import React from "react";
import MUIDataTable from "mui-datatables";
const Datatable = ({ data, columns, title, loading = false }) => {
  // const [pageSize, setPageSize] = useState(10);
  const options = {
    filterType: "checkbox",
    filter: true,
    sort: false,
  };
  return (
    <div style={{ width: "100%" }}>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Datatable;
