import React from "react";
import "./weCanHelp.css";
import ContactDetails from "../contactDetails/ContactDetails";
import DemoContactForm from "../demoContactForm/DemoContactForm";

const WeCanHelp = () => {
  return (
    <div className="container my-container pt-0">
      <h1 className="heading-2 heading-main">
        Want to Know More How We can Help
      </h1>
      <div className="we-can-help-sec-width mx-auto">
        <div className="we-can-help-sec">
          <div className="row row-cols-1 row-cols-lg-2 g-0">
            <div className="col">
              <div className="p-4 p-lg-5">
                <ContactDetails
                  title={"Schedule a demo:"}
                  description={
                    "Get an insightful demonstration of all that Onco-Connect has to offer to your organisation. Fill in your details so that our team can reach out to you at the earliest."
                  }
                  classes={"schedule-demo"}
                />
              </div>
            </div>
            <div className="col">
              <div className="bg-light-blue p-4 p-lg-5 h-100">
                <DemoContactForm heading="Schedule a Demo Form" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeCanHelp;
