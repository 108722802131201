import React from "react";
import "./treatmentsWhatWeDo.css";
import SmallCard2 from "../../../../../components/cards/SmallCard2";

const TreatmentsWhatWeDo = () => {
  return (
    <div className="container my-container pt-0">
      <h1 className="heading-2 heading-main pb-3 mb-5">What We Do</h1>
      <p className="heading-5 text-center pb-4 pb-xl-5 mb-5">
        Travel to India, UK & Singapore For Cancer Treatment
      </p>
      <div className="what-we-do-steps">
        <SmallCard2 title={"1"} subTitle={"Send Us Reports"} />
        <SmallCard2 title={"2"} subTitle={"Consult Doctor"} />
        <SmallCard2 title={"3"} subTitle={"Travel To Cancer Centre"} />
        <SmallCard2 title={"4"} subTitle={"Recovery & Support"} />
      </div>
    </div>
  );
};

export default TreatmentsWhatWeDo;
