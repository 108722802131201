import React from "react";
import "./cancerRisk.css";
// import solutionsMockup1 from "../../../../../assets/images/solutions-mockup-1.png";
import solutionsMockup1 from "../../../../../assets/images/solutions-mockup-1-1.png";
import solutionsMockup2 from "../../../../../assets/images/solutions-mockup-2.png";
import solutionsMockup3 from "../../../../../assets/images/solutions-mockup-3.png";
import { ButtonPrimary } from "../../../../../components/buttons/Buttons";
import { Link } from "react-router-dom";

const CancerRisk = () => {
  return (
    <div className="solutions-sec">
      <div className="container my-container">
        <h1 className="heading-2 heading-main">
          Al led Cancer Risk Assessment
        </h1>
        <div className="row row-cols-1 row-cols-lg-3 gx-4 gy-4 g-xl-5">
          <div className="col">
            <div className="solutions-card">
              {/* <div className="mb-4">
                <h4 className="color-pink text-center fw-700 text-3 mb-3">
                  SCREENING PROGRAM
                </h4>
                <span className="border-b-green mt-auto"></span>
              </div> */}
              <div>
                <img src={solutionsMockup1} alt="" />
                <p className="text-3 text-center fw-600 m-0 cancer-risk-p-width">
                  Start Your Cancer Risk Assessment
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="solutions-card">
              {/* <div className="mb-4">
                <h4 className="color-green text-center fw-700 text-3 mb-3">
                  START RISK ASSESSMENT
                </h4>
                <span className="border-b-pink mt-auto"></span>
              </div> */}
              <div>
                <img src={solutionsMockup2} alt="" />
                <p className="text-3 text-center fw-600 m-0 cancer-risk-p-width">
                  Complete Assessment
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="solutions-card">
              {/* <div className="mb-4">
                <h4 className="color-pink text-center fw-700 text-3 mb-3">
                  GET YOUR RESULTS
                </h4>
                <span className="border-b-green mt-auto"></span>
              </div> */}
              <div>
                <img src={solutionsMockup3} alt="" />
                <p className="text-3 text-center fw-600 m-0 cancer-risk-p-width">
                  Get Your Results
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4 mt-5">
          <Link to="/contactUs">
            <ButtonPrimary
              name={"Book a Demo"}
              arrow={false}
              classes={"btn-lg"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancerRisk;
