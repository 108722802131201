import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./userHeader.css";
import SidebarMenu from "./userHeaderComponents/sidebarMenu/SidebarMenu";
import StoredVariables, { GetSession } from "../../constant/Session";
const UserHeader = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const [headerMenu, setHeaderMenu] = useState([]);
  const login = GetSession(StoredVariables.username);
  useEffect(() => {
    if (login) {
      setHeaderMenu([
        {
          name: "Profile",
          link: "/employee/profile",
          active: pathName === "/employee/profile" ? true : false,
        },
        {
          name: "Assessment",
          link: "/employee/cancer-assessment",
          active: pathName === "/employee/cancer-assessment" ? true : false,
        },
      ]);
    } else {
      setHeaderMenu([
        {
          name: "Contact Us",
          link: "/contactUs",
          active: pathName === "/contactUs" ? true : false,
        },
      ]);
    }
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const Logout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  return (
    <>
      <header className="user-nav">
        <nav className="navbar navbar-expand-xl">
          <div className="container-fluid position-relative">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <i
                // className={
                //   isMenuOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"
                // }
                className="fa-solid fa-bars"
              ></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                {headerMenu &&
                  headerMenu.map((item, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <Link
                          className={
                            item.active ? "nav-link active" : "nav-link"
                          }
                          to={item.link}
                        >
                          {item.name}
                          <span className="menu-active-border"></span>
                        </Link>
                      </li>
                    );
                  })}
                <li className="nav-item" onClick={() => Logout()}>
                  <Link to="" className="nav-link">
                    Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <SidebarMenu
          headerMenu={headerMenu}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </header>
    </>
  );
};

export default UserHeader;
