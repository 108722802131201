import React from "react";
import propTypes from "prop-types";
import "./banners.css";

const MainBanner = ({ title, backgroundImg, textWidthClass, classes }) => {
  return (
    <div className={`${classes ? classes : ""}`}>
      <div
        className={`main-banner`}
        style={{
          background: `linear-gradient(rgba(000,000,000,0.22), rgba(000,000,000,0.22)), url(${backgroundImg})`,
        }}
      >
        <div className="container d-none d-md-block py-5">
          <div className={textWidthClass}>
            <h1 className="heading-1 color-white mb-4 mb-lg-5">{title}</h1>
          </div>
        </div>
      </div>
      <div className="title-container-sm container d-md-none">
        <div className="px-3 py-5">
          <h1 className="heading-1 text-center mb-0">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;

MainBanner.propTypes = {
  title: propTypes.any.isRequired,
  backgroundImg: propTypes.any.isRequired,
  textWidthClass: propTypes.string,
  classes: propTypes.string,
};
MainBanner.defaultProps = {
  textWidthClass: "col-lg-7",
};
