import React from "react";
import RootRoutes from "./routes/RootRoutes";

const App = () => {
  return (
    <>
      <RootRoutes />
    </>
  );
};

export default App;
