import React from "react";
import "./developingAI.css";
import developingAiImg1 from "../../../../../assets/images/developing-ai-1.png";
import developingAiImg2 from "../../../../../assets/images/developing-ai-2.png";
import developingAiImg3 from "../../../../../assets/images/developing-ai-3.png";

const DevelopingAI = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">
        Developing AI- Led Solutions in Cancer Care
      </h1>
      <div className="row row-cols-1 row-cols-lg-3 gx-4 gy-4 g-lg-5">
        <div className="col">
          <div className="developing-card">
            <img src={developingAiImg1} alt="" />
            <p className="text-4 text-center fw-600 my-4">
              Cancer Risk Assessment AI
            </p>
            <p className="text-center developing-p-width mb-4 mb-lg-0">
              Cancer risk assessment involves evaluating an individual's
              likelihood of developing cancer based on factors such as health,
              genetics, lifestyle, and exposure to carcinogens
            </p>
          </div>
        </div>
        <div className="col">
          <div className="developing-card">
            <img src={developingAiImg2} alt="" />
            <p className="text-4 text-center fw-600 my-4">Early Detection AI</p>
            <p className="text-center developing-p-width mb-4 mb-lg-0">
              We use machine learning algorithms to analyse medical images and
              data, enabling the timely identification of potential cancerous
              lesions, ultimately improving patient outcomes
            </p>
          </div>
        </div>
        <div className="col">
          <div className="developing-card">
            <img src={developingAiImg3} alt="" />
            <p className="text-4 text-center fw-600 my-4">Diagnostic AI</p>
            <p className="text-center developing-p-width mb-4 mb-lg-0">
              We employ advanced algorithms to interpret various data sources,
              assisting healthcare professionals in accurately diagnosing and
              characterising cancer, leading to more effective treatment
              decision
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopingAI;
