import React from "react";
import logoFooter from "../../assets/images/logo-footer.png";
import "./footer.css";
import { ButtonPrimary } from "../buttons/Buttons";
import { Link} from "react-router-dom";

const footerMenu1 = [
  { name: "Solutions", link: "/solutions" },
  { name: "Insurers", link: "/insurers" },
  { name: "Terms and Conditions", link: "/termsAndConditions" },
  { name: "Privacy Policy", link: "/privacyPolicy" },
  
  // { name: "Cancer Treatments", link: "/CancerTreatments" },
];

const footerMenu2 = [
  { name: "Employers", link: "/employers" },
  { name: "Digital Library", link: "/digitalLibrary" },
  { name: "Contact Us", link: "/contactUs" },
];

const Footer = () => {
  return (
    <>
      <footer className="my-footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 mb-5 mb-lg-0">
              <div className="footer-items-center">
                <Link to="/">
                  <img src={logoFooter} alt="" className="mb-5" />
                </Link>
                <Link to="/contactUs">                <ButtonPrimary name="STAY UPDATED" classes={"footer-btn"} />
</Link>
              </div>
            </div>
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <ul className="footer-menu">
                    {footerMenu1 &&
                      footerMenu1.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to={item.link}>{item.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-auto">
                  <ul className="footer-menu">
                    {footerMenu2 &&
                      footerMenu2.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link to={item.link}>{item.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 mb-5 mb-lg-0">
              <div className="h-100 d-flex align-items-center">
                <div className="w-100">
                  <div className="footer-items-center">
                    <Link to="/contactUs">
                      <div className="book-a-demo me-lg-4">
                        <p>BOOK A DEMO</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-items-center">
                <div className="d-flex align-items-center gap-4 mb-4">
                  <a
                    href="https://www.facebook.com/CompleteCancerCare"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook-f fs_18"></i>
                  </a>
                  {/* <a href="/">
                    <i className="fa-brands fa-twitter fs_18"></i>
                  </a> */}
                  <a
                    href="https://www.linkedin.com/company/94872400/admin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-linkedin-in fs_18"></i>
                  </a>
                </div>
                <p>WeWork Vasvani Chambers, Worli, Mumbai, India 400025</p>
                <p>68 Circular Road, #02-01, Singapore 049422</p>
                {/* <p>
                  <a href="tel:+91623456789">+91 62 345 67 89</a>
                </p> */}
                <p>
                  <a
                    href="mailto:hello@onco-connect.com"
                    style={{ wordWrap: "break-word" }}
                  >
                    hello@onco-connect.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-0">
          <p className="fs_14 m-0 p-1">
            Copyright @ {new Date().getFullYear()} onco-connect.com. All rights
            reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
