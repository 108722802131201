import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, { SetSession } from "../../../constant/Session";
import { Stringify, ENCODE, Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      email: email,
      password: password,
    };
    const validation = new Validator(requestData, {
      email: "required|email",
      password: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("account/admin/login", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          await SetSession(
            StoredVariables.logindata,
            ENCODE(Stringify(response.data))
          );
          await SetSession(StoredVariables.username, response.data.firstName);
          await SetSession(StoredVariables.email, response.data.email);
          await SetSession(StoredVariables.tabs, "dashboard");
          navigate("/admin/dashboard");
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  return (
    <div className="bg h-1">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <div className="card mt-5 p-3">
              <div className="card-body">
                <div className="logo p-3 bg-new">
                  <img src="/logo.png" alt="#logopng" />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <p className="para1"></p>
                  <div className="form-outline mb-3">
                    <label className="form-label ">
                      <b>Email address</b>
                    </label>
                    <input
                      type="email"
                      id="form1Example13"
                      className="form-control form-control-lg"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-outline mb-2">
                    <label className="form-label">
                      <b>Password</b>
                    </label>
                    <input
                      type="password"
                      id="form1Example23"
                      className="form-control form-control-lg"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end mb-3"></div>
                  <div className="text">
                    <Link to="/admin/forget-password">Forgot Password</Link>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
