import React from "react";
import "./insurersFigures.css";
import Figures from "../../../../../components/figures/Figures";

const InsurersFigures = () => {
  return (
    <div className="container my-container my-lg-5">
      <div className="insurers-figures">
        <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5">
          <div className="col">
            <Figures
              digit={"3<sup>rd</spu>"}
              description={
                "Employees rank cancer as their top three health concerns"
              }
            />
          </div>
          <div className="col">
            <Figures
              digit={"29%"}
              description={
                "Companies are offering cancer screening services for their employees"
              }
            />
          </div>
          <div className="col">
            <Figures
              digit={"31%"}
              description={
                "Mean health insurance coverage in low & middle income countries"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsurersFigures;
