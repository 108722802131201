import React, { useState } from "react";
import "./userForgotPassword.css";
import TextField from "@mui/material/TextField";
import { ButtonPrimary } from "../../../components/buttons/Buttons";
import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, { SetSession } from "../../../constant/Session";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const UserForgotPassword = () => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    email: "",
  });

  const onChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      email: formValues.email,
    };
    const validation = new Validator(requestData, {
      email: "required|email",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("employee/forgot-password", requestData);
        console.log(response);
        if (response.status === 200) {
          toast.success(response.message);
          await SetSession(StoredVariables.email, formValues.email);
          navigate("/employee/reset-password");
        } else {
          toast.error(response.message);
        }
      }
    }
  };
  return (
    <div className="container my-4 my-md-5">
      <div className="pro-card model-card mx-auto position-relative">
        <div>
          <h1 className="heading-5 fw-400 text-center mb-4 mb-md-5">
            Forgot Password
          </h1>
          <div>
            <form
              className="d-flex flex-column gap-3 gap-md-4"
              onSubmit={(e) => handleSubmit(e)}
            >
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Email"
                value={formValues.email}
                onChange={(e) => onChange("email", e.target.value)}
              />

              <ButtonPrimary name="Reset Password" arrow={false} />

              <p className="text-center m-0">
                Remember Password? Please{" "}
                <Link to="/employee/login">
                  <span className="color-pink pointer">Login</span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForgotPassword;
