import { Buffer } from "buffer";
import { toast } from "react-toastify";
// Base 64 Encode
export const ENCODE = (code) =>
  Buffer.from(
    `Kisan2KisanEn-Coding-$2y.S/${Buffer.from(`${code}`).toString(
      "base64"
    )}/$2y.Dev-Sonu-Kisan2KisanEn-Coding`
  ).toString("base64");

// Base 64 Decode
export const DECODE = (code) =>
  Buffer.from(
    `${Buffer.from(`${code}`, "base64").toString("ascii")}`.split("/")[1],
    "base64"
  ).toString("ascii");

// reload functionallty
export const Reload = () => window.location.reload();

// json to String
export const Stringify = (json) => JSON.stringify(json);

// string to json
export const Json = (string) => JSON.parse(string);

// function for getting error from validatorjs
export const Validation = async (errordata) => {
  // console.log(errordata);
  return Object.values(errordata).length > 0
    ? Object.values(errordata).map((x) => {
        //   console.log(x);
        return toast.error(`${x}`);
      })
    : [];
};
