import React from "react";
import "./designedToSupport.css";

const DesignedToSupport = () => {
  return (
    <div className="bg-light-blue">
      <div className="container my-container">
        <h1 className="heading-2 heading-main">Designed To Support </h1>
        <div className="d-flex justify-content-evenly gap-4">
          <div className="support-card">
            <p className="text-3 text-center fw-600 m-0">Patients</p>
          </div>
          <div className="support-card">
            <p className="text-3 text-center fw-600 m-0">Insurers</p>
          </div>
          <div className="support-card">
            <p className="text-3 text-center fw-600 m-0">Employers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignedToSupport;
