import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DELETE } from "../../constant/RequestAuthService";
import { ButtonPrimary, ButtonWhite } from "../../components/buttons/Buttons";

const DeleteModal = ({ modal3, setModal3, id, setId, URL }) => {
  // Modal Operation
  const handleSubmit = async () => {
    const response = await DELETE(URL, { id: id });
    // console.log(response);
    if (response.status === 200) {
      toast.success(response.message);
      await setId(0);
      setModal3(false);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Modal centered show={modal3} onHide={() => setModal3(false)}>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column gap-5">
            <h2 className="text-3 text-center">
              Are you sure you want to delete ?
            </h2>

            <div className="d-flex justify-content-center gap-3">
              <ButtonWhite
                name="No"
                arrow={false}
                onClick={() => setModal3(false)}
              />
              <ButtonPrimary
                name="Yes"
                arrow={false}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(DeleteModal);
