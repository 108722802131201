import CURLPath, { Base } from "./Urls";

// Base Url set Here
const Base_url = `${Base}`;

// Image url path
export const Img_url = `${Base}public/images/`;

// Get Value
export const getValue = (value) => `/${value}`;

// Set headers for All APIs
export const headers = CURLPath.json;

// Set the url of the for delete and get
export const validateUrl = async (url, props) =>
  (await props[1]) && props[1].id > 0 ? url + getValue(props[1].id) : url;

//  Check the Path Parameters
const PATHParameter = (path) => `${path}`.split("?")[0];

// Checking Url is defiend or not
const CURL = async (c) =>
  (await (CURLPath[PATHParameter(c)] === PATHParameter(c)))
    ? `${Base_url}${c}`
    : false;

export default CURL;
