import React, { useState, useEffect } from "react";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import { PUT, GET } from "../../constant/RequestAuthService";
import { Validation } from "../../constant/Util";
import { Modal } from "react-bootstrap";
import { ButtonPrimary, ButtonWhite } from "../buttons/Buttons";
import PhoneInput from "../phoneInput/PhoneInput";

const EditUser = ({ modal2, setModal2, id, setId }) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobilecode, setMobileCode] = useState("+91");

  const GetUser = async (id) => {
    const { data, status } = await GET("admin/user", { id: id });

    if (status === 200) {
      setFname(data && data.firstName);
      setLname(data && data.lastName);
      setMobile(data && data.mobileNumber);
    }
  };

  useEffect(() => {
    GetUser(id);
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      id: id,
      firstName: fname,
      lastName: lname,
      profilePicture: "",
      mobileNumber: mobile,
    };
    // console.log(requestData)
    const validation = new Validator(requestData, {
      firstName: "required",
      lastName: "required",
      mobileNumber: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT("admin/user", requestData);
      // console.log(response)
      if (response.status === 200) {
        toast.success(response.message);
        setModal2(false);
        setFname("");
        setLname("");
        setMobile("");
        setId("");
        // setAnswer("");
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <>
      <Modal centered show={modal2} onHide={() => setModal2(false)}>
        <Modal.Header closeButton>
          <h1 className="text-3">Edit User</h1>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex flex-column gap-3 gap-md-4">
            <input
              type="text"
              id="form1Example13"
              className="input-2"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />

            <input
              type="text"
              id="form1Example13"
              className="input-2"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
            />

            <PhoneInput
              phoneCodeValue={mobilecode}
              phoneCodeValueChange={(e) => setMobileCode(e.target.value)}
              phoneValue={mobile}
              phoneValueChange={(e) => setMobile(e.target.value)}
              variant={"pink"}
            />

            <div>
              <label htmlFor="profileImg" className="mb-2 fs_14">
                Profile Picture
              </label>
              <br />
              <input
                type="file"
                name="profile_picture"
                id="profileImg"
                className=""
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonWhite
            name="Cancel"
            arrow={false}
            onClick={() => setModal2(false)}
          />
          <ButtonPrimary
            name="Edit User"
            arrow={false}
            onClick={(e) => handleSubmit(e)}
          />
        </Modal.Footer>{" "}
      </Modal>
    </>
  );
};

export default EditUser;
