import React, { useState } from "react";
import "./whatWeDo.css";
import FullWidthCard1 from "../../../../../components/cards/FullWidthCard1";
import img_1 from "../../../../../assets/images/what-we-do-image-1.png";
import img_2 from "../../../../../assets/images/what-we-do-image-2.png";
import img_3 from "../../../../../assets/images/what-we-do-image-3.png";

const WhatWeDo = () => {
  const [viewPortWidth, setViewPortWidth] = useState(window.outerWidth);

  window.addEventListener("resize", () => {
    setViewPortWidth(window.outerWidth);
  });

  return (
    <div
      className={`${
        viewPortWidth >= "768" ? "container" : ""
      }  my-container pt-0`}
    >
      <h1 className="heading-2 heading-main container-padding">What We Do</h1>
      <div className="what-we-do-card">
        <FullWidthCard1
          title={"Engage"}
          description={
            "We are collaborating with multiple stakeholders that advocate risk assessment, early detection, diagnostics, expert opinion to achieve better outcomes"
          }
          image={img_1}
          bgClass={"bg-green"}
          variant={"image-right"}
          classes={"mb-4 mb-xl-5"}
        />
        <FullWidthCard1
          title={"Support"}
          description={
            "We firmly believe that cancer detection and support should go hand in hand so we can save  and improve quality of lives"
          }
          image={img_2}
          bgClass={"bg-linen"}
          variant={"image-left"}
          classes={"mb-4 mb-xl-5"}
        />
        <FullWidthCard1
          title={"Analyse"}
          description={
            "Cancer data reveals trends, patterns and potential risk factors, aiding better health engagement and cancer treatment strategies for population health at large"
          }
          image={img_3}
          bgClass={"bg-light-blue"}
          variant={"image-right"}
        />
      </div>
    </div>
  );
};

export default WhatWeDo;
