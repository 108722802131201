import React, { useEffect, useRef } from "react";
import "./digitalLibrary.css";
import DigitalLibraryBanner from "./digitalLibraryComponents/digitalLibraryBanner/DigitalLibraryBanner";
import Library from "./digitalLibraryComponents/library/Library";

const DigitalLibrary = () => {
  const libraryRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleScrollToLibrary = () => {
    libraryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <DigitalLibraryBanner />
      <Library />
    </>
  );
};

export default DigitalLibrary;
