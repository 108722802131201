import React, { useState } from "react";
import "./whatsNew.css";
import FullWidthCard2 from "../../../../../components/cards/FullWidthCard2";
import img_1 from "../../../../../assets/images/whats-new-1.png";
import { useNavigate } from "react-router-dom";

const WhatsNew = () => {
  const navigate = useNavigate();

  const [viewPortWidth, setViewPortWidth] = useState(window.outerWidth);

  window.addEventListener("resize", () => {
    setViewPortWidth(window.outerWidth);
  });

  const handleArticle = (id) => {
    localStorage.setItem("articleId", id);
    navigate("/article");
  };
  return (
    <div
      className={`${
        viewPortWidth >= "768" ? "container" : ""
      } my-container pt-0`}
    >
      <h1 className="heading-2 heading-main container-padding">
        What’s New At Onco-Connect
      </h1>
      <div className="Whats-new-card">
        <FullWidthCard2
          title={
            "Discover how cancer impacts employees at work and what employers can do about it"
          }
          description={
            "Onco-Connect engages your employees to undersstand the impact of cancer on their work life balance and what they can do mitigate these issues"
          }
          image={img_1}
          bgClass={"bg-pitch"}
          variant={"image-right"}
          btnOnclick={() => handleArticle(1)}
        />
      </div>
    </div>
  );
};

export default WhatsNew;
