import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GET } from "../../../constant/RequestAuthService";
import { BsFillTrashFill, BsPencil } from "react-icons/bs";
import AddEmployee from "../../../components/models/addEmployee";
import EditEmployee from "../../../components/models/editEmployee";
import { Img_url } from "../../../constant";
import Datatable from "../../../components/dataTables/Datatable";
import { IconButton } from "@mui/material";
import DeleteModal from "../../../components/models/deleteModal";

const UserDetail = () => {
  const [userdetail, setUserDetails] = useState("");
  const [employees, setEmployees] = useState("");
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [employeeid, setEmployeeId] = useState(0);
  const [employeedata, setEmployeeData] = useState();
  // const [Status, setStatus] = useState("");
  let { id } = useParams();

  const GetUserDetails = async (id) => {
    const { data, status } = await GET("admin/user", { id: id });
    if (status === 200) {
      setUserDetails(data && data);
    }
  };

  useEffect(() => {
    GetUserDetails(id);
  }, [id]);

  useEffect(() => {
    GetEmployees(id);
  }, [id]);

  const GetEmployees = async (id) => {
    const { data, status } = await GET("admin/user/employees", { id: id });
    if (status === 200) {
      setEmployees(data && data);
    }
  };
  const handleAdd = async (e) => {
    await setModal1(!modal1);
  };

  const handleDelete = async (x) => {
    await setEmployeeId(x.id);
    await setModal3(!modal3);
  };

  // const handleActive = async (x) => {
  //   await setEmployeeId(x.id);
  //   await setStatus(x.status);
  //   await setModal4(!modal4);
  // };

  const handleEdit = async (x) => {
    await setEmployeeData(x);
    await setModal2(!modal2);
  };

  const columns = [
    {
      label: <b>First Name</b>,
      name: "fname",
    },
    {
      label: <b>Last Name</b>,
      name: "lname",
    },
    {
      label: <b>Profile Picture</b>,
      name: "image",
    },
    {
      label: <b>Email</b>,
      name: "email",
    },
    {
      label: <b>Gender</b>,
      name: "emailverified",
    },
    {
      label: <b>Mobile No.</b>,
      name: "mobile",
    },
    {
      label: <b>Address</b>,
      name: "address",
    },
    // {
    //   label: <b>Status</b>,
    //   name: "status",
    // },
    {
      label: <b>Action</b>,
      name: "action",
    },
  ];

  let i = 1;
  let data =
    employees && employees.length > 0
      ? employees.map((x) => {
          return {
            id: i++,
            fname: x && x.firstName ? x.firstName : "",
            lname: x && x.lastName ? x.lastName : "",
            image:
              x && x.profilePicture !== null && x.profilePicture !== "null" ? (
                <a
                  href={`${Img_url}${x.profilePicture}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    width="80%"
                    src={`${Img_url}${x.profilePicture}`}
                    alt="#employee_image"
                  />
                </a>
              ) : (
                ""
              ),
            email: x && x.email ? x.email : "",
            gender: x && x.gender ? x.gender : "",
            mobile: x && x.mobileNumber ? x.mobileNumber : "",
            address: x && x.address ? x.address : "",
            // status: (
            //   <Switch
            //     checked={x && x.status === "active" ? "checked" : ""}
            //     onChange={() => handleActive(x)}
            //   />
            // ),
            action: (
              <>
                <IconButton onClick={(e) => handleEdit(x)}>
                  <BsPencil color="blue" />
                </IconButton>
                <IconButton onClick={(e) => handleDelete(x)}>
                  <BsFillTrashFill color="red" />
                </IconButton>
              </>
            ),
          };
        })
      : [];

  return (
    <div className="col-md-12 pt-2">
      <h4 className="font-weight-bold">User Detail</h4>
      <div className="card m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <img
                src={
                  userdetail && userdetail.profilePicture
                    ? `${userdetail.profilePicture}`
                    : "../../../assets/images/user.png"
                }
              />
              <p>
                {" "}
                <b>Full Name : </b>
                {userdetail && userdetail.firstName}{" "}
                {userdetail && userdetail.lastName}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <b>Email : </b>
                {userdetail && userdetail.email}
              </p>
              <p>
                <b>Email Verified :</b>
                {userdetail && userdetail.isEmailVerify}
              </p>
              <p>
                <b>Mobile No. :</b>
                {userdetail && userdetail.mobileNumber}{" "}
              </p>
              <p>
                <b>Mobile No. Verified :</b>
                {userdetail && userdetail.isMobileVerify}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <h3 class="card-heading">
              Employees Under {userdetail && userdetail.firstName}{" "}
              {userdetail && userdetail.lastName}
            </h3>
            <button className="btn btn-primary" onClick={(e) => handleAdd()}>
              Add Employee
            </button>
          </div>
          <div className="card-body mb-3">
            <Datatable data={data} columns={columns} />
          </div>
          <AddEmployee modal1={modal1} setModal1={setModal1} userid={id} />
          <EditEmployee
            modal2={modal2}
            setModal2={setModal2}
            userid={id}
            URL={"admin/user/employees"}
            employeedata={employeedata}
          />
          <DeleteModal
            modal3={modal3}
            setModal3={setModal3}
            id={employeeid}
            setId={setEmployeeId}
            URL={"admin/user/employees"}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
