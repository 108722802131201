import React, { useState } from "react";
import "./cards.css";
import propTypes from "prop-types";
import { ButtonPrimary } from "../buttons/Buttons";
import { Link } from "react-router-dom";

// variant should be "image-right" to place image right side of container
// can pass either link or btnOnclick in props, not both
const FullWidthCard2 = ({
  title,
  description,
  image,
  variant,
  bgClass,
  classes,
  link,
  btnOnclick,
}) => {
  const [viewPortWidth, setViewPortWidth] = useState(window.outerWidth);

  window.addEventListener("resize", () => {
    setViewPortWidth(window.outerWidth);
  });
  return (
    <div
      className={`full-width-card2 p-4 p-md-0 ${bgClass} ${
        classes ? classes : ""
      }`}
    >
      <div className={`${viewPortWidth < "768" ? "container p-0" : ""}`}>
        <div className="row row-cols-1 row-cols-lg-2 gx-lg-5">
          <div
            className={`col ${
              variant === "image-right" ? "order-lg-last" : ""
            }`}
          >
            <img src={image} alt="" className="full-width-card-2-img" />
          </div>
          <div className="col">
            <div className="w-100 h-100 pt-4 p-md-4 p-lg-5">
              <h3 className="heading-3 mb-4 fw-700">{title}</h3>
              <p className="mb-4 fs_18 fw-600">{description}</p>
              {link && (
                <Link to={link}>
                  <ButtonPrimary name="Read all Article" />
                </Link>
              )}
              {btnOnclick && (
                <ButtonPrimary name="Read all Article" onClick={btnOnclick} />
              )}
              {!link && !btnOnclick && (
                <ButtonPrimary name="Read all Article" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthCard2;

FullWidthCard2.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  variant: propTypes.string,
  bgClass: propTypes.string,
  classes: propTypes.string,
  link: propTypes.string,
};

FullWidthCard2.defaultProps = {
  bgClass: "bg-pitch",
  variant: "image-right",
};
