import React from "react";
import "./helpingInsurers.css";
import Card4 from "../../../../../components/cards/Card4";
import helpingInsurersImg1 from "../../../../../assets/images/helping-insurers-img1.png";
import helpingInsurersImg2 from "../../../../../assets/images/helping-insurers-img2.png";
import helpingInsurersImg3 from "../../../../../assets/images/helping-insurers-img3.png";

const HelpingInsurers = () => {
  return (
    <div className="container my-container employer-container-pt">
      <h1 className="heading-2 heading-main">
        Helping insurance proposition standout with actionable insights
      </h1>
      <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5 gy-5">
        <div className="col">
          <Card4
            title={"Engage"}
            description={
              "We are collaborating with multiple stakeholders that advocate risk assessment, early detection, diagnostics, expert opinion to achieve better outcomes"
            }
            image={helpingInsurersImg1}
          />
        </div>
        <div className="col">
          <Card4
            title={"Analyse"}
            description={
              "We provide detailed analysis and insights gained from data of individuals and employees to provide better healthcare outcomes and engagement for your organisation."
            }
            image={helpingInsurersImg2}
          />
        </div>
        <div className="col">
          <Card4
            title={"Support"}
            description={
              "We firmly believe that cancer detection and support should go hand in hand so we can save  and improve quality of lives"
            }
            image={helpingInsurersImg3}
          />
        </div>
      </div>
    </div>
  );
};

export default HelpingInsurers;
