import React, { useState, useEffect } from "react";
import "./userCancerAssessment.css";
import bannerImg from "../../../assets/images/cancer-assessment-banner-img.png";
import { ButtonSecondary } from "../../../components/buttons/Buttons";
import { Link } from "react-router-dom";
import { GET } from "../../../constant/RequestAuthService";
import StoredVariables, { GetSession } from "../../../constant/Session";
const UserCancerAssessment = () => {
  const [questionData, setQuestionData] = useState("");
  const [questionDataLength, setQuestiondatalength] = useState("");
  const [current, setCurrent] = useState(0);

  const GetQuestion = async () => {
    const { data, status } = await GET("question", {
      id: GetSession(StoredVariables.userId),
    });
    if (status === 200) {
      setQuestiondatalength(data && data.length);
      setQuestionData(data && data);
    }
  };

  useEffect(() => {
    GetQuestion();
  }, []);

  const onChange = (value) => {
    setCurrent(value);
  };

  console.log(setQuestionData);
  return (
    <>
      <div
        className="container-fluid p-0 assessment-banner"
        style={{ background: `url(${bannerImg})` }}
      >
        <div>
          <div className="container py-3">
            <h1 className="heading-5 color-white text-center">
              Take Your Cancer Risk Assessment
            </h1>
          </div>
        </div>
      </div>

      <div className="container my-3 my-md-4">
        <div className="pro-card col-md-8 col-lg-6 mx-auto">
          <p className="text-3 text-center mb-4">
            Complete the questionnaire at your{" "}
            <br className="d-none d-md-block" /> own pace
          </p>

          <div
            className="progress assessment-progress"
            style={{ height: "6px" }}
          >
            <div
              className="progress-bar bg-success"
              style={{ width: "0%" }}
            ></div>
          </div>

          <p className="text-center my-4">
            {current}/{questionDataLength} questions answered
          </p>

          <div className="d-flex justify-content-center">
            <Link to="/employee/cancer-assessment-questions">
              <ButtonSecondary name="Proceeds" arrow={false} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCancerAssessment;
