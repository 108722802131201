import React from "react";
import propTypes from "prop-types";
import arrowImg from "../../assets/images/arrow.png";
import arrowWhiteImg from "../../assets/images/arrow-white.png";
import "./buttons.css";

// arrow prop should be true or false to show arrow in button, default arrow prop value is true
export const ButtonPrimary = ({ name, arrow, classes, onClick }) => {
  return (
    <>
      <button
        type="submit"
        className={`btn btn-primary ${classes ? classes : ""}`}
        onClick={onClick}
      >
        {name}
        {arrow && <img src={arrowImg} alt="arrow" />}
      </button>
    </>
  );
};

ButtonPrimary.propTypes = {
  name: propTypes.string.isRequired,
  arrow: propTypes.bool,
  classes: propTypes.string,
};
ButtonPrimary.defaultProps = {
  arrow: true,
};

// arrow prop should be true or false to show arrow in button, default arrow prop value is true
export const ButtonSecondary = ({
  name,
  arrow,
  classes,
  onClick,
  disabled,
}) => {
  return (
    <>
      <button
        className={`btn btn-secondary ${classes ? classes : ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        {name}
        {arrow && <img src={arrowImg} alt="arrow" />}
      </button>
    </>
  );
};

ButtonSecondary.propTypes = {
  name: propTypes.string.isRequired,
  arrow: propTypes.bool,
  classes: propTypes.string,
};
ButtonSecondary.defaultProps = {
  arrow: true,
};

// arrow prop should be true or false to show arrow in button, default arrow prop value is true
export const ButtonTertiary = ({ name, arrow, classes, onClick }) => {
  return (
    <>
      <button
        className={`btn btn-tertiary ${classes ? classes : ""}`}
        onClick={onClick}
      >
        {name}
        {arrow && <img src={arrowWhiteImg} alt="arrow" />}
      </button>
    </>
  );
};

ButtonTertiary.propTypes = {
  name: propTypes.string.isRequired,
  arrow: propTypes.bool,
  classes: propTypes.string,
};
ButtonTertiary.defaultProps = {
  arrow: true,
};

// arrow prop should be true or false to show arrow in button, default arrow prop value is true
export const ButtonPitch = ({ name, arrow, classes, onClick }) => {
  return (
    <>
      <button
        className={`btn btn-pitch ${classes ? classes : ""}`}
        onClick={onClick}
      >
        {name}
        {arrow && <img src={arrowImg} alt="arrow" />}
      </button>
    </>
  );
};

ButtonPitch.propTypes = {
  name: propTypes.string.isRequired,
  arrow: propTypes.bool,
  classes: propTypes.string,
};
ButtonPitch.defaultProps = {
  arrow: true,
};

// arrow prop should be true or false to show arrow in button, default arrow prop value is true
export const ButtonWhite = ({ name, arrow, classes, onClick }) => {
  return (
    <>
      <button
        className={`btn btn-white ${classes ? classes : ""}`}
        onClick={onClick}
      >
        {name}
        {arrow && <img src={arrowImg} alt="arrow" />}
      </button>
    </>
  );
};

ButtonWhite.propTypes = {
  name: propTypes.string.isRequired,
  arrow: propTypes.bool,
  classes: propTypes.string,
};
ButtonWhite.defaultProps = {
  arrow: true,
};
