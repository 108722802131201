import React from "react";

const CheckboxButton = ({ x, setAnswerId, setAnswer }) => {
  const setOptionValue = (x) => {
    setAnswerId(x.id);
    setAnswer(x.value);
  };
  return (
    <div className="row">
      <div className="col-md-1">
        <input
          type="checkbox"
          id="html"
          className="mb-2"
          onChange={() => setOptionValue(x)}
        />
      </div>
      <div className="col-md-3">
        <label for="html">{x.value}</label>
      </div>
    </div>
  );
};

export default CheckboxButton;
