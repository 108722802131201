import React, { useState } from "react";
import "./userResetPassword.css";
import TextField from "@mui/material/TextField";
import { ButtonPrimary } from "../../../components/buttons/Buttons";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, { GetSession } from "../../../constant/Session";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserResetPassword = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      email: GetSession(StoredVariables.email),
      otp: formValues.otp,
      newPassword: formValues.newPassword,
      confirmPassword: formValues.confirmPassword,
    };
    const validation = new Validator(requestData, {
      otp: "required",
      newPassword: "required",
      confirmPassword: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("employee/reset-password", requestData);
        console.log(response);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/employee/login");
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  return (
    <div className="container my-4 my-md-5">
      <div className="pro-card model-card mx-auto position-relative">
        <div>
          <h1 className="heading-5 fw-400 text-center mb-4 mb-md-5">
            Reset Password
          </h1>
          <div>
            <form
              className="d-flex flex-column gap-3 gap-md-4"
              onSubmit={(e) => handleSubmit(e)}
            >
              <TextField
                fullWidth
                className="mui-tex-field"
                label="OTP"
                value={formValues.otp}
                onChange={(e) => onChange("otp", e.target.value)}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        className="btn p-0 border-none"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </span>
                    </InputAdornment>
                  ),
                }}
                label="New Password"
                value={formValues.newPassword}
                onChange={(e) => onChange("newPassword", e.target.value)}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        className="btn p-0 border-none"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </span>
                    </InputAdornment>
                  ),
                }}
                label="Confirm New Password"
                value={formValues.confirmPassword}
                onChange={(e) => onChange("confirmPassword", e.target.value)}
              />

              <ButtonPrimary name="Reset Password" arrow={false} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserResetPassword;
