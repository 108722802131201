import React from "react";

const WelcomeSubHeader = () => {
  return (
    <div className="container-fluid bg-white p-0">
      <div className="container py-3">
        <h1 className="heading-5">Welcome, John</h1>
      </div>
    </div>
  );
};

export default WelcomeSubHeader;
