import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StoredVariables, { GetSession } from "../../../constant/Session";
const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (GetSession(StoredVariables.logindata) === "") {
      navigate("/admin");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="card p-3">
      <h2 className="card-heading">Dashboard</h2>
      <div className="card-body mb-3">
        <div className="col">Dashboard</div>
      </div>
    </div>
  );
};

export default Dashboard;
