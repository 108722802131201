import React, {useEffect} from "react";
import "./privacyPolicy.css";
const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-heading">PRIVACY POLICY</h1>
      <div className="privacy-policy-content">
        We are committed to protecting your privacy and ensuring the security of
        your personal information. This privacy policy outlines how Crossroads
        Health Services Private Limited, called as Onco-Connect here and
        referred to as <b>“we”, “us”, “company”,</b> and <b>“Onco-Connect”</b>,
        collect, use, disclose and protect your information when you use our
        services. Our services include services delivered whether through our
        website
        <a href="/">(www.onco-connect.com)</a> or through the company’s web or
        mobile applications, or through any of our services linked to cancer
        screening.
        <h1>Terms of Reference for this document:</h1>
        <b>“Personal Data”</b> : any information that can be used to contact or
        identify the user (such as name, contact details, health records,
        specific health-related information or any other such information).{" "}
        <b>“User”</b> : any individual using Onco-Connect services.
        <h1>What information do we collect?</h1>
        <b>1.</b> We collect “Personal Data” i.e. personal information about you
        like your name, email address, date of birth and other relevant
        information when you register for our services. <br />
        <br /> <b>2.</b> We will also collect information about your health,
        lifestyle, medical and cancer history, clinical data, including
        diagnostic reports and any other data you share with us and treat it as
        &quot;Personal data&quot;. <br /> <br />
        By accepting the terms and conditions on our website, including but not
        limited to this Privacy Policy and by providing us with your Personal
        Data, it is agreed that you have voluntarily consented to the
        collection, storage, processing, disclosure and transfer of your
        personal data in accordance with applicable laws prevalent in India and
        internationally. You acknowledge that you have provided your Personal
        Data of your free will after understanding the purpose of how it will be
        used.
        <h1>How do we use your information?</h1>
        We use the information collected through our services for the following
        purposes:{" "}
        <ul>
          <li>
            {" "}
            To provide our services which include cancer risk assessment, cancer
            screening, video consultations
          </li>
          <li>
            To communicate with you and send important updates, newsletters and
            any other communications related to our services. You can opt-out of
            these communications as any time
          </li>
          <li>
            We may use aggregated and anonymised data for research and analytics
            purposes to improve our services and contribute to health-related
            knowledge and demographic profiling
          </li>
          <li>
            To detect,investigate and prevent activities that may violate our
            policy
          </li>
        </ul>
        <h1>What information do we share with third party?</h1>
        <ul>
          <li>
            We may share your Personal Data with service providers who have a
            contractual relationship with Onco-Connect in capacity with the
            operation of our services
          </li>
          <li>
            {" "}
            When you give us your consent to do so, we may disclose information
            to service providers that assist us in delivering our services,
            which would be having their means of protecting data privacy.
          </li>
          <li>
            We may disclose your information if required by law or if we believe
            such action is necessary to comply with legal obligations, protect
            our rights or ensure the safety of our users.
          </li>
        </ul>


        <h1>Data Security</h1>
        <ul><li>We adhere to best practise guidelines and
        regulations for our network servers and web applications for data
        security.</li>
        <li>We implement reasonable security measures to protect your
        personal data from unauthorised access, disclosure and destruction.</li></ul>
        <h1>Your options</h1>
        You have the right to access, modify, or delete your personal
        information at anytime. You can manage your preferences by emailing us
        at <a href="mailto:hello@onco-connect.com">hello@onco-connect.com</a>.<h1>Contact us</h1>
        If you have any concerns, questions, requests, or grievances regarding
        this policy or your personal data, please write to{" "}
        <a href="mailto:hello@onco-connect.com">hello@onco-connect.com</a>. Once we receive your request, we
        will aim to reply within five working days.
      </div>
    </div>
  );
}

export default PrivacyPolicy;
