import React, { useEffect } from "react";
import "./home.css";
import HomeBanner from "../../../components/banners/HomeBanner";
import PitchBoard from "../../../components/pitchBoard/PitchBoard";
import CorePillars from "./homeComponents/corePillars/CorePillars";
import OurSolutions from "./homeComponents/ourSolutions/OurSolutions";
import StructuredFramework from "./homeComponents/structuredFramework/StructuredFramework";
import WhatWeDo from "./homeComponents/whatWeDo/WhatWeDo";
import WhatsNew from "./homeComponents/whatsNew/WhatsNew";
import CancerWellness from "./homeComponents/cancerWellness/CancerWellness";
// import OurPartners from "../../components/ourPartners/OurPartners";

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <HomeBanner />
      <PitchBoard
        text={"Your Tomorrow Starts Today: Cancer Detection Matters"}
      />
      <CorePillars />
      <OurSolutions />
      <StructuredFramework />
      <CancerWellness />
      <WhatWeDo />
      <WhatsNew />
      {/* <OurPartners /> */}
    </>
  );
};

export default Home;
