import React from "react";
import "./pitchBoard.css";
import couts from "../../assets/images/“.png";
import propTypes from "prop-types";

const PitchBoard = ({ text, textWidthClass, classes }) => {
  return (
    <div className={`bg-pitch ${classes ? classes : ""}`}>
      <div className="container my-container pitch-bord">
        <img src={couts} alt="" className="mb-5" />
        <div className={textWidthClass ? textWidthClass : ""}>
          <p
            className="heading-4 text-center fw-600 mb-4"
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default PitchBoard;

PitchBoard.propTypes = {
  text: propTypes.string.isRequired,
  textWidthClass: propTypes.string,
  classes: propTypes.string,
};
