import React from "react";
import "./structuredFramework.css";
import Card1 from "../../../../../components/cards/Card1";

const StructuredFramework = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">
        Structured Framework <br className="d-md-none" /> To Support
      </h1>
      <div className="structured-framework-card mx-auto">
        <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-4 g-4 px-3">
          <div className="col">
            <Card1
              title={"Employers"}
              subTitle={"Catch it early, Keep them strong"}
              description={
                "Our solutions will improve employee retention, boost productivity and save lives through early cancer detection"
              }
              bgClass={"bg-linen"}
              link={"/employers"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Insurers"}
              subTitle={"Wellness begins, With awareness"}
              description={
                "Our solutions help insurers in underwriting, corporate wellness and promoting proactive healthcare   "
              }
              bgClass={"bg-light-blue"}
              link={"/insurers"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Healthcare Provider"}
              subTitle={"Your health , Our expertise"}
              description={
                "Our solutions assist in early detection, diagnosis and treatment "
              }
              bgClass={"bg-green"}
              link={"/solutions"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Government Programs"}
              subTitle={"Early detection, For a healthier nation"}
              description={
                "We support public health policy for early detection and affordable cancer care"
              }
              bgClass={"bg-light-blue"}
              link={"/solutions"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StructuredFramework;
