import { Link } from "react-router-dom";
import React from "react";

const TakeAssessment = () => {
  return (
    <div className="container my-3 my-md-4">
      <div className="col-md-8 mx-auto p-0">
        <Link to="">
          <div className="pro-card">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3 className="text-3">Take Your Cancer Risk Assessment</h3>
                <p className="m-0">Lorem ipsum dolor sit amet.</p>
              </div>
              <i className="fa-solid fa-chevron-right fs_18"></i>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TakeAssessment;
