import React from "react";
import "./cancerDiagnostics.css";
import Card3 from "../../../../../components/cards/Card3";
import diagnosticTestsImg1 from "../../../../../assets/images/diagnostic-tests-img-1.png";
import diagnosticTestsImg2 from "../../../../../assets/images/diagnostic-tests-img-2.png";
import diagnosticTestsImg3 from "../../../../../assets/images/diagnostic-tests-img-3.png";
import { Link } from "react-router-dom";
import { ButtonPrimary } from "../../../../../components/buttons/Buttons";

const CancerDiagnostics = () => {
  return (
    <div className="container my-container pt-0">
      <h1 className="heading-2 heading-main">Cancer Diagnostics</h1>
      <div className="row row-cols-1 row-cols-md-2 g-4 g-xl-5 align-items-center mb-5">
        <div className="col">
          <h4 className="diagnostics-sub-heading text-center mb-0">
            <strong className="color-pink text-1">500+</strong>{" "}
            <sup className="color-pink text-3 font-playfair fw-700">
              CERTIFIED LABS
            </sup>
          </h4>
        </div>
        <div className="col d-none d-md-block">
          <h4 className="diagnostics-sub-heading text-center mb-0">
            <strong className="color-green text-1">250+</strong>{" "}
            <sup className="color-green text-3 font-playfair fw-700">
              DIAGNOSTIC TESTS
            </sup>
          </h4>
        </div>
      </div>
      <div className="cancer-diagnostics-row">
        <div className="row row-cols-1 row-cols-md-3 g-4 g-xl-5 gy-5">
          <div className="col">
            <Card3
              title={"LAB TESTS"}
              description={
                "Book any lab test from the comfort of your home. Your lab reports will be made available digitally so your doctor can review them"
              }
              image={diagnosticTestsImg1}
            />
          </div>
          <div className="col">
            <Card3
              title={"SCANS"}
              description={
                "Book X-Rays, MRI and Scans through our 100+ NABH & NABL certified centres. Get imaging done quickly and easily with further review from your doctor"
              }
              image={diagnosticTestsImg2}
            />
          </div>
          <div className="col d-md-none">
            <h4 className="diagnostics-sub-heading text-center mb-0">
              <strong className="color-green text-1">250+</strong>{" "}
              <sup className="color-green text-3 font-playfair fw-700">
                DIAGNOSTIC TESTS
              </sup>
            </h4>
          </div>
          <div className="col">
            <Card3
              title={"GENETICS & PROCEDURES"}
              description={
                "Book genetic tests,  liquid biopsies, tumour block reviews and procedures like bone marrow biopsy et al  from our certified provider networks"
              }
              image={diagnosticTestsImg3}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center pt-4 mt-5">
        <Link to="/contactUs">
          <ButtonPrimary
            name={"Speak To Us"}
            arrow={false}
            classes={"btn-lg"}
          />
        </Link>
      </div>
    </div>
  );
};

export default CancerDiagnostics;
