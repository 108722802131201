import React from "react";
import { toast } from "react-toastify";
import { PUT } from "../../constant/RequestAuthService";
import { Modal } from "react-bootstrap";
import { ButtonPrimary, ButtonWhite } from "../buttons/Buttons";

const StatusChange = ({
  modal4,
  setModal4,
  id,
  setId,
  URL,
  Status,
  setStatus,
}) => {
  // const classes = useStyles();
  // console.log(id);
  // Modal Operation
  const setModalOperation = async (confirm) => {
    const requestdata = {
      id: id,
      status: Status === "deactive" ? "active" : "deactive",
    };
    const response = await PUT(URL, requestdata);
    // console.log(response);
    if (response.status === 200) {
      toast.success(response.message);
      await setId(0);
      await setStatus("");
      await setModal4(!modal4);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Modal centered show={modal4} onHide={() => setModal4(false)}>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column gap-5">
            <h2 className="text-3 text-center">
              Are you sure you want to{" "}
              {Status === "deactive" ? "Activate" : "Deactivate"} ?
            </h2>

            <div className="d-flex justify-content-center gap-3">
              <ButtonWhite
                name="No"
                arrow={false}
                onClick={() => setModal4(false)}
              />
              <ButtonPrimary
                name="Yes"
                arrow={false}
                onClick={() => setModalOperation(true)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusChange;
