import React from "react";
import "./figures.css";
import propTypes from "prop-types";

const Figures = ({ digit, description, classes }) => {
  return (
    <div className={`figure-card${classes ? classes : ""}`}>
      <h3
        dangerouslySetInnerHTML={{ __html: digit }}
        className="font-open-sans fw-600 text-center mb-4"
      ></h3>
      <p className="text-center fw-600 fs_20">{description}</p>
    </div>
  );
};

export default Figures;

Figures.propTypes = {
  digit: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  classes: propTypes.string,
};
