import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import StoredVariables, { SetSession } from "../../../constant/Session";
const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    await SetSession(StoredVariables.email, email);
    e.preventDefault();
    const requestData = {
      email: email,
    };
    const validation = new Validator(requestData, {
      email: "required|email",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST(
          "account/admin/forgot-password",
          requestData
        );
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/admin/reset-password");
        } else {
          toast.error(response.message);
        }
        // await navigate("/dashboard");
      }
    }
  };

  return (
    <div className="bg h-1">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <div className="card mt-6 p-3">
              <div className="card-body">
                <div className="logo p-3 bg-new">
                  <img src="/logo.png" alt="#logopng" />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <p className="para1"></p>
                  <div className="form-outline mb-3">
                    <label className="form-label">
                      <b>Email address</b>
                    </label>
                    <input
                      type="email"
                      id="form1Example13"
                      className="form-control form-control-lg"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
