import React from 'react'
import "./partners.css"
import partner1 from "../../../assets/images/partner1.png"
import partner2 from "../../../assets/images/partner2.png"
import partner3 from "../../../assets/images/partner3.png"

const Partners = () => {
  return (
    <div>
      <h1 className='partners-heading'>Our Partners</h1>
      <div className='partners-container'>
        <div className='partner1'><img src={partner1} alt="" /></div>
        <div className='partner2'><img src={partner2} alt="" /></div>
        <div className='partner3'><img src={partner3} alt="" /></div>
      </div>
    </div>
  )
}

export default Partners
