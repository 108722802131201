import React from "react";
import "./banners.css";
import { ButtonPitch, ButtonTertiary, ButtonWhite } from "../buttons/Buttons";
import { Link } from "react-router-dom";

const CancerTreatmentsBanner = () => {
  return (
    <div>
      <div className="treatments-banner">
        <div className="container py-5 d-none d-md-block">
          <div className="treatments-banner-text-width">
            <h1 className="heading-1 color-white pb-lg-4 mb-5">
              Providing access to advanced cancer treatments near you
            </h1>
            <div className="d-flex align-items-center flex-wrap gap-3">
              <Link to="/contactUs">
                <ButtonPitch
                  name="Speak To Us"
                  arrow={false}
                  classes={"banner-btn"}
                />
              </Link>
              <Link to="/solutions">
                <ButtonTertiary
                  name="Start Your Treatment"
                  classes={"banner-btn"}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 d-md-none">
        <div>
          <h1 className="heading-1 text-center mb-4">
            Providing access to advanced cancer treatments near you
          </h1>
          <div
            className="d-flex flex-column align-items-center
           gap-3"
          >
            <Link to="/contactUs">
              <ButtonPitch
                name="Speak To Us"
                arrow={false}
                classes={"banner-btn"}
              />
            </Link>
            <Link to="/solutions">
              <ButtonWhite
                name="Start Your Treatment"
                classes={"banner-btn arrow-sm-none"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancerTreatmentsBanner;
