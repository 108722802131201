import React from "react";
import "./corePillars.css";
import awarenessPillarImg from "../../../../../assets/images/homeAwareness.png";
import preventionPillarImg from "../../../../../assets/images/HomePrevention.png";
import treatmentPillarImg from "../../../../../assets/images/homeTreatment.png";
import surviourshipPillarImg from "../../../../../assets/images/homeSurviourship.png";
import wellbeingPillarImg from "../../../../../assets/images/homeWellbeing.png";

const CorePillars = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">
        Core Pillars of Cancer <br className="d-md-none" /> Care Management
      </h1>
      <div className="core-pillars row g-4 g-xl-5 gy-5">
        <div className="col-md-4">
          <div className="core-pillars-card">
            <img src={awarenessPillarImg} alt="" />
            <p className="text-center fs_20 fw-600 m-0">
              AI Led Technology Solutions
            </p>
            <span className="pillar-border"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="core-pillars-card">
            <img src={preventionPillarImg} alt="" />
            <p className="text-center fs_20 fw-600 m-0">
              Awareness & Education
            </p>
            <span className="pillar-border"></span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="core-pillars-card">
            <img src={treatmentPillarImg} alt="" />
            <p className="text-center fs_20 fw-600 m-0">
              Prevention & Early Detection
            </p>
            <span className="pillar-border"></span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="core-pillars-card">
            <img src={surviourshipPillarImg} alt="" />
            <p className="text-center fs_20 fw-600 m-0">
              Advanced Treatment Solutions
            </p>
            <span className="pillar-border"></span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="core-pillars-card">
            <img src={wellbeingPillarImg} alt="" />
            <p className="text-center fs_20 fw-600 m-0">Wellbeing</p>
            <span className="pillar-border"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorePillars;
