import React from "react";
import "./cancerTreatment.css";
import Card1 from "../../../../../components/cards/Card1";
import { Link } from "react-router-dom";
import {
  ButtonSecondary,
  ButtonWhite,
} from "../../../../../components/buttons/Buttons";
import stepsArrow from "../../../../../assets/images/steps-arrow-pink.png";
import SmallCard1 from "../../../../../components/cards/SmallCard1";

const CancerTreatment = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">Cancer Treatment Options</h1>
      <div className="mb-5">
        <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5">
          <div className="col">
            <div className="d-flex flex-column align-items-center h-100">
              <h4 className="color-pink text-center fw-700 text-3 mb-3">
                SPECIALIST CANCER CENTRES
              </h4>
              <span className="border-b-green mt-auto"></span>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-column align-items-center h-100">
              <h4 className="color-green text-center fw-700 text-3 mb-3">
                WORKING WITH GLOBAL HEALTHCARE PROVIDERS
              </h4>
              <span className="border-b-pink mt-auto"></span>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-column align-items-center h-100">
              <h4 className="color-pink text-center fw-700 text-3 mb-3">
                NEW & ADVANCED CANCER TREATMENTS
              </h4>
              <span className="border-b-green mt-auto"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="cancer-treatment-card mx-auto">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 g-xl-5">
          <div className="col">
            <Card1
              title={"Cancer Surgery"}
              description={
                "Surgery is one of the main treatments for many types of cancer and we help find the right treatment solutions for your cancer type"
              }
              bgClass={"bg-light-blue"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Chemotherapy"}
              description={
                "Chemotherapy is anti cancer drug treatment. We support patients to get the right line of chemotherapy to improve their healthcare outcomes. "
              }
              bgClass={"bg-linen"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Radiotherapy"}
              description={
                "Cancer treatment with radiotherapy, includes external radiotherapy and internal radiotherapy. Get an expert opinion from radiation oncologists to start your treatment. "
              }
              bgClass={"bg-pitch"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"NanoKnife"}
              description={
                "Irreversible electroporation (IRE) which  is a treatment that uses electrical currents to damage and destroy cancer cells. It is  known as NanoKnife®. We support our patients to get NanoKnife treatment on priority"
              }
              bgClass={"bg-linen"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"CAR-T cell Therapy"}
              description={
                "Chimeric antigen receptor (CAR)-T cell therapy has been revolutionary as it has produced remarkably effective and durable clinical responses. We work with centres of clinical excellence for these treatments and help patients receive this therapy on priority"
              }
              bgClass={"bg-pitch"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Proton Therapy"}
              description={
                "Proton therapy is a radiation treatment that precisely delivers a beam of protons to disrupt and destroy tumor cells. We work with proton therapy centres across different geographies to bring world class cancer care for our patients"
              }
              bgClass={"bg-light-blue"}
              link={"/contactUs"}
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-between pt-4 mt-5">
          <Link to="/contactUs">
            <ButtonSecondary
              name={"Immunotherapy & Targeted Therapy"}
              classes={"btn-lg"}
              arrow={false}
            />
          </Link>
          <Link to="/contactUs">
            <ButtonSecondary
              name={"All and Rare Cancers Treated"}
              classes={"btn-lg"}
            />
          </Link>
        </div>
      </div>
      <div className="treatment-steps-container pt-4 mt-5">
        <div>
          <SmallCard1
            title={"1"}
            subTitle={"Speak to Us"}
            bgClass={"bg-linen"}
          />
        </div>
        <div>
          <img src={stepsArrow} alt="" className="steps-arrow" />
        </div>
        <div>
          <SmallCard1
            title={"2"}
            subTitle={"Access Provider Networks"}
            bgClass={"bg-pitch"}
          />
        </div>
        <div>
          <img src={stepsArrow} alt="" className="steps-arrow" />
        </div>
        <div>
          <SmallCard1
            title={"3"}
            subTitle={"Appointment Arranged"}
            bgClass={"bg-light-blue"}
          />
        </div>
        <div>
          <img src={stepsArrow} alt="" className="steps-arrow" />
        </div>
        <div>
          <SmallCard1
            title={"4"}
            subTitle={"Receive treatment"}
            bgClass={"bg-green"}
          />
        </div>
        <div>
          <img src={stepsArrow} alt="" className="steps-arrow" />
        </div>
        <div>
          <SmallCard1 title={"5"} subTitle={"Recover"} bgClass={"bg-pitch"} />
        </div>
      </div>
      <div className="d-flex justify-content-center pt-4 mt-5">
        <Link to="/contactUs">
          <ButtonWhite name={"Get Treatment Quote"} classes={"btn-lg"} />
        </Link>
      </div>
    </div>
  );
};

export default CancerTreatment;
