import React, { useEffect } from "react";
import "./insurers.css";
import MainBanner from "../../../components/banners/MainBanner";
import insurersBannerImg from "../../../assets/images/insurers-banner-img.png";
import HelpingInsurers from "./insurersComponents/helpingInsurers/HelpingInsurers";
import PitchBoard from "../../../components/pitchBoard/PitchBoard";
import InsurersFigures from "./insurersComponents/insurersFigures/InsurersFigures";
import EngagingIndividuals from "../../../components/engagingIndividuals/EngagingIndividuals";
import ActionableInsights from "../../../components/actionableInsights/ActionableInsights";
import SolutionsForBusiness from "../../../components/solutionsForBusiness/SolutionsForBusiness";
import WeCanHelp from "../../../components/weCanHelp/WeCanHelp";

const Insurers = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MainBanner
        title={
          "We work with insurers to build customised solutions that enhance cancer offering"
        }
        backgroundImg={insurersBannerImg}
        textWidthClass={"insurers-banner-title-width"}
        classes={"insurers-banner"}
      />
      <HelpingInsurers />
      <PitchBoard
        text={
          "Better health and life insurance with higher approval and lower risk"
        }
        textWidthClass={"insurer-p-width"}
        classes={"insurers-pitch-board"}
      />
      <InsurersFigures />
      <EngagingIndividuals />
      <ActionableInsights />
      <SolutionsForBusiness />
      <WeCanHelp />
    </>
  );
};

export default Insurers;
