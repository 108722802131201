import React, { useState } from "react";
import StoredVariables, {
  GetSession,
  SetSession,
} from "../../../constant/Session";
const Sidebar = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const [active, setActive] = useState("dashboard");
  const Outlet = props.children.type;
  const handleUserlogout = async () => {
    sessionStorage.clear();
    window.location = "/admin";
  };
  const setMenus = async (value) => {
    await SetSession(StoredVariables.tabs, value);
  };
  return (
    <div className="bg">
      <nav className="navbar navbar-expand-lg navbar-light bg-light pt-0 pb-0">
        <div className="container-fluid bg1">
          <div className="ml-2 pt-2">
            <a className="navbar-brand" href="/admin/dashboard">
              <img src="/logo.png" alt="#logopng" />
            </a>
            &emsp;&emsp;
            <i
              className="fa fa-bars"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              aria-hidden="true"
            ></i>
          </div>
          <div className="dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {GetSession(StoredVariables.username)}
            </button>
            <ul
              className="dropdown-menu mt-3 mr-new"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a className="dropdown-item" href="/admin/profile">
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/admin/changepassword">
                  Change Password
                </a>
              </li>
              <li
                onClick={() => handleUserlogout()}
                style={{ cursor: "pointer" }}
              >
                <a className="dropdown-item" href="javascript();">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="row flex-nowrap h-1">
        <div
          className={`col-auto col-md-3 col-xl-2 px-sm-2 pr-0 ${
            isSidebarOpen === true ? "d-none" : " "
          }`}
        >
          <div
            className={
              "d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100 bg1"
            }
          >
            <ul className="list-group m-0 w-100">
              <li
                className={
                  GetSession(StoredVariables.tabs) === "dashboard"
                    ? "list-group-item mt-3 active"
                    : "list-group-item mt-3"
                }
                aria-current="true"
                onClick={() => setMenus(`dashboard`)}
              >
                <a
                  href="/admin/dashboard"
                  className="d-flex justify-content-between"
                >
                  <div>
                    <i className="fa fa-home" aria-hidden="true"></i> &nbsp;
                    <span>Dashboard</span>
                  </div>
                  <i className="fa fa-angle-right mt-1" aria-hidden="true"></i>
                </a>
              </li>

              <li
                className={
                  GetSession(StoredVariables.tabs) === "users"
                    ? "list-group-item mt-3 active"
                    : "list-group-item mt-3"
                }
                aria-current="true"
                onClick={() => setMenus(`users`)}
              >
                <a
                  href="/admin/users"
                  className="d-flex justify-content-between "
                >
                  <div>
                    <i className="fa fa-users" aria-hidden="true"></i> &nbsp;
                    <span>Users</span>
                  </div>
                  <i className="fa fa-angle-right mt-1" aria-hidden="true"></i>
                </a>
              </li>
              <li
                className={
                  GetSession(StoredVariables.tabs) === "question"
                    ? "list-group-item mt-3 active"
                    : "list-group-item mt-3"
                }
                aria-current="true"
                onClick={() => setMenus(`question`)}
              >
                <a
                  href="/admin/questions"
                  className="d-flex justify-content-between "
                >
                  <div>
                    <i className="fa fa-question" aria-hidden="true"></i> &nbsp;
                    <span>Questions</span>
                  </div>
                  <i className="fa fa-angle-right mt-1" aria-hidden="true"></i>
                </a>
              </li>
              <li
                className={
                  GetSession(StoredVariables.tabs) === "question1"
                    ? "list-group-item mt-3 active"
                    : "list-group-item mt-3"
                }
                aria-current="true"
                onClick={() => setMenus(`question1`)}
              >
                <a
                  href="/admin/add-question"
                  className="d-flex justify-content-between "
                >
                  <div>
                    <i className="fa fa-question" aria-hidden="true"></i> &nbsp;
                    <span>Questions 1</span>
                  </div>
                  <i className="fa fa-angle-right mt-1" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`container-fluid m-0 p-0 pt-2 ${
            isSidebarOpen === true ? "w-98" : "w-82"
          }`}
        >
          <div className={`${isSidebarOpen === true ? "ml-2" : ""}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
