import React from "react";
import "./banner.css";
import secondOpinionBanner from "../../../assets/images/secondOpinionBanner.jpg";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${secondOpinionBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="banner-container"
      >
         <div className="overlay"></div>
        <div>
          <h1 className="banner-heading">Online Cancer Second Opinion <br/> From Top Centres in UK and India </h1>
        </div>
        <div>
          {" "}
          <button
            onClick={() => {
              navigate("/contactUs");
            }}
            className="banner-button"
          >
            Speak to us
          </button>
        </div>
      </div>
      <h1 className="mobile-banner-heading">Online Cancer Second Opinion <br/> From Top Centres in UK and India</h1>
      <button
            onClick={() => {
              navigate("/contactUs");
            }}
            className="mobile-banner-button"
          >
            Speak to us
          </button>
      <div className="banner-quote">
        <div className="banner-quote-text">
        <span className="doublequote">&ldquo;</span>Cancer Second Opinion Provides You With Peace Of  Mind Or Additional Options For Your Care <span className="doublequote">&rdquo;</span> 
        </div>
      </div>
    </div>
  );
}
