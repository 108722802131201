import React from "react";
import "./info.css";
function Info() {
  return (
    <>
      <div className="info1">
        <h2 className="info1-heading">Why Take A Second Opinion</h2>
        <p className="info1-text">
          Cancer treatment can involve taking major decisions about treatment
          options which include chemotherapy, surgery or radiotherapy. An expert
          second opinion is recommended when you need more clarity about these
          options or even to reassure you, about your treatment. An expert
          opinion will also help explore for any new and latest treatment
          opportunities for your treatment. This will enable to to make the best
          informed decision about your treatment.
        </p>
      </div>
      <div className="info2">
        <h2 className="info2-heading">What is A Second Opinion</h2>
        <p className="info2-text">
          A second opinion is getting another expert to review your case and
          give suggestions about your treatment. This is common practice and you
          should not feel concerned of how it may affect your current team of
          doctors. Getting an expert second opinion is common within the Indian
          healthcare system too. This is help you to have confidence and peace
          of mind that you are receiving the most appropriate treatment.
        </p>
      </div>
      <div className="info3">
        <div className="info3-text">
          <p className="info3-heading">
            At Onco-Connect, we believe you should consider of an expert second
            opinion when:
          </p>

          <div className="bullet-container">
            <div
              className="bullet-circle"
              style={{ backgroundColor: "rgba(220, 125, 87, 1)" }}
            ></div>
            <p>
              {" "}
              Your diagnosis is unclear or you are unsure about the treatment
              recomendations that you have received.
            </p>
          </div>
          <div className="bullet-container">
            <div
              className="bullet-circle"
              style={{ backgroundColor: "rgba(173, 207, 186, 1)" }}
            ></div>

            <p>
              {" "}
              You need reassurance and confidence of whether your treatment is
              going in the right direction.
            </p>
          </div>
          <div className="bullet-container">
            <div
              className="bullet-circle"
              style={{ backgroundColor: "rgba(101, 91, 150, 1)" }}
            ></div>

            <p>You have been diagnosed with a rare type of cancer.</p>
          </div>
          <div className="bullet-container">
          <div className="bullet-circle" style={{backgroundColor:"rgba(106, 172, 242, 1)"}}></div>

            <p>
              Your cancer has reoccurred and you like to know about the latest
              developments of treatment for your cancer type.
            </p>
          </div>
          <div className="bullet-container">
            <div
              className="bullet-circle"
              style={{ backgroundColor: "rgba(250, 64, 64, 1)" }}
            ></div>

            <p>
              You would like more information about your cancer type, diagnosis
              and treatment options.
            </p>
          </div>
         
        </div>
      </div>
    </>
  );
}

export default Info;
