import React from "react";
import "./cards.css";
import propTypes from "prop-types";

const SmallCard1 = ({ title, subTitle, bgClass, classes }) => {
  return (
    <div className={`small-card1 ${bgClass} ${classes ? classes : ""}`}>
      <h3 className="font-open-sans color-pink text-center mb-3 mb-xl-4">
        {title}
      </h3>
      <h4 className="mb-0 font text-center fw-600">{subTitle}</h4>
    </div>
  );
};
export default SmallCard1;

SmallCard1.propTypes = {
  title: propTypes.string.isRequired,
  subTitle: propTypes.string.isRequired,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

SmallCard1.defaultProps = {
  bgClass: "bg-linen",
};
