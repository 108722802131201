import React from "react";
import propTypes from "prop-types";
import "./employersTabItem.css";

const EmployersTabItem = ({ title, description, image, bgClass, classes }) => {
  return (
    <div
      className={`${bgClass} ${classes ? classes : ""}`}
      style={{ borderRadius: "0 0 25px 25px" }}
    >
      <div className="employers-tab px-4 px-md-5 px-lg-0 py-4 py-lg-5 col-lg-10 mx-auto">
        <div className="row row-cols-1 row-cols-md-2 g-4 g-xl-5 gy-4 align-items-center">
          <div className="col">
            <div className="d-flex justify-content-center">
              <img
                src={image}
                style={{ marginTop: "-20px" }}
                alt=""
                className="w-75"
              />
            </div>
          </div>
          <div className="col order-md-first">
            <h1 className="heading-2 fw-700 mb-3">{title}</h1>
            <span className="border-b mb-4"></span>
            <p className="m-0 fs_20">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployersTabItem;

EmployersTabItem.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

EmployersTabItem.defaultProps = {
  bgClass: "bg-light-blue",
};
