import React, { useState, memo, useEffect } from "react";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import { PUT } from "../../constant/RequestAuthService";
import { Validation } from "../../constant/Util";
import PhoneInput from "../phoneInput/PhoneInput";
import { ButtonPrimary, ButtonWhite } from "../buttons/Buttons";
import { Modal } from "react-bootstrap";

const EditEmployee = ({ modal2, setModal2, userid, employeedata, URL }) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobilecode, setMobileCode] = useState("+91");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (employeedata) {
      setFname(employeedata.firstName);
      setLname(employeedata.lastName);
      setEmail(employeedata.email);
      setMobile(employeedata.mobileNumber);
      setGender(employeedata.gender);
      setAddress(employeedata.address);
    }
  }, [employeedata]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      id: employeedata.id,
      userId: userid,
      firstName: fname,
      lastName: lname,
      email: email,
      mobileNumber: mobile,
      gender: gender,
      address: address,
    };
    const validation = new Validator(requestData, {
      firstName: "required",
      lastName: "required",
      email: "required",
      mobileNumber: "required",
      gender: "required",
      address: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT(URL, requestData);
      // console.log(response)
      if (response.status === 200) {
        toast.success(response.message);
        setModal2(false);
        setFname("");
        setLname("");
        setEmail("");
        setMobile("");
        setGender("");
        setAddress("");
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <>
      <Modal centered show={modal2} onHide={() => setModal2(false)}>
        <Modal.Header closeButton>
          <h1 className="text-3">Add Employee</h1>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex flex-column gap-3 gap-md-4">
            <input
              type="text"
              id="form1Example13"
              placeholder="First Name"
              className="input-2"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />
            <input
              type="text"
              id="form1Example13"
              placeholder="Last Name"
              className="input-2"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
            />
            <input
              type="email"
              id="form1Example13"
              placeholder="Email"
              className="input-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PhoneInput
              phoneCodeValue={mobilecode}
              phoneCodeValueChange={(e) => setMobileCode(e.target.value)}
              phoneValue={mobile}
              phoneValueChange={(e) => setMobile(e.target.value)}
              variant={"pink"}
            />
            <div className="my-select">
              <select
                type="text"
                placeholder="Gender"
                className="input-2"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option disabled selected>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <input
              type="text"
              placeholder="Address"
              className="input-2"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonWhite
            name="Cancel"
            arrow={false}
            onClick={() => setModal2(false)}
          />
          <ButtonPrimary
            name="Edit Employee"
            arrow={false}
            onClick={(e) => handleSubmit(e)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(EditEmployee);
