import React from "react";
import "../userProfile.css";
import { ButtonPrimary } from "../../../../components/buttons/Buttons";

const EmployeeDashboardComponent = () => {
  return (
    <div className="container my-3 my-md-4">
      <div className="pro-card col-md-8 mx-auto position-relative">
        <h3>About</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>
        <h4>Interesting facts from Report</h4>
        <h2>41%</h2>
        <ButtonPrimary name="Generate Report" arrow={false} />
      </div>
    </div>
  );
};

export default EmployeeDashboardComponent;
