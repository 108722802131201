import React from "react";
import { Route, Routes } from "react-router-dom";
import "./layout.css";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import UserHeader from "../components/userHeader/UserHeader";
import Footer from "../components/footer/Footer";
import Home from "../pages/userpages/home/Home";
import Solutions from "../pages/userpages/Solutions/Solutions";
import Employers from "../pages/userpages/employers/Employers";
import Insurers from "../pages/userpages/insurers/Insurers";
import Article from "../pages/userpages/article/Article";
import DigitalLibrary from "../pages/userpages/digitalLibrary/DigitalLibrary";
import ContactUs from "../pages/userpages/contactUs/ContactUs";
import CancerTreatments from "../pages/userpages/cancerTreatments/CancerTreatments";
import Login from "../pages/adminpages/login";
import ForgetPassword from "../pages/adminpages/forgetPassword";
import ResetPassword from "../pages/adminpages/resetPassword";
import Sidebar from "../pages/adminpages/sidebar";
import Dashboard from "../pages/adminpages/dashboard";
import Users from "../pages/adminpages/users";
import UserDetail from "../pages/adminpages/users/userDetails";
import Questions from "../pages/adminpages/questions";
import AddQuestions from "../pages/adminpages/questions/addQuestion";
import Profile from "../pages/adminpages/profile";
import ChangePassword from "../pages/adminpages/changePassword";
import UserProfile from "../pages/userpages/userProfile/UserProfile";
import EmployeeDashboard from "../pages/userpages/employeeDashboard/EmployeeDashboard";
import UserCancerAssessment from "../pages/userpages/userCancerAssessment/UserCancerAssessment";
import AssessmentStepper from "../pages/userpages/userCancerAssessmentQuestions/AssessmentStepper";
import UserLogin from "../pages/userpages/userLogin/UserLogin";
import UserForgotPassword from "../pages/userpages/userForgotPassword/UserForgotPassword";
import UserResetPassword from "../pages/userpages/userResetPassword/UserResetPassword";
import UserSignup from "../pages/userpages/userSignup/UserSignup";
import SecondOpinion from "../pages/userpages/secondOpinion/SecondOpinion";
import PrivacyPolicy from "../pages/userpages/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/userpages/termsAndCondtions/TermsAndConditions";
import Payment from "../pages/userpages/payment/Payment";
const UserLayout = () => {
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <div className="user-main-content flex-grow-1">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

const EmployeeLayout = () => {
  return (
    <>
      <div className="d-flex flex-column min-vh-100 bg-light-gray">
        <UserHeader />
        <div className="user-main-content flex-grow-1">
          <Outlet />
        </div>
      </div>
    </>
  );
};

const AdminLoginLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const AdminLayout = () => {
  return (
    <>
      <Sidebar>
        <Outlet />
      </Sidebar>
    </>
  );
};
const RootRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/secondOpinion" element={<SecondOpinion />} />
          <Route path="/employers" element={<Employers />} />
          <Route path="/insurers" element={<Insurers />} />
          <Route path="/digitalLibrary" element={<DigitalLibrary />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/CancerTreatments" element={<CancerTreatments />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/payment" element={<Payment />} />

        </Route>

        {/* admin routes */}
        <Route path="/admin" element={<AdminLoginLayout />}>
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/corporate-login" element={<Login />} />
          <Route path="/admin/forget-password" element={<ForgetPassword />} />
          <Route path="/admin/reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/admin/" element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/user-detail/:id" element={<UserDetail />} />
          <Route path="/admin/questions" element={<Questions />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/changepassword" element={<ChangePassword />} />
          <Route path="/admin/add-question" element={<AddQuestions />} />
        </Route>

        <Route path="/employee/" element={<EmployeeLayout />}>
          <Route path="/employee/login" element={<UserLogin />} />
          <Route path="/employee/signup" element={<UserSignup />} />
          <Route
            path="/employee/forgot-password"
            element={<UserForgotPassword />}
          />
          <Route
            path="/employee/reset-password"
            element={<UserResetPassword />}
          />
          <Route path="/employee/dashboard" element={<EmployeeDashboard />} />
          <Route path="/employee/profile" element={<UserProfile />} />
          <Route
            path="/employee/cancer-assessment"
            element={<UserCancerAssessment />}
          />
          <Route
            path="/employee/cancer-assessment-questions"
            element={<AssessmentStepper />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RootRoutes;
