import React from "react";
import "./ourSolutions.css";
import ourSolutionsMockup11 from "../../../../../assets/images/our-solutions-mockup-1-1.png";
import ourSolutionsMockup1 from "../../../../../assets/images/our-solutions-mockup-1.png";
import ourSolutionsMockup2 from "../../../../../assets/images/our-solutions-mockup-2.png";
// import ourSolutionsMockup3 from "../../../../../assets/images/our-solutions-mockup-3.png";
import ourSolutionsMockup4 from "../../../../../assets/images/our-solutions-mockup-4.png";
// import ourSolutionsMockup5 from "../../../../../assets/images/our-solutions-mockup-5.png";
// import ourSolutionsMockup6 from "../../../../../assets/images/our-solutions-mockup-6.png";
import { ButtonSecondary } from "../../../../../components/buttons/Buttons";
import { Link } from "react-router-dom";

const OurSolutions = () => {
  return (
    <div className="our-solutions-sec">
      <div className="container my-container">
        <h1 className="heading-2 heading-main">Our Solutions</h1>
        <div className="col-xl-10 mx-auto row row-cols-1 row-cols-md-2 g-4 g-xl-5 gy-5">
          <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup11} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                AI-Led Solutions
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Our AI technology is transforming cancer care in early
                detection, diagnosis and treatment
              </p>
            </div>
          </div>
          <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup1} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Cancer Risk Assessment
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                AI-led cancer risk assessment for most prevalent cancers
              </p>
            </div>
          </div>
          <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup2} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Screening Programs
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Our cancer screening programs are tailored to suit any
                organisation or government programs
              </p>
            </div>
          </div>
          {/* <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup3} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Diagnostics & Pharmacy
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Users can book lab & imaging tests and also get medications
                delivered at your home through our platform
              </p>
            </div>
          </div> */}
          <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup4} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Expert Cancer Opinion
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Access leading cancer experts from India, UK & Singapore to
                discuss advance treatment options
              </p>
            </div>
          </div>
          {/* <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup5} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Condition Management Platform
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Empowering users with personalised health and lifestyle
                interventions to manage their condition
              </p>
            </div>
          </div> */}
          {/* <div className="col">
            <div className="our-solutions-card">
              <img src={ourSolutionsMockup6} alt="" />
              <h4 className="text-3 text-center fw-600 mt-3 mt-md-0 mb-3">
                Health Engagement
              </h4>
              <p className="m-0 text-center our-solutions-p-width mb-3 mb-md-0">
                Engage users to improve their health & wellbeing through
                activities, challenges and rewards
              </p>
            </div>
          </div> */}
        </div>
        <div className="d-flex justify-content-center pt-4 mt-5">
          <Link to="/contactUs">
            <ButtonSecondary name="Speak To Us" classes={"btn-lg"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurSolutions;
