import React, { useEffect, useState } from "react";
import "./models.css";
import { Form, Modal } from "react-bootstrap";
import { ButtonPrimary, ButtonWhite } from "../buttons/Buttons";
import { useNavigate } from "react-router-dom";
import DocEditor from "../../components/CkEditor";
import { ENCODE } from "../../constant/Util";
import { GET, POST } from "../../constant/RequestAuthService";
import { Validation } from "../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";

const AddQuestion = ({ modal1, setModal1, props }) => {
  const navigate = useNavigate();

  const [editor, setEditor] = useState(null);
  const [select, setSelect] = useState();
  const [required, setRequired] = useState(false);
  const [displayRadio, setDisplayRadio] = useState([]);
  const [displayCheckbox, setDisplayCheckBox] = useState([]);
  const [displaySelect, setDisplaySelect] = useState([]);
  const [options, setOptions] = useState([]);
  const [count, setCount] = useState(2);
  const [convertedContent, setConvertedContent] = useState(null);
  const [input, setInput] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userid, setUserId] = useState();

  const GetOptionTypes = async () => {
    const { data, status } = await GET("question/get-type");
    if (status === 200) {
      setInput(data && data);
    }
  };

  useEffect(() => {
    GetOptionTypes();
    GetUsers();

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setRequired(!required);

  //   // eslint-disable-next-line
  // }, [select]);

  const GetUsers = async () => {
    const { data, status } = await GET("admin/user");
    if (status === 200) {
      setUserData(data && data);
    }
  };

  const AppendRadio = () => {
    setDisplayRadio((displayRadio) => [
      ...displayRadio,
      <div className="question-model-input-sec">
        <div className="input-sec-inner">
          <input type="radio" id="html" disabled />
          <input
            type="text"
            className="input-2"
            placeholder="Enter Option"
            // value={value}
            // onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => setOptionsValue(e.target.value)}
          />
        </div>
        <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
      </div>,
    ]);
  };

  const AppendCheckbox = () => {
    setDisplayCheckBox((displayCheckbox) => [
      ...displayCheckbox,
      <div className="question-model-input-sec">
        <div className="input-sec-inner">
          <input type="checkbox" id="html" disabled />
          <label for="html">
            <input
              type="text"
              className="input-2"
              placeholder="Enter Option"
              // value={value}
              // onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => setOptionsValue(e.target.value)}
            />
          </label>
        </div>
        <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
      </div>,
    ]);
  };

  const AppendSelect = () => {
    if (displayCheckbox && displayCheckbox.length > count) {
      setCount(displayCheckbox.length + 1);
    } else if (displayRadio && displayRadio.length > count) {
      setCount(displayRadio.length + 1);
    } else {
      setCount(count + 1);
    }
    // setCount(count + 1);
    setDisplaySelect((displaySelect) => [
      ...displaySelect,
      <div className="question-model-input-sec">
        <div className="input-sec-inner">
          <label>{count}</label>
          <input
            type="text"
            className="input-2"
            placeholder="Enter Option"
            onBlur={(e) => setOptionsValue(e.target.value)}
          />
        </div>
        <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
      </div>,
    ]);
  };

  const setOptionsValue = (value) => {
    if (value !== "") {
      setOptions((options) => [
        ...options,
        {
          value: value,
          isAnswers: "false",
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      typeId: parseInt(select),
      title: ENCODE(convertedContent),
      userId: userid,
      allowedAnswers: 1,
      isRequired: required.toString(),
      options: options,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      typeId: "required",
      title: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/question", requestData);
        if (response.status === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  return (
    <>
      <Modal
        centered
        show={modal1}
        onHide={() => setModal1(false)}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <h1 className="text-3">Add Question</h1>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex flex-column gap-3 gap-md-4">
            <select
              value={select}
              onChange={(e) => setSelect(e.target.value)}
              className="input-2"
            >
              <option selected disabled>
                Type
              </option>
              {input &&
                input.map((x) => (
                  <option value={x.id}>
                    {x.typeName} {x.property === "null" ? " " : x.property}
                  </option>
                ))}
            </select>

            <select
              value={userid}
              onChange={(e) => setUserId(e.target.value)}
              className="input-2"
            >
              <option selected disabled>
                User
              </option>
              {userData &&
                userData.map((x) => (
                  <option value={x.id}>
                    {x.firstName} {x.lastName}
                  </option>
                ))}
            </select>

            <div className={"border-dim p-2"}>
              <DocEditor
                // value={editor}
                // onChange={(e) => setEditor(e.target.value)}
                handleChange={(data) => {
                  setEditor(data);
                }}
                data={editor}
                {...props}
                convertedContent={convertedContent}
                setConvertedContent={setConvertedContent}
              />
            </div>

            <div>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Required"
                checked={required}
                onChange={(e) => setRequired(!required)}
              />
            </div>

            {select && select && <hr className="m-0" />}

            <div>
              {select && select === "1" && (
                <input
                  type="text"
                  className="input-2"
                  readOnly
                  placeholder="Short Answer text"
                />
              )}
              {select && select === "2" && (
                <input
                  type="number"
                  className="input-2"
                  readOnly
                  placeholder="Enter Number"
                />
              )}
              {select && select === "3" && (
                <input
                  type="datetime-local"
                  className="input-2"
                  readOnly
                  placeholder="Enter Date Time"
                />
              )}
              {select && select === "4" && (
                <input
                  type="email"
                  className="input-2"
                  readOnly
                  placeholder="Enter Email"
                />
              )}
              {select && select === "5" && (
                <>
                  {displayCheckbox &&
                    displayCheckbox.length > 0 &&
                    displayCheckbox.map((x, y) => (
                      <div key={y} className="question-model-input-sec">
                        <div className="input-sec-inner">
                          <input type="radio" id="html" disabled />
                          <input
                            type="text"
                            className="input-2"
                            placeholder="Enter Option"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            onBlur={(e) => setOptionsValue(e.target.value)}
                          />
                        </div>
                        <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                      </div>
                    ))}
                  <div className="question-model-input-sec">
                    <div className="input-sec-inner">
                      <input type="radio" id="html" disabled />
                      <input
                        type="text"
                        className="input-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                    <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                  </div>

                  {displayRadio && displayRadio}

                  <button
                    onClick={() => AppendRadio()}
                    className="btn color-pink fw-500 border-none ps-0"
                  >
                    Add Option
                  </button>
                </>
              )}
              {select && select === "6" && (
                <input type="file" className="mb-2" />
              )}
              {select && select === "7" && (
                <>
                  {displayRadio &&
                    displayRadio.length > 0 &&
                    displayRadio.map((x, y) => (
                      <div key={y} className="question-model-input-sec">
                        <div className="input-sec-inner">
                          <input type="checkbox" id="html" disabled />
                          <input
                            type="text"
                            className="input-2"
                            placeholder="Enter Option"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            onBlur={(e) => setOptionsValue(e.target.value)}
                          />
                        </div>
                        <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                      </div>
                    ))}
                  <div className="question-model-input-sec">
                    <div className="input-sec-inner">
                      <input type="checkbox" id="html" disabled />
                      <input
                        type="text"
                        className="input-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                    <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                  </div>

                  {displayCheckbox && displayCheckbox}

                  <button
                    onClick={() => AppendCheckbox()}
                    className="btn color-pink fw-500 border-none ps-0"
                  >
                    Add Option
                  </button>
                </>
              )}
              {select && select === "8" && (
                <>
                  <div className="question-model-input-sec">
                    <div className="input-sec-inner">
                      <label>1</label>
                      <input
                        type="text"
                        className="input-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                    <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                  </div>
                  {displayCheckbox &&
                    displayCheckbox.length > 0 &&
                    displayCheckbox.map((x, index) => (
                      <>
                        <div className="question-model-input-sec">
                          <div className="input-sec-inner">
                            <label>{index + 1}</label>
                            <input
                              type="text"
                              className="input-2"
                              placeholder="Enter Option"
                              // value={value}
                              // onChange={(e) => setValue(e.target.value)}
                              onBlur={(e) => setOptionsValue(e.target.value)}
                            />
                          </div>
                          <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                        </div>
                      </>
                    ))}
                  {displayRadio &&
                    displayRadio.length > 0 &&
                    displayRadio.map((x, index) => (
                      <>
                        {/* {setCount(index)} */}
                        <div className="question-model-input-sec">
                          <div className="input-sec-inner">
                            <label>{index + 2}</label>
                            <input
                              type="text"
                              className="input-2"
                              placeholder="Enter Option"
                              // value={value}
                              // onChange={(e) => setValue(e.target.value)}
                              onBlur={(e) => setOptionsValue(e.target.value)}
                            />
                          </div>
                          <i className="fa-solid fa-xmark fs_18 p-3 pe-0 pointer"></i>
                        </div>
                      </>
                    ))}

                  {displaySelect && displaySelect}

                  <button
                    onClick={() => AppendSelect()}
                    className="btn color-pink fw-500 border-none ps-0"
                  >
                    Add Option
                  </button>
                </>
              )}
              {select && select === "9" && (
                <textarea rows="4" className="input-2 w-100">
                  Long Answer Text
                </textarea>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonWhite
            name="Close"
            arrow={false}
            onClick={() => setModal1(false)}
          />
          <ButtonPrimary
            name="Add Question"
            arrow={false}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddQuestion;
