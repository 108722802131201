import React, { useEffect } from "react";
import "./contactUs.css";
import ContactUsSection from "./contactUsComponents/contactUsSection/ContactUsSection";

const ContactUs = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div>
        <ContactUsSection />
      </div>
    </>
  );
};

export default ContactUs;
