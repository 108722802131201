import React, { useEffect, useState, forwardRef } from "react";
import client, { urlFor } from "../../sanityClient/sanityClient";
import "./library.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SearchBox from "../../../../../components/searchBox/SearchBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

const Library = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const categoryMenu = [
    "all",
    "awareness",
    "screening",
    "treatments",
    "nutrition",
    "wellness",
    "employee engagement",
  ];
  useEffect(() => {
    const fetchArticles = async () => {
      let query;
      if (selectedCategory === "all") {
        query = `*[_type == "post" && author->name == "Team Onco-Connect"] | order(publishedAt desc){
          ...,
          mainImage,
          "imageUrl": mainImage.asset->url,
          "authorName": author->name,
          "authorImage": author->image.asset->url,
          categories[]->{
            title
          }
        }`;
      } else {
        query = `*[_type == "post" && author->name == "Team Onco-Connect" && categories[0]->title == "${selectedCategory}"] | order(publishedAt desc){
          ...,
          mainImage,
          "imageUrl": mainImage.asset->url,
          "authorName": author->name,
          "authorImage": author->image.asset->url,
          categories[]->{
            title
          }
        }`;
      }

      try {
        const data = await client.fetch(query);
        console.log("Data fetched:", data);

        // Generate optimized URLs for images
        const articlesWithOptimizedImages = data.map((article) => ({
          ...article,
          imageUrl: urlFor(article.mainImage).width(800).url(),
          authorImageUrl: urlFor(article.authorImage).width(50).url(),
        }));

        setArticles(articlesWithOptimizedImages);
        setFilteredArticles(articlesWithOptimizedImages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchArticles();
  }, [selectedCategory]);
  const handleTab = (category) => {
    setSelectedCategory(category.toLowerCase());
  };

  const singlePost = (id) => {
    navigate(`/article/${id}`);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long" };
    const date = new Date(dateString);

    return `${date.toLocaleString(undefined, {
      month: "long",
    })} , ${date.getFullYear()}`;
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = articles.filter((article) =>
      article.title.toLowerCase().includes(query)
    );
    setFilteredArticles(filtered);
  };

  return (
    <div className="library-container">
      <h1 className="heading-main">
        Onco-Connect Digital <br className="responsive-break" /> Health Library
      </h1>
      <div>
        <div className="my-library-categories">
          <Swiper loop={false} slidesPerView={"auto"} className="mySwiper">
            {categoryMenu.map((item, index) => (
              <SwiperSlide
                key={index}
                className={item === "all" ? "d-none" : undefined}
              >
                <div
                  className="my-library-categories-inner"
                  onClick={() => handleTab(item)}
                >
                  {item}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="my-5 py-xxl-4">
          <div className="row g-4 g-xl-5">
            <div className="col-lg-4">
              <SearchBox value={searchQuery} onChange={handleSearchChange} />
            </div>
          </div>
        </div>
        <div>
          <div className="post-grid-container">
            {filteredArticles.map((post) => (
              <div key={post._id} className="post-card">
                <img
                  src={post.imageUrl}
                  alt={post.title}
                  className="post-image"
                  loading="lazy"
                />
                <div className="post-info">
                  <div className="flex justify-between">
                    <button
                      className="category-tag"
                      style={{
                        backgroundColor:
                          post.categories[0].title === "awareness" ||
                          post.categories[0].title === "health"
                            ? "#F2F6FA"
                            : "#FFEFE2",
                      }}
                    >
                      {post.categories && post.categories.length > 0
                        ? post.categories[0].title
                        : "No Category"}
                    </button>

                    <span className="post-date">
                      {formatDate(post.publishedAt)}
                    </span>
                  </div>
                  <h3
                    onClick={() => singlePost(post._id)}
                    className="post-title"
                  >
                    {post.title}
                  </h3>
                  <div className="author-info">
                    <img
                      src={post.authorImageUrl}
                      alt={post.authorName || "Author"}
                      className="author-avatar"
                      loading="lazy"
                    />
                    <div>
                      <p className="author-name">{post.authorName}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Library;
