import React, { useState, useEffect } from "react";
import { GET, PUT } from "../../../constant/RequestAuthService";
import StoredVariables, { SetSession } from "../../../constant/Session";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
function Profile() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const GetProfile = async (id) => {
    const { data, status } = await GET("account/admin/get-profile");
    if (status === 200) {
      setFname(data && data.firstName);
      setLname(data && data.lastName);
      setEmail(data && data.email);
      setMobile(data && data.mobileNumber);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      firstName: fname,
      lastName: lname,
      profilePicture: "",
      mobileNumber: mobile,
    };
    // console.log(requestData)
    const validation = new Validator(requestData, {
      firstName: "required",
      lastName: "required",
      mobileNumber: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT("admin/update-profile", requestData);
      if (response.status === 200) {
        toast.success(response.message);
        await SetSession(StoredVariables.username, fname);
        setFname("");
        setLname("");
        setMobile("");
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <div className="col-md-12 pt-2">
      <h4 className="font-weight-bold">Profile</h4>
      <div className="card m-3">
        <div className="card-body">
          <form className="forms-sample" onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputUsername1">
                  <b>First Name</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputEmail1">
                  <b>Last Name</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputPassword1">
                  <b>Email</b>
                </label>
                <input
                  type="email"
                  readonly
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputConfirmPassword1">
                  <b>Mobile No.</b>
                </label>
                <input
                  type="phone"
                  className="form-control"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Phone"
                  required
                />
              </div>

              <div className="col-md-6 col-xs-12 form-group in-box">
                <label for="exampleInputConfirmPassword1">
                  <b>Profile Picture</b>
                </label>
                <input
                  type="file"
                  name="profile_picture"
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex p-1">
              <button type="submit" className="btn btn-primary mr-2">
                Submit
              </button>
              <button type="button" className="btn btn-new2 bg-new">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
