import React from "react";
import "./sidebarMenu.css";
import logo from "../../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const SidebarMenu = ({ headerMenu, isMenuOpen, setIsMenuOpen }) => {
  return (
    <>
      <div className={`side-bar-menu ${isMenuOpen ? "active" : ""}`}>
        <div className="position-relative">
          <i
            className="fa-solid fa-xmark close-icon pointer p-1 ps-0"
            onClick={() => setIsMenuOpen(false)}
          ></i>
          <div className="d-flex justify-content-center">
            <Link
              className="navbar-brand"
              to="/"
              onClick={() => setIsMenuOpen(false)}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <ul className="navbar-nav mt-4 ms-3">
          {headerMenu &&
            headerMenu.map((item, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link
                    className={item.active ? "nav-link active" : "nav-link"}
                    to={item.link}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
          <li className="nav-item">
            <Link to="" className="nav-link">
              Log Out
            </Link>
          </li>
        </ul>
        <div className="d-flex align-items-center justify-content-center gap-4 pt-2">
          <a
            href="https://www.facebook.com/CompleteCancerCare"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook-f fs_18"></i>
          </a>
          {/* <a href="/">
            <i className="fa-brands fa-twitter fs_18"></i>
          </a> */}
          <a
            href="https://www.linkedin.com/company/94872400/admin/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin-in fs_18"></i>
          </a>
        </div>
      </div>
      <div
        id="overlay1"
        className={`d-xl-none ${isMenuOpen ? "d-block" : "d-none"}`}
        onClick={() => setIsMenuOpen(false)}
      ></div>
    </>
  );
};

export default SidebarMenu;
