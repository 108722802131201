import React from "react";
import "./solutionsForBusiness.css";
import Card1 from "../cards/Card1";

const SolutionsForBusiness = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main mx-auto business-heading-width">
        API, Organisation, and Management Portal solutions for business
      </h1>
      <div className="solutions-business-card card1-width mx-auto">
        <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5">
          <div className="col">
            <Card1
              title={"API Solutions "}
              subTitle={"Seamless connection, Limitless potential"}
              description={
                "Our specialist team manages the entire process of integrating API solutions"
              }
              buttonText={"Speak To Us"}
              bgClass={"bg-linen"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Working with Organisations"}
              subTitle={"Your vision, Our collaboration"}
              description={
                "Our technology, health & wellness team help deliver a platform according to your organisational needs"
              }
              buttonText={"Speak To Us"}
              bgClass={"bg-light-blue"}
              link={"/contactUs"}
            />
          </div>
          <div className="col">
            <Card1
              title={"Management Portals and Dashboards"}
              subTitle={"Transforming data, Into direction"}
              description={
                "We monitor, analyse, and make informed decisions based on the data presented, tracking progress, and identifying areas that require attention"
              }
              buttonText={"Speak To Us"}
              bgClass={"bg-green"}
              link={"/contactUs"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsForBusiness;
