import React from "react";
import "./cancerTreatmentsSection.css";
import FullWidthCard4 from "../../../../../components/cards/FullWidthCard4";
import { ButtonSecondary } from "../../../../../components/buttons/Buttons";
import { Link } from "react-router-dom";

const CancerTreatmentsSection = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">Cancer Treatments</h1>
      <div>
        <FullWidthCard4
          title={"Cancer Surgery"}
          description={
            "Cancer Surgery aims to remove tumours or affected tissue from the body. It is one of the main treatments for many types of cancer. You can have surgery to: diagnose cancer, treat cancer (on its own or with other treatments), reduce your risk of getting a particular type of cancer (if you already have a high risk). Surgeons use precise techniques to excise the cancerous cells. Sometimes, surgery may be combined with other treatments like chemotherapy or radiation for optimal results. "
          }
          bgClass={"cancerTreatments-card-bg1"}
          classes={"mb-4 mb-lg-5"}
        />
        <FullWidthCard4
          title={"Chemotherapy"}
          description={
            "Cancer chemotherapy is a systemic treatment that uses powerful drugs to kill or slow the growth of cancer cells throughout the body. It is often administered through infusion or oral medications. Chemotherapy aims to destroy cancer cells, shrink tumors, prevent metastasis, and potentially cure or control the progression of cancer."
          }
          bgClass={"cancerTreatments-card-bg2"}
          classes={"mb-4 mb-lg-5"}
        />

        <FullWidthCard4
          title={"Radiotherapy "}
          description={
            "Radiotherapy, also known as radiation therapy, is a common cancer treatment that uses high-energy radiation to target and destroy cancer cells. It can be delivered externally (external beam radiation) or internally (brachytherapy). Radiotherapy works by damaging the DNA of cancer cells, preventing their ability to multiply and causing them to die. This treatment is carefully planned and administered to minimize damage to healthy tissues surrounding the tumor."
          }
          bgClass={"cancerTreatments-card-bg1"}
          classes={"mb-4 mb-lg-5"}
        />
        <FullWidthCard4
          title={"Proton Beam Therapy"}
          description={
            "Proton beam therapy is an advanced form of radiation therapy used to treat certain types of cancer. It uses protons, which are positively charged particles, to precisely target and destroy cancer cells while minimising damage to surrounding healthy tissues. Proton therapy is particularly beneficial for tumors located near critical structures or in paediatric patients, as it reduces the risk of long-term side effects."
          }
          bgClass={"cancerTreatments-card-bg2"}
          classes={"mb-4 mb-lg-5"}
        />

        <FullWidthCard4
          title={"NanoKnife"}
          description={
            "NanoKnife treatment, also known as irreversible electroporation (IRE), is a minimally invasive technique used to treat certain types of cancer. It uses short, intense electrical pulses to create tiny pores in cancer cell membranes, leading to cell death. NanoKnife targets the tumor precisely while preserving nearby blood vessels and other critical structures. This treatment option is particularly useful for tumors that are close to sensitive areas or deemed inoperable, offering a potential alternative for patients who are not suitable for traditional surgery or other therapies."
          }
          bgClass={"cancerTreatments-card-bg1"}
          classes={"mb-4 mb-lg-5"}
        />
        <FullWidthCard4
          title={"CAR T-cell"}
          description={
            "CAR T-cell therapy is a groundbreaking immunotherapy treatment that harnesses the power of a patient's own immune cells to fight cancer. It involves collecting T-cells from the patient, modifying them in the laboratory to express chimeric antigen receptors (CARs), and infusing them back into the patient. These CARs enable the T-cells to recognise and target specific cancer cells, leading to their destruction. CAR T-cell therapy has shown remarkable success in treating certain types of blood cancers, offering new hope for patients who have not responded to conventional treatments."
          }
          bgClass={"cancerTreatments-card-bg2"}
          classes={"mb-4 mb-lg-5"}
        />

        <FullWidthCard4
          title={"Immunotherapy"}
          description={
            "Immunotherapy is a revolutionary approach to cancer treatment that harnesses the body's own immune system to fight cancer cells. It works by stimulating or enhancing the immune system's ability to recognise and destroy cancer cells. Immunotherapies include immune checkpoint inhibitors, adoptive cell transfer, and cytokine therapy, among others. These treatments have shown remarkable success in various cancers, leading to durable responses and improved survival rates."
          }
          bgClass={"cancerTreatments-card-bg1"}
          classes={"mb-4 mb-lg-5"}
        />
        <FullWidthCard4
          title={"Targeted Therapy"}
          description={
            "Targeted therapy is a type of cancer treatment that focuses on specific molecules or pathways involved in the growth and progression of cancer cells. It uses drugs or other substances to block the signaling pathways or target specific proteins that are essential for cancer cell survival and growth. Unlike traditional chemotherapy, targeted therapies aim to selectively kill cancer cells while minimising harm to normal cells, resulting in potentially fewer side effects."
          }
          bgClass={"cancerTreatments-card-bg2"}
          classes={"mb-4 mb-lg-5"}
        />

        <FullWidthCard4
          title={"Clinical Trials"}
          description={
            "Clinical trials in cancer play a crucial role in advancing cancer research and improving treatment outcomes. They involve carefully designed studies to evaluate new therapies, treatment strategies, or diagnostic methods in cancer patients. These trials help determine the safety, efficacy, and potential side effects of experimental interventions."
          }
          bgClass={"cancerTreatments-card-bg1"}
          classes={"mb-4 mb-lg-5"}
        />
      </div>

      <div className="d-none d-md-flex justify-content-center pt-4 mt-5">
        <Link to="/contactUs">
          <ButtonSecondary
            name={"All and Rare Cancers Treated"}
            classes={"btn-lg"}
          />
        </Link>
      </div>
    </div>
  );
};

export default CancerTreatmentsSection;
