import React from "react";
import "./cards.css";
import propTypes from "prop-types";

const Card4 = ({ title, description, image, classes }) => {
  return (
    <div className={`card-4 ${classes ? classes : ""}`}>
      <img src={image} alt="" />
      <div>
        <h3 className="heading-4 text-center mb-4">{title}</h3>
        <p className="mb-0 text-center text-4">{description}</p>
      </div>
    </div>
  );
};
export default Card4;

Card4.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  classes: propTypes.string,
};
