import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../digitalLibrary/sanityClient/sanityClient";
import BlockContent from "@sanity/block-content-to-react";
import "./article.css";

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  const customSerializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "h1":
            return <h1 className="custom-h1">{props.children}</h1>;
          case "h2":
            return <h2 className="custom-h2">{props.children}</h2>;
          case "h3":
            return <h3 className="custom-h3">{props.children}</h3>;
          case "h4":
            return <h4 className="custom-h4">{props.children}</h4>;
          case "h5":
            return <h5 className="custom-h5">{props.children}</h5>;
          case "h6":
            return <h6 className="custom-h6">{props.children}</h6>;
          case "blockquote":
            return <blockquote>{props.children}</blockquote>;
          default:
            return <p>{props.children}</p>;
        }
      },
    },
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    function getOrdinalNum(n) {
      return (
        n + (["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th")
      );
    }

    return `${getOrdinalNum(day)} ${month} ${year}`;
  }

  useEffect(() => {
    const fetchArticle = async () => {
      const query = `*[_type == "post" && _id == $id][0]{
        _id,
        title,
        "imageUrl": mainImage.asset->url,
        body,
        "authorName": author->name,
        "authorImage": author->image.asset->url,
        publishedAt,
        categories[]->{title}
      }`;

      try {
        const data = await client.fetch(query, { id });
        setArticle(data);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="article-banner">
        <img
          src={article.imageUrl}
          alt={article.title}
          className="main-banner"
        />
      </div>
      <h1 className="article-heading">{article.title}</h1>
      <div className="article-container">
        <div className="article-body">
          <BlockContent blocks={article.body} serializers={customSerializers} />
        </div>
      </div>
    </>
  );
};

export default Article;
