import React from "react";
import "./solutionsFigures.css";
import Figures from "../../../../../components/figures/Figures";

const SolutionsFigures = () => {
  return (
    <div className="container my-container solutions-figures-container my-lg-5">
      <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5">
        <div className="col">
          <Figures
            digit={"30%"}
            description={
              "Less than 30% of eligible women receive regular Pap smears or HPV tests for cervical cancer screening  in India"
            }
          />
        </div>
        <div className="col">
          <Figures
            digit={"40%"}
            description={
              "Only around 40% of eligible population completes bowel cancer  screening in Singapore"
            }
          />
        </div>
        <div className="col">
          <Figures
            digit={"6.5%  - 38%"}
            description={
              "UAE, Oman, Lebanon, Turkey, Iran, and Saudi Arabia demonstrated screening uptake between 6.5% and 38% for bowel cancer screening"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionsFigures;
