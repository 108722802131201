import React from "react";
import "./process.css";
import process1 from "../../../assets/images/process-1.jpg";
import process2 from "../../../assets/images/process-2.jpg";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../../assets/images/arrow.png";

export default function Process() {
  const navigate = useNavigate();
  const steps = [
    { number: "1", bg: "rgba(255, 239, 226, 1)", text: "Speak to Us" },
    { number: "2", bg: "rgba(246, 241, 231, 1)", text: "Send Your Reports" },
    { number: "3", bg: "rgba(242, 246, 250, 1)", text: "Complete Payment" },
    { number: "4", bg: "rgba(191, 221, 203, 1)", text: "Consult" },
    { number: "5", bg: "rgba(246, 241, 231, 1)", text: "Receive Expert Report" }
  ];

  const StepCard = ({ step }) => (
    <div style={{ backgroundColor: step.bg }} className="step-card">
      <p>{step.number}</p>
      <h5>{step.text}</h5>
    </div>
  );

  return (
    <div className="process-page">
      <div  className="process-heading">
        <p>Cancer Second Opinion Process</p>
      </div>
      <div className="process-container">
        <div className="process">
          <h3 style={{ color: "rgba(233, 150, 117, 1)" }} className="process-heading">
            <span className="stat-number1">400+</span> CANCERS TREATED
          </h3>
          <img className="process-img" src={process1} alt="Online Video Consultation" />
          <p className="process-title">Online Video Consultation</p>
        </div>
        <div className="process">
          <h3 style={{ color: "rgba(17, 144, 68, 1)" }} className="process-heading">
            <span className="stat-number2">250+</span> ONCOLOGY SPECIALISTS
          </h3>
          <img className="process-img" src={process2} alt="Online Second Opinions" />
          <p className="process-title">Online Second Opinion</p>
        </div>
      </div>
      <div className="steps-container">
        {steps.map((step, index) => (
          <StepCard key={index} step={step} />
        ))}
      </div>
      <div className="process-button-container">  <button
        onClick={() => {
          navigate("/contactUs");
        }}
        className="process-button"
      >
        Contact us
        <img style={{'paddingTop':'0.5rem'}} src={rightArrow} alt="" />
      </button></div>
    
     
    </div>
  );
}
