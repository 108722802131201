import React, { useEffect } from "react";
import "./solutions.css";
import MainBanner from "../../../components/banners/MainBanner";
import solutionsBannerImg from "../../../assets/images/solutions-banner-img.png";
import CancerRisk from "./solutionsComponents/cancerRisk/CancerRisk";
import CancerCheck from "./solutionsComponents/cancerCheck/CancerCheck";
import SolutionsFigures from "./solutionsComponents/solutionsFigures/SolutionsFigures";
import CancerDiagnostics from "./solutionsComponents/cancerDiagnostics/CancerDiagnostics";
import CancerConsultation from "./solutionsComponents/cancerConsultation/CancerConsultation";
import CancerTreatment from "./solutionsComponents/cancerTreatment/CancerTreatment";
// import ManagementProgram from "./solutionsComponents/managementProgram/ManagementProgram";
import CancerCareEngagement from "./solutionsComponents/cancerCareEngagement/CancerCareEngagement";
import KnowMore from "./solutionsComponents/knowMore/KnowMore";
import Separator from "../../../components/separator/Separator";
import DevelopingAI from "./solutionsComponents/developingAI/DevelopingAI";
// import OurPartners from "../../components/ourPartners/OurPartners";

const Solutions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MainBanner
        title={"Transforming Cancer Care Through Our Technological Solutions"}
        backgroundImg={solutionsBannerImg}
        textWidthClass={"solution-banner-title-width"}
        classes={"solutions-banner"}
      />
      <DevelopingAI />
      <CancerRisk />
      <CancerCheck />
      <div className="d-none d-lg-block">
        <Separator />
      </div>
      <SolutionsFigures />
      <CancerDiagnostics />
      <CancerConsultation />
      <div className="d-none d-md-block">
        <CancerTreatment />
      </div>
      {/* <ManagementProgram /> */}
      <CancerCareEngagement />
      <KnowMore />
      {/* <OurPartners /> */}
    </>
  );
};

export default Solutions;
