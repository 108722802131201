import React, { useEffect } from "react";
import "./employers.css";
import MainBanner from "../../../components/banners/MainBanner";
import employersBannerImg from "../../../assets/images/employers-banner-img.png";
import HelpingEmployers from "./employersComponents/helpingEmployers/HelpingEmployers";
import PitchBoard from "../../../components/pitchBoard/PitchBoard";
import EmployersFigures from "./employersComponents/employersFigures/EmployersFigures";
import EngagingIndividuals from "../../../components/engagingIndividuals/EngagingIndividuals";
import ActionableInsights from "../../../components/actionableInsights/ActionableInsights";
import SolutionsForBusiness from "../../../components/solutionsForBusiness/SolutionsForBusiness";
import WeCanHelp from "../../../components/weCanHelp/WeCanHelp";

const Employers = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MainBanner
        title={
          "Support your employees when they need you the most through their cancer journey"
        }
        backgroundImg={employersBannerImg}
        textWidthClass={"employers-banner-title-width"}
        classes={"employers-banner"}
      />
      <HelpingEmployers />
      <PitchBoard
        text={
          "Your Team’s Health, Our Priority Through Access To Top-Tier Cancer Services"
        }
        classes={"employers-pitch-board"}
      />
      <EmployersFigures />
      <EngagingIndividuals />
      <ActionableInsights />
      <SolutionsForBusiness />
      <WeCanHelp />
    </>
  );
};

export default Employers;
