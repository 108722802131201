import React, { useState } from "react";
import { Country } from "country-state-city";
import "./phoneInput.css";
import { TextField } from "@mui/material";

const PhoneInput = ({
  phoneCodeValue,
  phoneCodeValueChange,
  phoneValue,
  phoneValueChange,
  variant,
  disabled,
}) => {
  const [allCountry] = useState(Country.getAllCountries());

  return (
    <>
      <div
        className={`phone-number-input ${variant === "pink" && "pink-select"}`}
      >
        <div className="my-select">
          <select
            className="input-2"
            value={phoneCodeValue}
            onChange={phoneCodeValueChange}
            disabled={disabled}
          >
            {allCountry &&
              allCountry.length > 0 &&
              allCountry.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={
                      item.phonecode.charAt(0) === "+"
                        ? item.phonecode
                        : "+" + item.phonecode
                    }
                  >
                    {item.name}{" "}
                    {item.phonecode.charAt(0) === "+"
                      ? item.phonecode
                      : "+" + item.phonecode}
                  </option>
                );
              })}
          </select>
        </div>
        <TextField
          fullWidth
          className="mui-tex-field"
          label="Phone number"
          value={phoneValue}
          onChange={phoneValueChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default PhoneInput;
