import React from "react";
import "./contactUsSection.css";
import ContactDetails from "../../../../../components/contactDetails/ContactDetails";
import DemoContactForm from "../../../../../components/demoContactForm/DemoContactForm";

const ContactUsSection = () => {
  return (
    <div className="container my-container">
      <div className="row row-cols-1 row-cols-lg-2 g-0">
        <div className="col">
          <div className="contact-sec p-4 p-lg-5 ps-lg-0">
            <ContactDetails
              title={"Contact Us:"}
              description={
                "Get an insightful demonstration of all that Onco-Connect has to offer to you or your organisation. Fill in your details so that our team can reach out to you at the earliest."
              }
              classes={"contact-us-sec"}
              // variant={"green"}
            />
          </div>
        </div>
        <div className="col">
          <div className="bg-light-blue border border-2 rounded-3 p-4 p-lg-5 h-100">
            <DemoContactForm
              heading="Enquiry Form"
              // variant={"green"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
