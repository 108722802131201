import React from "react";
import rightArrow from "../../../assets/images/arrow-white.png";
import "./supportFramework.css";
import { useNavigate } from "react-router-dom";

const Card = ({ heading, desc, bgColor }) => {
  const navigate = useNavigate();

  return (
    <div className="card" style={{ backgroundColor: bgColor }}>
      <p className="card-heading">{heading}</p>
      <p className="card-desc">{desc}</p>
      <button onClick={() => {
              navigate("/contactUs");
            }} className="card-button">
        <h5>Learn More</h5>
        <img src={rightArrow} alt="Right Arrow" />
      </button>
    </div>
  );
};

const SupportFramework = () => {

  return (
    <div className="support-framework-container">
      <h1 className="support-framework-heading">
        Structured Framework To Support
      </h1>
      <div className="cards-container">
        <Card
          heading="Patients & Carers"
          desc="Get personalised expert opinion about your diagnosis and treatment 
          from the comfort of your home"
          bgColor="rgba(242, 246, 250, 1)"
        />
        <Card
          heading="Health Care Professionals"
          desc="Liaise with cancer experts from UK & India to ensure your patients get the best cancer treatment possible."
          bgColor="rgba(255, 239, 226, 1)"
        />
        <Card
          heading="Corporates & Insurance"
          desc="We provide customised packages for employers & insurance companies to promote proactive wellness and reduce healthcare costs"
          bgColor="rgba(246, 241, 231, 1)"
        />
      </div>
    </div>
  );
};

export default SupportFramework;
