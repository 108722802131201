import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DocEditor from "../../../components/CkEditor";
import { ENCODE } from "../../../constant/Util";
import { GET, POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
const AddQuestion = ({ props }) => {
  const navigate = useNavigate();
  const [editor, setEditor] = useState(null);
  const [select, setSelect] = useState();
  const [required, setRequired] = useState(false);
  const [displayRadio, setDisplayRadio] = useState([]);
  const [displayCheckbox, setDisplayCheckBox] = useState([]);
  const [displaySelect, setDisplaySelect] = useState([]);
  const [options, setOptions] = useState([]);
  const [count, setCount] = useState(2);
  const [convertedContent, setConvertedContent] = useState(null);
  const [input, setInput] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userid, setUserId] = useState();

  const GetOptionTypes = async () => {
    const { data, status } = await GET("question/get-type");
    if (status === 200) {
      setInput(data && data);
    }
  };

  useEffect(() => {
    GetOptionTypes();
    GetUsers();
  }, []);

  useEffect(() => {
    setRequired(!required);
  }, [select]);

  const GetUsers = async () => {
    const { data, status } = await GET("admin/user");
    if (status === 200) {
      setUserData(data && data);
    }
  };

  const AppendRadio = () => {
    setDisplayRadio((displayRadio) => [
      ...displayRadio,
      <div className="row">
        <div className="col-md-1">
          <input type="radio" id="html" disabled className="mb-2" />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            className="mb-2"
            placeholder="Enter Option"
            // value={value}
            // onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => setOptionsValue(e.target.value)}
          />
        </div>
      </div>,
    ]);
  };
  const AppendCheckbox = () => {
    setDisplayCheckBox((displayCheckbox) => [
      ...displayCheckbox,
      <div className="row">
        <div className="col-md-1">
          <input type="checkbox" id="html" disabled className="mb-2" />
        </div>
        <div className="col-md-3">
          <label for="html">
            <input
              type="text"
              className="mb-2"
              placeholder="Enter Option"
              // value={value}
              // onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => setOptionsValue(e.target.value)}
            />
          </label>
        </div>
      </div>,
    ]);
  };

  const AppendSelect = () => {
    if (displayCheckbox && displayCheckbox.length > count) {
      setCount(displayCheckbox.length + 1);
    } else if (displayRadio && displayRadio.length > count) {
      setCount(displayRadio.length + 1);
    } else {
      setCount(count + 1);
    }
    // setCount(count + 1);
    setDisplaySelect((displaySelect) => [
      ...displaySelect,
      <div className="row">
        <div className="col-md-1">
          <p>{count}</p>
        </div>
        <div className="col-md-3">
          <input
            type="text"
            className="mb-2"
            placeholder="Enter Option"
            onBlur={(e) => setOptionsValue(e.target.value)}
          />
        </div>
      </div>,
    ]);
  };

  const setOptionsValue = (value) => {
    if (value !== "") {
      setOptions((options) => [
        ...options,
        {
          value: value,
          isAnswers: "false",
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      typeId: parseInt(select),
      title: ENCODE(convertedContent),
      userId: userid,
      allowedAnswers: 1,
      isRequired: required,
      options: options,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      typeId: "required",
      title: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("admin/question", requestData);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("admin/questions");
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  // console.log(required);
  return (
    <div className="card p-3">
      <div className="row">
        <div className="col-md-3">
          <h2 className="card-heading">Add Question</h2>
        </div>
        <div className="col-md-3">
          {select && select && (
            <>
              <label>Required</label>
              <label className="switch">
                {" "}
                <input
                  type="checkbox"
                  value={required}
                  onChange={(e) => setRequired(e.target.value)}
                />
                <span className="slider round"></span>
              </label>
            </>
          )}
        </div>
        <div className="col-md-3">
          <select value={select} onChange={(e) => setSelect(e.target.value)}>
            <option>Select</option>
            {input &&
              input.map((x) => (
                <option value={x.id}>
                  {x.typeName} {x.property === "null" ? " " : x.property}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-3">
          <select value={userid} onChange={(e) => setUserId(e.target.value)}>
            <option>Select</option>
            {userData &&
              userData.map((x) => (
                <option value={x.id}>
                  {x.firstName} {x.lastName}
                </option>
              ))}
          </select>
        </div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="card-body mb-3">
          <div className="col">
            <DocEditor
              // value={editor}
              // onChange={(e) => setEditor(e.target.value)}
              handleChange={(data) => {
                setEditor(data);
              }}
              data={editor}
              {...props}
              convertedContent={convertedContent}
              setConvertedContent={setConvertedContent}
            />
          </div>
          <div className="col"></div>
          <div className="row">
            <div className="col">
              {select && select === "1" && (
                <input
                  type="text"
                  className="mb-2"
                  readOnly
                  placeholder="Short Answer text"
                />
              )}
              {select && select === "2" && (
                <input
                  type="number"
                  className="mb-2"
                  readOnly
                  placeholder="Enter Number"
                />
              )}
              {select && select === "3" && (
                <input
                  type="datetime-local"
                  className="mb-2"
                  readOnly
                  placeholder="Enter Date Time"
                />
              )}
              {select && select === "4" && (
                <input
                  type="email"
                  className="mb-2"
                  readOnly
                  placeholder="Enter Email"
                />
              )}
              {select && select === "5" && (
                <>
                  {displayCheckbox &&
                    displayCheckbox.length > 0 &&
                    displayCheckbox.map((x) => (
                      <div className="row">
                        <div className="col-md-1">
                          <input
                            type="radio"
                            id="html"
                            disabled
                            className="mb-2"
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="mb-2"
                            placeholder="Enter Option"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            onBlur={(e) => setOptionsValue(e.target.value)}
                          />
                        </div>
                      </div>
                    ))}
                  <div className="row">
                    <div className="col-md-1">
                      <input type="radio" id="html" disabled className="mb-2" />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="mb-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                  </div>

                  {displayRadio && displayRadio}
                  <p
                    onClick={() => AppendRadio()}
                    style={{ cursor: "pointer" }}
                  >
                    Add Option
                  </p>
                </>
              )}
              {select && select === "6" && (
                <input type="file" className="mb-2" />
              )}
              {select && select === "7" && (
                <>
                  {displayRadio &&
                    displayRadio.length > 0 &&
                    displayRadio.map((x) => (
                      <div className="row">
                        <div className="col-md-1">
                          <input
                            type="checkbox"
                            id="html"
                            disabled
                            className="mb-2"
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="mb-2"
                            placeholder="Enter Option"
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            onBlur={(e) => setOptionsValue(e.target.value)}
                          />
                        </div>
                      </div>
                    ))}
                  <div className="row">
                    <div className="col-md-1">
                      <input
                        type="checkbox"
                        id="html"
                        disabled
                        className="mb-2"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="mb-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                  </div>

                  {displayCheckbox && displayCheckbox}
                  <p
                    onClick={() => AppendCheckbox()}
                    style={{ cursor: "pointer" }}
                  >
                    Add Option
                  </p>
                </>
              )}
              {select && select === "8" && (
                <>
                  <div className="row">
                    <div className="col-md-1">
                      <p>1</p>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="mb-2"
                        placeholder="Enter Option"
                        // value={value}
                        // onChange={(e) => setValue(e.target.value)}
                        onBlur={(e) => setOptionsValue(e.target.value)}
                      />
                    </div>
                  </div>
                  {displayCheckbox &&
                    displayCheckbox.length > 0 &&
                    displayCheckbox.map((x, index) => (
                      <>
                        {/* {setCount(index)} */}
                        <div className="row">
                          <div className="col-md-1">
                            <p>{index + 1}</p>
                          </div>
                          <div className="col-md-3">
                            <input
                              type="text"
                              className="mb-2"
                              placeholder="Enter Option"
                              // value={value}
                              // onChange={(e) => setValue(e.target.value)}
                              onBlur={(e) => setOptionsValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  {displayRadio &&
                    displayRadio.length > 0 &&
                    displayRadio.map((x, index) => (
                      <>
                        {/* {setCount(index)} */}
                        <div className="row">
                          <div className="col-md-1">
                            <p>{index + 2}</p>
                          </div>
                          <div className="col-md-3">
                            <input
                              type="text"
                              className="mb-2"
                              placeholder="Enter Option"
                              // value={value}
                              // onChange={(e) => setValue(e.target.value)}
                              onBlur={(e) => setOptionsValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  {displaySelect && displaySelect}
                  <p
                    onClick={() => AppendSelect()}
                    style={{ cursor: "pointer" }}
                  >
                    Add Option
                  </p>
                </>
              )}
              {select && select === "9" && (
                <textarea rows="4" cols="50">
                  Long Answer Text
                </textarea>
              )}

              <button type="submit" className="btn btn-primary">
                Add Question
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddQuestion;
