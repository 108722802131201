import React, { useState } from "react";
import "./userSignup.css";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ButtonPrimary } from "../../../components/buttons/Buttons";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneInput from "../../../components/phoneInput/PhoneInput";
import { POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserSignup = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    fName: "",
    lName: "",
    email: "",
    phoneCode: "+91",
    phone: "",
    username: "",
    password: "",
    tc: false,
  });

  const onChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      firstName: formValues.fName,
      lastName: formValues.fName,
      email: formValues.email,
      password: formValues.password,
      mobileCountryCode: formValues.phoneCode,
      mobileNumber: formValues.phone,
    };
    // console.log(requestData);
    const validation = new Validator(requestData, {
      firstName: "required",
      lastName: "required",
      email: "required|email",
      password: "required",
      mobileCountryCode: "required",
      mobileNumber: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST("account/sign-up", requestData);
        // console.log(response);
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/employee/login");
        } else {
          toast.error(response.message);
        }
      }
    }
  };

  return (
    <div className="container my-4 my-md-5">
      <div className="pro-card model-card mx-auto position-relative">
        <div>
          <h1 className="heading-5 fw-400 text-center mb-4 mb-md-5">Sign up</h1>
          <div>
            <form
              className="d-flex flex-column gap-3 gap-md-4"
              onSubmit={(e) => handleSubmit(e)}
            >
              <TextField
                fullWidth
                className="mui-tex-field"
                label="First name"
                value={formValues.fName}
                onChange={(e) => onChange("fName", e.target.value)}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Last name"
                value={formValues.lName}
                onChange={(e) => onChange("lName", e.target.value)}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Email"
                value={formValues.email}
                onChange={(e) => onChange("email", e.target.value)}
              />
              <PhoneInput
                phoneCodeValue={formValues.phoneCode}
                phoneCodeValueChange={(e) =>
                  onChange("phoneCode", e.target.value)
                }
                phoneValue={formValues.phone}
                phoneValueChange={(e) => onChange("phone", e.target.value)}
                variant={"pink"}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Username"
                value={formValues.username}
                onChange={(e) => onChange("username", e.target.value)}
              />
              <TextField
                fullWidth
                className="mui-tex-field"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        className="btn p-0 border-none"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </span>
                    </InputAdornment>
                  ),
                }}
                label="Create password"
                value={formValues.password}
                onChange={(e) => onChange("password", e.target.value)}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    className="mui-check-box"
                    checked={formValues.tc}
                    onChange={(e) => onChange("tc", e.target.checked)}
                  />
                }
                label="Agree with terms & conditions"
              />

              <ButtonPrimary name="Sign up" arrow={false} />

              <p className="text-center m-0">
                Already have an account?{" "}
                <span
                  className="color-pink pointer"
                  onClick={() => {
                    navigate("/employee/login");
                  }}
                >
                  Log in
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;
