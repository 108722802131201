// // import { CKEditor } from "@ckeditor/ckeditor5-react";
// // import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// // import { Img_url } from "constant";
// // import { POST } from "constant/RequestAuthService";
// // const API_URL = 'admin/upload_image';
// // import React, { useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// export default function DocEditor({ handleChange, ...props }) {
//   //   function uploadAdapter(loader) {
//   //     return {
//   //       upload: () => {
//   //         return new Promise((resolve, reject) => {
//   //           const body = new FormData();
//   //           loader.file.then(async (file) => {
//   //             body.append("photo", file);
//   //             const res = await POST(`${API_URL}`, body, true);
//   //             if (res.status === 200) {
//   //               resolve({
//   //                 default: `${Img_url}/${res.data}`,
//   //               });
//   //             } else {
//   //               reject(res.message);
//   //             }
//   //           });
//   //         });
//   //       },
//   //     };
//   //   }
//   //   function uploadPlugin(editor) {
//   //     editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
//   //       return uploadAdapter(loader);
//   //     };
//   //   }
//   return (
//     <div className="App">
//       <Editor
//         editorState={editorState}
//         toolbarClassName="toolbarClassName"
//         wrapperClassName="wrapperClassName"
//         editorClassName="editorClassName"
//         onEditorStateChange={this.onEditorStateChange}
//       />
//     </div>
//   );
// }
import React, { useState, useEffect, Component } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";

export default function DocEditor({ convertedContent, setConvertedContent }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  return (
    <Editor
      initialEditorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={setEditorState}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "history",
        ],
        image: {
          previewImage: true,
          uploadCallback: (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve({
                  data: {
                    url: reader.result,
                  },
                });
              };

              // reader.onerror = (reason) => reject(reason);

              // reader.readAsDataURL(file);
            });
          },
          alt: { present: true, mandatory: true },
        },
      }}
    />
  );
  // }
}
