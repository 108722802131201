import React from "react";
import "./cancerCheck.css";
import Card2 from "../../../../../components/cards/Card2";
import cancerCheckBreast from "../../../../../assets/images/cancer-check-breast.png";
import cancerCheckBowel from "../../../../../assets/images/cancer-check-bowel.png";
import cancerCheckCervical from "../../../../../assets/images/cancer-check-cervical.png";
// import cancerCheckGastric from "../../../../assets/images/cancer-check-gastric.png";
import cancerCheckLung from "../../../../../assets/images/cancer-check-lung.png";
import cancerCheckOral from "../../../../../assets/images/cancer-check-oral.png";
import cancerCheckOvarian from "../../../../../assets/images/cancer-check-ovarian.png";
// import cancerCheckPancreatic from "../../../../assets/images/cancer-check-pancreatic.png";
import cancerCheckProstate from "../../../../../assets/images/cancer-check-prostate.png";
import { ButtonSecondary } from "../../../../../components/buttons/Buttons";
import { Link } from "react-router-dom";

const CancerCheck = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">
        OncoCheck Screening <br className="d-md-none" /> Program
      </h1>
      <div className="d-flex flex-column align-items-center mb-5">
        <h4 className="color-green text-center fw-700 text-3 mb-3">
          COMPREHENSIVE CANCER SCREENING PROGRAM
        </h4>
        <span className="border-b-green"></span>
      </div>
      <div className="cancer-check-width mx-auto">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 g-xl-5 gy-5">
          <div className="col">
            <Card2
              title={"Breast Cancer"}
              description={
                "BreastCheck screening aims to find cancer early or changes in your breast that could lead to cancer.  Our personalised breast cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckBreast}
              bgClass={"bg-linen"}
              classes={"card2-height"}
            />
          </div>
          <div className="col">
            <Card2
              title={"Bowel Cancer"}
              description={
                "BowelCheck screening aims to find cancer early or changes in your bowel that could lead to cancer.  Our personalised bowel cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckBowel}
              bgClass={"bg-light-blue"}
              classes={"card2-height"}
            />
          </div>
          <div className="col">
            <Card2
              title={"Cervical Cancer"}
              description={
                "CervicalCheck screening aims to find cancer early or changes in your cervical that could lead to cancer.  Our personalised cervical cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckCervical}
              bgClass={"bg-pitch"}
              classes={"card2-height"}
            />
          </div>
          {/* <div className="col">
            <Card2
              title={"Gastric Cancer"}
              description={
                "GastricCheck screening aims to find cancer early or changes in your stomach that could lead to cancer.  Our personalised gastric cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckGastric}
              bgClass={"bg-light-blue"}
              classes={"card2-height"}
            />
          </div> */}
          <div className="col">
            <Card2
              title={"Lung Cancer"}
              description={
                "LungCheck screening aims to find cancer early or changes in your lung that could lead to cancer.  Our personalised lung cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckLung}
              bgClass={"bg-light-blue"}
              classes={"card2-height"}
            />
          </div>
          <div className="col">
            <Card2
              title={"Oral Cancer"}
              description={
                "OralCheck screening aims to find cancer early or changes in your oral cavity that could lead to cancer.  Our personalised oral cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckOral}
              bgClass={"bg-linen"}
              classes={"card2-height"}
            />
          </div>
          <div className="col">
            <Card2
              title={"Ovarian Cancer"}
              description={
                "OvarianCheck screening aims to find cancer early or changes in your  ovaries that could lead to cancer.  Our personalised Ovarian cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckOvarian}
              bgClass={"bg-pitch"}
              classes={"card2-height"}
            />
          </div>
          {/* <div className="col">
            <Card2
              title={"Pancreatic Cancer"}
              description={
                "PancreaticCheck screening aims to find cancer early or changes in your  pancreas that could lead to cancer.  Our personalised Pancreatic cancer screening program offers expert clinical advice and support"
              }
              image={cancerCheckPancreatic}
              bgClass={"bg-pitch"}
              classes={"card2-height"}
            />
          </div> */}
          <div className="col">
            <Card2
              title={"Prostate Cancer"}
              description={
                "ProstateCheck screening aims to find cancer early or changes in your prostate that could lead to cancer.  Our personalised prostate cancer screening programmes offers expert clinical advice and support"
              }
              image={cancerCheckProstate}
              bgClass={"bg-pitch"}
              classes={"card2-height"}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end pt-4 mt-5">
          <Link to="/contactUs">
            <ButtonSecondary
              name={"Customise Screening Program"}
              classes={"btn-lg"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancerCheck;
