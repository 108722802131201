import React from "react";
import "./cancerConsultation.css";
import consultationVideo from "../../../../../assets/images/consultation-video.png";
import consultationSecondOpinion from "../../../../../assets/images/consultation-second-opinion.png";
import stepsArrow from "../../../../../assets/images/steps-arrow-pink.png";
import SmallCard1 from "../../../../../components/cards/SmallCard1";
import { Link } from "react-router-dom";
import { ButtonWhite } from "../../../../../components/buttons/Buttons";

const CancerConsultation = () => {
  return (
    <div className="cancer-consultation-sec">
      <div className="container my-container">
        <h1 className="heading-2 heading-main consultation-heading-width mx-auto">
          Expert Cancer Consultation India, UK & Singapore
        </h1>
        <div className="col-md-10 col-lg-9 col-xl-8 mx-auto mb-5">
          <div className="row row-cols-1 row-cols-lg-2 g-4 g-xl-5 align-items-center">
            <div className="col">
              <h4 className="consultation-sub-heading text-center mb-0">
                <strong className="color-pink text-1">400+</strong>{" "}
                <sup className="color-pink text-3 font-playfair fw-700">
                  CANCERS TREATED
                </sup>
              </h4>
            </div>
            <div className="col d-none d-md-block">
              <h4 className="consultation-sub-heading text-center mb-0">
                <strong className="color-green text-1">250+</strong>{" "}
                <sup className="color-green text-3 font-playfair fw-700">
                  ONCOLOGY SPECIALISTS
                </sup>
              </h4>
            </div>
          </div>
        </div>
        <div className="cancer-consultation-inner col-md-10 col-lg-9 mx-auto">
          <div className="row row-cols-1 row-cols-md-2 g-4 g-xl-5 gy-5">
            <div className="col">
              <img src={consultationVideo} alt="" className="mb-4" />
              <h4 className="heading-5 fw-700 font-open-sans text-center mb-0">
                Online Video Consultation
              </h4>
            </div>
            <div className="col">
              <img src={consultationSecondOpinion} alt="" className="mb-4" />
              <h4 className="heading-5 fw-700 font-open-sans text-center mb-0">
                Online Second Opinions
              </h4>
            </div>
          </div>
        </div>
        <div className="steps-container pt-4 mt-5">
          <div>
            <SmallCard1
              title={"1"}
              subTitle={"Speak to Us"}
              bgClass={"bg-linen"}
            />
          </div>
          <div>
            <img src={stepsArrow} alt="" className="steps-arrow" />
          </div>
          <div>
            <SmallCard1
              title={"2"}
              subTitle={"Appointment Arranged"}
              bgClass={"bg-pitch"}
            />
          </div>
          <div>
            <img src={stepsArrow} alt="" className="steps-arrow" />
          </div>
          <div>
            <SmallCard1
              title={"3"}
              subTitle={"Complete the Consult "}
              bgClass={"bg-light-blue"}
            />
          </div>
          <div>
            <img src={stepsArrow} alt="" className="steps-arrow" />
          </div>
          <div>
            <SmallCard1
              title={"4"}
              subTitle={"Receive Report"}
              bgClass={"bg-green"}
            />
          </div>
          <div>
            <img src={stepsArrow} alt="" className="steps-arrow" />
          </div>
          <div>
            <SmallCard1
              title={"5"}
              subTitle={"Get Treated & Support For Recovery"}
              bgClass={"bg-pitch"}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4 mt-5">
          <Link to="/contactUs">
            <ButtonWhite
              name={"Expert Consultant Opinion"}
              classes={"btn-lg"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CancerConsultation;
