import React from "react";
import cancerWellnessImg2 from "../../../../../assets/images/cancer-wellness2.png";

const CancerCareEngagement = () => {
  return (
    <div className="container my-container">
      <h1 className="heading-2 heading-main">Cancer Care Engagement</h1>
      <img src={cancerWellnessImg2} alt="" className="w-100" />
    </div>
  );
};

export default CancerCareEngagement;
