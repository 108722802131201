import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
import StoredVariables, { GetSession } from "../../../constant/Session";
const ResetPassword = () => {
  const [otp, setOtp] = useState();
  const [newpassword, setNewPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      email: GetSession(StoredVariables.email),
      otp: otp,
      new_password: newpassword,
      c_password: confirmpassword,
    };
    const validation = new Validator(requestData, {
      otp: "required",
      new_password: "required",
      c_password: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST(
          "account/admin/reset-password",
          requestData
        );
        if (response.status === 200) {
          toast.success(response.message);
          navigate("/admin/resetpassword");
        } else {
          toast.error(response.message);
        }
        // await navigate("/dashboard");
      }
    }
  };

  return (
    <div className="bg h-1">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center h-100">
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <div className="card p-3 mt">
              <div className="card-body">
                <div className="logo p-3 bg-new">
                  <img src="/logo.png" alt="#logopng" />
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <p className="para1"></p>
                  <div className="form-outline mb-3">
                    <label className="form-label">
                      <b>OTP</b>
                    </label>
                    <input
                      type="test"
                      id="form1Example13"
                      className="form-control form-control-lg custom-input"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <div className="form-outline mb-3">
                    <label className="form-label">
                      <b>New Password </b>
                    </label>
                    <input
                      type="password"
                      id="form1Example13"
                      className="form-control form-control-lg"
                      value={newpassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-outline mb-3">
                    <label className="form-label">
                      <b>Confirm Password</b>
                    </label>
                    <input
                      type="password"
                      id="form1Example13"
                      className="form-control form-control-lg"
                      value={confirmpassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
