import React from "react";
import "./cards.css";
import propTypes from "prop-types";

const Card3 = ({ title, description, image, classes }) => {
  return (
    <div className={`card-3 ${classes ? classes : ""}`}>
      <img src={image} alt="" />
      <div>
        <h3 className="text-4 fw-700 text-center mb-3">{title}</h3>
        <p className="mb-0 font-playfair fw-500 text-center">{description}</p>
      </div>
    </div>
  );
};
export default Card3;

Card3.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  classes: propTypes.string,
};
