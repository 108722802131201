import React, { useEffect } from "react";
import "./cancerTreatments.css";
import CancerTreatmentsBanner from "../../../components/banners/CancerTreatmentsBanner";
import CancerTreatmentsSection from "./cancerTreatmentsComponents/cancerTreatmentsSection/CancerTreatmentsSection";
import TreatmentsWhatWeDo from "./cancerTreatmentsComponents/treatmentsWhatWeDo/TreatmentsWhatWeDo";
import DesignedToSupport from "./cancerTreatmentsComponents/designedToSupport/DesignedToSupport";

const CancerTreatments = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <CancerTreatmentsBanner />
      <CancerTreatmentsSection />
      <div className="d-none d-md-block">
        <TreatmentsWhatWeDo />
        <DesignedToSupport />
      </div>
    </>
  );
};

export default CancerTreatments;
