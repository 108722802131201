import React from "react";
import "./digitalLibraryBanner.css";
import { ButtonSecondary } from "../../../../../components/buttons/Buttons";
import img_1 from "../../../../../assets/images/whats-new-1.png";
import { useNavigate } from "react-router-dom";

const DigitalLibraryBanner = () => {
  const navigate = useNavigate();

  const scrollDown = () => {
    window.scrollBy({ top: 400, left: 0, behavior: "smooth" });
  };
  return (
    <div className="acticle-banner">
      <div className="row gx-lg-5">
        <div className="col-lg-6 col-xxl-7 order-lg-last">
          <img src={img_1} alt="" className="acticle-banner-img" />
        </div>
        <div className="col-lg-6 col-xxl-5">
          <div className="container acticle-banner-container py-md-3 py-lg-0">
            <div className="h-100 py-md-4">
              <h3 className="heading-3 acticle-banner-heading-width mb-4 mb-lg-5">
                Discover how cancer impacts employees at work and what employers
                can do about it
              </h3>
              <p className="acticle-banner-p-width mb-4 mb-lg-5 fs_18">
                Onco-Connect engages both employees to understand the impact of
                cancer on their work life balance; and employers on how they can
                help mitigate these issues
              </p>
              <ButtonSecondary
                name="Read all Articles"
                style={{ cursor: "pointer" }}
                onClick={scrollDown}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalLibraryBanner;
