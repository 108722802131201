import React, { useState, useEffect } from "react";
import "../userProfile.css";
import editIcon from "../../../../assets/images/edit-icon.png";
import { FormControl, InputLabel, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StoredVariables, { SetSession } from "../../../../constant/Session";
import { ButtonPrimary } from "../../../../components/buttons/Buttons";
import { GET, PUT } from "../../../../constant/RequestAuthService";
import { Validation } from "../../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";
// const roals = ["Manager", "Other"];
// const access = ["All", "Other"];

const UserProfileForm = () => {
  const [formValues, setFormValues] = useState({
    profile: "",
    fName: "",
    lName: "",
    email: "",
    phone: "",
    role: "",
    access: "",
    gender: "",
    address: "",
  });

  const GetProfile = async (id) => {
    const { data, status } = await GET("employee/get-profile");
    console.log(data);
    if (status === 200) {
      console.log(data);
      setFormValues({
        ...formValues,
        profile: data && data.profilePicture,
        fName: data && data.firstName,
        lName: data && data.lastName,
        gender: data && data.gender,
        phone: data && data.mobileNumber,
        email: data && data.email,
        address: data && data.address,
      });
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const handleAddProfileImg = () => {};

  const onChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      firstName: formValues.fName,
      lastName: formValues.lName,
      gender: formValues.gender,
      address: formValues.address,
      profilePicture: formValues.profile,
      mobileNumber: formValues.phone,
    };
    // console.log(requestData)
    const validation = new Validator(requestData, {
      firstName: "required",
      lastName: "required",
      gender: "required",
      address: "required",
      mobileNumber: "required",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      const response = await PUT("employee/update-profile", requestData);
      if (response.status === 200) {
        toast.success(response.message);
        await SetSession(StoredVariables.username, formValues.fName);
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <div className="container my-3 my-md-4">
      <div className="pro-card col-md-8 mx-auto position-relative">
        <img
          src={editIcon}
          className="edit-profile-btn"
          alt=""
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        />
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center mb-5">
            <div className="position-relative">
              <div className="img-circle">img</div>
              {/* <img
              src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"
              alt=""
              className="user-profile-img"
            /> */}
              <label className="add-img" onClick={handleAddProfileImg}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  className="d-none"
                  onChange={(e) => onChange("profile", e.target.files[0])}
                />
                <img src={editIcon} alt="" />
              </label>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-lg-2 g-4">
            <div className="col">
              <TextField
                fullWidth
                className="mui-tex-field"
                label="First name"
                value={formValues.fName}
                onChange={(e) => onChange("fName", e.target.value)}
              />
            </div>
            <div className="col">
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Last name"
                value={formValues.lName}
                onChange={(e) => onChange("lName", e.target.value)}
              />
            </div>
            <div className="col">
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Email"
                value={formValues.email}
                onChange={(e) => onChange("email", e.target.value)}
              />
            </div>
            <div className="col">
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Phone Number"
                value={formValues.phone}
                onChange={(e) => onChange("phone", e.target.value)}
              />
            </div>
            <div className="col">
              <FormControl className="mui-tex-field" fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={formValues.gender}
                  label="Gender"
                  onChange={(e) => onChange("gender", e.target.value)}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col">
              <TextField
                fullWidth
                className="mui-tex-field"
                label="Address"
                value={formValues.address}
                onChange={(e) => onChange("address", e.target.value)}
              />
            </div>
            {/* <div className="col">
              <FormControl className="mui-tex-field" fullWidth disabled={!edit}>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={formValues.role}
                  label="Role"
                  onChange={(e) => onChange("role", e.target.value)}
                >
                  {roals.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            {/* <div className="col">
              <FormControl className="mui-tex-field" fullWidth disabled={!edit}>
                <InputLabel id="demo-simple-select-label">Access</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={formValues.access}
                  label="Access"
                  onChange={(e) => onChange("access", e.target.value)}
                >
                  {access.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
          </div>

          {/* <div id="collapseOne" className="accordion-collapse collapse"> */}
          <div className="pt-5">
            <ButtonPrimary name="Update" arrow={false} />
          </div>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
};

export default UserProfileForm;
