import React from "react";
import StoredVariables, { GetSession } from "../../../../constant/Session";
const WelcomeSubHeader = () => {
  return (
    <div className="container-fluid bg-white p-0">
      <div className="container py-3">
        <h1 className="heading-5">
          Welcome, {GetSession(StoredVariables.username)}
        </h1>
      </div>
    </div>
  );
};

export default WelcomeSubHeader;
