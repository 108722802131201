import React from "react";
import "./banners.css";
import { ButtonPitch, ButtonTertiary, ButtonWhite } from "../buttons/Buttons";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div>
      <div className="home-banner">
        <div className="container py-5 d-none d-md-block">
          <div className="home-banner-text-width">
            <h1 className="heading-1 color-white mb-4 mb-lg-5">
              Delivering Value-Based Cancer Care Near You
            </h1>
            <p className="text-3 color-white fw-500 mb-4 pe-lg-4 pe-xl-0 mb-lg-5">
              Our technological solution combined with oncology expertise and
              provider partnerships helps us deliver high quality cancer care
              for our users.
            </p>
            <div className="d-flex align-items-center flex-wrap gap-3">
              <Link to="/contactUs">
                <ButtonPitch
                  name="Speak To Us"
                  arrow={false}
                  classes={"banner-btn"}
                />
              </Link>
              <Link to="/solutions">
                <ButtonTertiary
                  name="AI Led Cancer Tech Solutions"
                  classes={"banner-btn arrow-sm-none"}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 d-md-none">
        <div>
          <h1 className="heading-1 text-center mb-4">
            Delivering Value-Based Cancer Care Near You
          </h1>
          <p className="text-3 text-center fw-400 mb-4 banner-p-sm">
            Our technological solution combined with oncology expertise and
            provider partnerships helps us deliver high quality cancer care for
            our users.
          </p>
          <div
            className="d-flex flex-column align-items-center
           gap-3 mb-4"
          >
            <Link to="/contactUs">
              <ButtonPitch
                name="Speak To Us"
                arrow={false}
                classes={"banner-btn"}
              />
            </Link>
            <Link to="/solutions">
              <ButtonWhite
                name="AI Led Cancer Tech Solutions"
                classes={"banner-btn arrow-sm-none"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
