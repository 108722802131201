import { useNavigate } from "react-router-dom";
import "./pricing.css";
import rightArrow from "../../../assets/images/arrow-white.png";
import tick from "../../../../src/assets/images/tick.png";
export default function Pricing() {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="pricing-heading">Our Pricing</h1>
      <div className="pricing-cards-container">
        <div className="pricing-card">
          <div className="pricing-card-heading">INDIAN CONSULTATION</div>
          <div className="pricing-card-bullets">
            {" "}
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>Online Video Consultation</p>
            </div>
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>Leading Cancer Experts in India</p>
            </div>
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>Working With Top Cancer Institutes</p>
            </div>
          </div>

          <div className="card-price"> Starts at <span className="aerial-price"> ₹1,999</span> </div>
        </div>
        <div className="pricing-card">
          <div className="pricing-card-heading">U.K CONSULTATION</div>
          <div className="pricing-card-bullets">
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>Online Video Consultation</p>
            </div>
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>All & Rare Cancers Treated</p>
            </div>
            <div className="pricing-card-bullet">
              <img src={tick} alt="" />
              <p>Working With Top Cancer Institutes</p>
            </div>
          </div>

          <div className="card-price">Starts at <span className="aerial-price"> ₹99,999</span> </div>
        </div>
      </div>
      <button onClick={() => {
              navigate("/contactUs");
            }} className="pricing-button">
        <h5>Get Second Opinion</h5>
        <img src={rightArrow} alt="" />
      </button>
    </>
  );
}
