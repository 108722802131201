import React, { useState, useEffect } from "react";
import Datatable from "../../../components/dataTables/Datatable";
import { Switch, IconButton } from "@mui/material";
import { BsFillTrashFill, BsPencil } from "react-icons/bs";
import AddQuestion from "../../../components/models/addQuestion";
import EditQuestion from "../../../components/models/editQuestion";
import DeleteModal from "../../../components/models/deleteModal";
import StatusChange from "../../../components/models/statusChange";
import { GET } from "../../../constant/RequestAuthService";
import { DECODE } from "../../../constant/Util";

const Questions = () => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [id, setId] = useState(0);
  const [Status, setStatus] = useState(0);
  const [questionData, setQuestionData] = useState();
  const [Editquestion, setEditQuestion] = useState([]);

  useEffect(() => {
    GetQuestion();
  }, []);

  const GetQuestion = async () => {
    const { data, status } = await GET("admin/question");
    if (status === 200) {
      setQuestionData(data && data);
    }
  };

  const handleAdd = async (e) => {
    await setModal1(!modal1);
  };

  const handleEdit = async (x) => {
    await setEditQuestion(x);
    await setModal2(!modal2);
  };

  const handleDelete = async (x) => {
    await setId(x.id);
    await setModal3(!modal3);
  };

  const handleActive = async (x) => {
    await setId(x.id);
    await setStatus(x.status);
    await setModal4(!modal4);
  };
  const columns = [
    {
      label: <b>Questions</b>,
      name: "question",
    },
    {
      label: <b>Question Level 1</b>,
      name: "level",
    },
    {
      label: <b>Type</b>,
      name: "type",
    },
    {
      label: <b>Status</b>,
      name: "status",
    },
    {
      label: <b>Action</b>,
      name: "action",
    },
  ];

  let i = 1;
  let data =
    questionData && questionData.length > 0
      ? questionData.map((x) => {
          return {
            id: i++,
            question:
              x && x.title ? (
                <div dangerouslySetInnerHTML={{ __html: DECODE(x.title) }} />
              ) : (
                ""
              ),
            level: "",
            // x && x.title ? (
            //   <div dangerouslySetInnerHTML={{ __html: x.title }} />
            // ) : (
            //   ""
            // ),
            type:
              x.typeId === 8 || x.typeId === 9
                ? x.element && x.element.typeName
                : x.element && `${x.element.typeName} (${x.element.property})`,
            status: (
              <Switch
                checked={x && x.status === "active" ? "checked" : ""}
                onChange={() => handleActive(x)}
              />
            ),
            action: (
              <>
                <IconButton onClick={(e) => handleEdit(x)}>
                  <BsPencil color="blue" />
                </IconButton>
                <IconButton onClick={(e) => handleDelete(x)}>
                  <BsFillTrashFill color="red" />
                </IconButton>
              </>
            ),
          };
        })
      : [];

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between">
        <h3 className="card-heading">Questions</h3>
        <button className="btn btn-primary" onClick={(e) => handleAdd()}>
          Add Question
        </button>
      </div>

      <hr />
      <div className="card-body mb-3">
        <Datatable title={"Questions"} data={data} columns={columns} />
      </div>
      <AddQuestion modal1={modal1} setModal1={setModal1} />
      {/* <EditQuestion
        modal2={modal2}
        setModal2={setModal2}
        Editquestion={Editquestion}
      /> */}
      <DeleteModal
        modal3={modal3}
        setModal3={setModal3}
        id={id}
        setId={setId}
        URL={"admin/question"}
      />
      <StatusChange
        modal4={modal4}
        setModal4={setModal4}
        id={id}
        setId={setId}
        URL={"admin/question/is-active"}
        Status={Status}
        setStatus={setStatus}
      />
    </div>
  );
};

export default Questions;
