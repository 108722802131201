import React, { useState } from "react";
import { POST } from "../../../constant/RequestAuthService";
import { Validation } from "../../../constant/Util";
import * as Validator from "validatorjs";
import { toast } from "react-toastify";

function ChangePassword() {
  const [currentpassword, setCurrentPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      currentPassword: currentpassword,
      newPassword: newpassword,
      confirmPassword: confirmpassword,
    };
    const validation = new Validator(requestData, {
      currentPassword: "required",
      newPassword: "required",
      confirmPassword: "required|same:newPassword",
    });
    if (validation.fails()) {
      await Validation(validation.errors.errors);
    } else {
      if (validation.passes()) {
        const response = await POST(
          "account/admin/change-password",
          requestData
        );
        if (response.status === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    }
  };
  return (
    <div className="col-md-12 pt-2">
      <h4 className="font-weight-bold m-1">Change Password </h4>
      <div className="card m-3">
        <div className="card-body">
          <form className="forms-sample" onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6 col-xs-12 form-group in-box">
                <label for="exampleInputUsername1">
                  <b>Current Password</b>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Current Password"
                  required
                  value={currentpassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputEmail1">
                  <b>New Password</b>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  required
                  value={newpassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-xs-12  form-group in-box">
                <label for="exampleInputPassword1">
                  <b>Confirm New Password</b>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm New Password"
                  required
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex p-1">
              <button type="submit" className="btn btn-primary mr-2">
                Submit
              </button>
              <button type="button" className="btn btn-new2 bg-new">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
