import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { decrypt, encrypt } from 'crypto-js/aes';
import { enc } from 'crypto-js';
import './payment.css';

const Payment = () => {
  const [searchParams] = useSearchParams();
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_live_RqiI2Zh327KPkw';

  useEffect(() => {
    let razorpayInstance = null;

    const initializePayment = async () => {
      try {
        const encryptedData = searchParams.get('data');
        if (!encryptedData) {
          throw new Error('No payment data provided');
        }

        // Decrypt and parse the data
        const decryptedBytes = decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
        const decryptedData = JSON.parse(decryptedBytes.toString(enc.Utf8));
        
        // Validate required fields
        if (!decryptedData.amount || !decryptedData.source || !decryptedData.returnUrl) {
          throw new Error('Missing required payment data');
        }

        setPaymentData(decryptedData);

        // Razorpay payment options
        const options = {
          key: RAZORPAY_KEY_ID,
          amount: Math.round(decryptedData.amount * 100), // Convert to paise and ensure integer
          currency: "INR",
          name: "Aurae Diagnostics",
          description: "Health Package Payment",
          handler: function(response) {
            handlePaymentSuccess(response, decryptedData);
          },
          modal: {
            ondismiss: function() {
              handlePaymentDismissal(decryptedData.source);
            }
          },
          prefill: {
            name: decryptedData.orderSummary?.name || '',
            email: decryptedData.orderSummary?.email || '',
            contact: decryptedData.orderSummary?.phone || ''
          },
          notes: {
            bookingId: decryptedData.orderSummary?.bookingId || '',
            description: 'Health Package Payment'
          },
          theme: {
            color: "#DC7D57"
          }
        };

        // Load Razorpay script
        await loadRazorpayScript();
        
        razorpayInstance = new window.Razorpay(options);
        razorpayInstance.on('payment.failed', function(resp) {
          handlePaymentFailure(resp, decryptedData.source);
        });
        
        razorpayInstance.open();
        setIsLoading(false);

      } catch (err) {
        console.error('Payment initialization error:', err);
        setError(err.message || 'Failed to initialize payment');
        setIsLoading(false);
      }
    };

    initializePayment();

    // Cleanup
    return () => {
      if (razorpayInstance) {
        razorpayInstance.close();
      }
    };
  }, [searchParams]);

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = resolve;
      script.onerror = () => reject(new Error('Failed to load Razorpay SDK'));
      document.body.appendChild(script);
    });
  };

  const handlePaymentSuccess = (response, paymentData) => {
    try {
      if (!paymentData || !paymentData.returnUrl) {
        throw new Error('Invalid payment data');
      }

      const redirectUrl = new URL(paymentData.returnUrl);
      
      // Payment details
      redirectUrl.searchParams.set('paymentId', response.razorpay_payment_id);
      redirectUrl.searchParams.set('orderId', response.razorpay_order_id);
      redirectUrl.searchParams.set('signature', response.razorpay_signature);
      redirectUrl.searchParams.set('amount', paymentData.amount.toString());
      
      // Booking and user details
      if (paymentData.orderSummary) {
        redirectUrl.searchParams.set('bookingId', paymentData.orderSummary.bookingId);
        
        const encryptedOrderSummary = encrypt(
          JSON.stringify(paymentData.orderSummary),
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString();
        
        redirectUrl.searchParams.set('orderData', encryptedOrderSummary);
      }
  
      redirectUrl.searchParams.set('timestamp', Date.now().toString());
  
      window.location.href = redirectUrl.toString();
    } catch (err) {
      console.error('Payment success handling error:', err);
      setError('Failed to process payment response');
      handleErrorRedirect(paymentData?.source);
    }
  };

  const handlePaymentFailure = (response, sourceUrl) => {
    console.error('Payment failed:', response.error);
    setError('Payment failed. Please try again.');
    handleErrorRedirect(sourceUrl);
  };

  const handlePaymentDismissal = (sourceUrl) => {
    if (sourceUrl) {
      window.location.href = sourceUrl;
    }
  };

  const handleErrorRedirect = (sourceUrl) => {
    if (sourceUrl) {
      setTimeout(() => {
        window.location.href = sourceUrl;
      }, 2000);
    }
  };

  if (isLoading) {
    return (
      <div className="payment-container">
        <div className="payment-loader-container">
          <div className="payment-loader" role="progressbar" aria-label="Loading payment">
            <div className="spinner"></div>
          </div>
          <p>Initializing payment...</p>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="payment-container">
        <div className="payment-error" role="alert">
          <h2>Payment Error</h2>
          <p>{error}</p>
          <button 
            onClick={() => {
              if (paymentData?.source) {
                window.location.href = paymentData.source;
              } else {
                window.history.back();
              }
            }} 
            className="back-button"
            aria-label="Go back to previous page"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="payment-container">
      <div className="payment-loading" role="status">
        <p>Opening payment window...</p>
      </div>
    </div>
  );
};

export default Payment;