import { toast } from "react-toastify";

// function for getting error from validatorjs
const Validation = async (errordata) => {
  return Object.values(errordata).length > 0
    ? Object.values(errordata).map((item) => {
        return toast.error(item[0]);
      })
    : [];
};

export default Validation;
