import React from "react";
import "./query.css";
import query1 from "../../../assets/images/second-opinion-query-1.png";
import query2 from "../../../assets/images/second-opinion-query-2.png";
import query3 from "../../../assets/images/second-opinion-query-3.png";
import rightArrow from "../../../assets/images/arrow.png";

import { useNavigate } from "react-router-dom";

export default function Query() {
  const navigate = useNavigate();

  return (
    <div className="send-query">
      <div className="query-container">
        <div className="query-card">
          <img src={query1} alt="Personalized care" className="query-img" />
          <div className="query-heading">Personalized care</div>
          <p className="query-desc">
            We will support you at every step of your second opinion journey
          </p>
        </div>
        <div className="query-card">
          <img src={query2} alt="Globally Connected" className="query-img" />
          <div className="query-heading">Globally Connected</div>
          <p className="query-desc">
            Get your review by world class cancer specialists in India and UK
          </p>
        </div>
        <div className="query-card">
          <img src={query3} alt="Quick And Hassle Free" className="query-img" />
          <div className="query-heading">Quick And Hassle Free</div>
          <p className="query-desc">
            Get a priority second opinion report for your diagnosis & treatment.
          </p>
        </div>
        <div className="straight-line"></div>
        <div className="query-stats">
          <p>
            45% of patients had a change in diagnosis and or treatment options
            after their second opinion
          </p>
        </div>
      </div>
      <div className="query-button-container">
        {" "}
        <button
          onClick={() => {
            navigate("/contactUs");
          }}
          className="query-button"
        >
          Speak With Us
          <img style={{ paddingTop: "0.5rem" }} src={rightArrow} alt="" />
        </button>
      </div>
    </div>
  );
}
