import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { Switch, IconButton } from "@mui/material";
import { BsFillTrashFill, BsPencil, BsFillPersonFill } from "react-icons/bs";
import AddUser from "../../../components/models/addUser";
import EditUser from "../../../components/models/editUser";
import DeleteModal from "../../../components/models/deleteModal";
import StatusChange from "../../../components/models/statusChange";
import { GET } from "../../../constant/RequestAuthService";

function Users() {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [id, setId] = useState(0);
  const [Status, setStatus] = useState("");
  const [userData, setUserData] = useState();

  useEffect(() => {
    GetUsers();
  }, []);

  const GetUsers = async () => {
    const { data, status } = await GET("admin/user");
    if (status === 200) {
      setUserData(data && data);
    }
  };
  const handleAdd = async (e) => {
    await setModal1(!modal1);
  };

  const handleDelete = async (x) => {
    await setId(x.id);
    await setModal3(!modal3);
  };

  const handleActive = async (x) => {
    await setId(x.id);
    await setStatus(x.status);
    await setModal4(!modal4);
  };

  const handleEdit = async (x) => {
    await setId(x.id);
    await setModal2(!modal2);
  };

  const columns = [
    {
      label: <b>First Name</b>,
      name: "fname",
    },
    {
      label: <b>Last Name</b>,
      name: "lname",
    },
    {
      label: <b>Email</b>,
      name: "email",
    },
    {
      label: <b>Email Verified</b>,
      name: "emailverified",
    },
    {
      label: <b>Mobile No.</b>,
      name: "mobile",
    },
    {
      label: <b>Mobile No. Verified</b>,
      name: "mobileverified",
    },
    {
      label: <b>Status</b>,
      name: "status",
    },
    {
      label: <b>Action</b>,
      name: "action",
    },
  ];

  let i = 1;
  let data =
    userData && userData.length > 0
      ? userData.map((x) => {
          return {
            id: i++,
            fname:
              x && x.firstName ? (
                <Link to={`/admin/users/${x.id}`}>{x.firstName} </Link>
              ) : (
                ""
              ),
            lname: x && x.lastName ? x.lastName : "",
            email: x && x.email ? x.email : "",
            emailverified: x && x.isEmailVerify ? x.isEmailVerify : "",
            mobile: x && x.mobileNumber ? x.mobileNumber : "",
            mobileverified: x && x.isMobileVerify ? x.isMobileVerify : "",
            status: (
              <Switch
                checked={x && x.status !== "inactive" ? "checked" : ""}
                onChange={() => handleActive(x)}
              />
            ),
            action: (
              <>
                <Link to={`/admin/users/${x.id}`}>
                  <IconButton>
                    <BsFillPersonFill color="blue" />
                  </IconButton>
                </Link>
                <IconButton onClick={(e) => handleEdit(x)}>
                  <BsPencil color="blue" />
                </IconButton>
                <IconButton onClick={(e) => handleDelete(x)}>
                  <BsFillTrashFill color="red" />
                </IconButton>
              </>
            ),
          };
        })
      : [];

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between">
        <h3 className="card-heading">All Users</h3>
        <button className="btn btn-primary" onClick={(e) => handleAdd()}>
          Add User
        </button>
      </div>

      <hr />
      <div className="card-body mb-3">
        <Datatable title={"Users"} data={data} columns={columns} />
      </div>
      <AddUser
        modal1={modal1}
        setModal1={setModal1}
        // refetch={refetch}
      />
      <EditUser
        modal2={modal2}
        setModal2={setModal2}
        id={id}
        setId={setId}
        // refetch={refetch}
      />
      <DeleteModal
        modal3={modal3}
        setModal3={setModal3}
        id={id}
        setId={setId}
        URL={"admin/user"}
      />
      <StatusChange
        modal4={modal4}
        setModal4={setModal4}
        id={id}
        setId={setId}
        URL={"admin/user/is-active"}
        Status={Status}
        setStatus={setStatus}
      />
    </div>
  );
}

export default Users;
