import React, { useState } from "react";
import "./contactDetails.css";
import propTypes from "prop-types";
import mailIcon1 from "../../assets/images/mail-icon.png";
import mailIcon2 from "../../assets/images/mail-icon2.png";
import mailIcon3 from "../../assets/images/mail-icon3.png";
// import phoneIcon1 from "../../assets/images/phone-icon.png";
// import phoneIcon2 from "../../assets/images/phone-icon2.png";
// import phoneIcon3 from "../../assets/images/phone-icon3.png";
import rightMarkIcon1 from "../../assets/images/right-mark-icon.png";
import rightMarkIcon2 from "../../assets/images/right-mark-icon2.png";
import rightMarkIcon3 from "../../assets/images/right-mark-icon3.png";
import locationMarkIcon1 from "../../assets/images/location-mark-icon.png";
import locationMarkIcon2 from "../../assets/images/location-mark-icon2.png";
import locationMarkIcon3 from "../../assets/images/location-mark-icon3.png";

// variant can be green to use green color theme
const ContactDetails = ({ title, description, variant, classes }) => {
  const [viewPortWidth, setViewPortWidth] = useState(window.outerWidth);

  window.addEventListener("resize", () => {
    setViewPortWidth(window.outerWidth);
  });
  return (
    <div className={`contact-details ${classes ? classes : ""}`}>
      <h3 className="heading-5 fw-700 mb-4 mb-md-5">{title}</h3>
      <p className="m-0 text-3 color-blue opacity-75 text-center text-md-start mb-5">
        {description}
      </p>
      <div>
        <a
          href="mailto:hello@onco-connect.com"
          className="d-inline-flex align-items-center gap-3 mb-4 fs_20"
        >
          <img
            src={
              variant === "green"
                ? viewPortWidth >= 768
                  ? mailIcon2
                  : mailIcon3
                : mailIcon1
            }
            alt=""
          />
          hello@onco-connect.com
        </a>
      </div>
      <p className="related-queries-width m-0 fs_20 fw-500 color-blue opacity-75 mb-4">
        For customer support/appointment related queries:
      </p>
      {/* <div>
        <a
          href="tel:+91222222222"
          className="d-inline-flex align-items-center gap-3 mb-4 fs_20"
        >
          <img
            src={
              variant === "green"
                ? viewPortWidth >= 768
                  ? phoneIcon2
                  : phoneIcon3
                : phoneIcon1
            }
            alt=""
          />
          +91 222222222
        </a>
      </div> */}
      <div className="d-flex align-items-center gap-3 mb-4">
        <img
          src={
            variant === "green"
              ? viewPortWidth >= 768
                ? rightMarkIcon2
                : rightMarkIcon3
              : rightMarkIcon1
          }
          alt=""
        />
        <p className="fs_20 m-0">GDPR & HIPPA Compliant</p>
      </div>
      <div className="d-flex align-items-center gap-3">
        <img
          src={
            variant === "green"
              ? viewPortWidth >= 768
                ? locationMarkIcon2
                : locationMarkIcon3
              : locationMarkIcon1
          }
          alt=""
        />
        <p className="fs_20 m-0">Working with major global brands</p>
      </div>
    </div>
  );
};

export default ContactDetails;

ContactDetails.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string,
  variant: propTypes.string,
  classes: propTypes.string,
};

ContactDetails.defaultProps = {
  variant: "pink",
};
