import React, { useState } from "react";
import "./cards.css";
import propTypes from "prop-types";

// variant prop should be "image-left" or "image-right" to place image left or right side of container, default variant value is image-right
const FullWidthCard1 = ({
  title,
  description,
  image,
  variant,
  bgClass,
  classes,
}) => {
  const [viewPortWidth, setViewPortWidth] = useState(window.outerWidth);

  window.addEventListener("resize", () => {
    setViewPortWidth(window.outerWidth);
  });

  return (
    <div
      className={`full-width-card1 p-4 p-lg-5 ${bgClass} ${
        classes ? classes : ""
      }`}
    >
      <div className={`${viewPortWidth < "768" ? "container p-0" : ""}`}>
        <div className=" row row-cols-1 row-cols-lg-2 gx-lg-5 align-items-center">
          <div
            className={`col mb-4 mb-lg-0 ${
              variant === "image-right" ? "order-lg-last" : ""
            }`}
          >
            <img src={image} alt="" />
          </div>
          <div className="col">
            <div
              className={
                variant !== "image-right" ? "text-width ms-auto" : "text-width"
              }
            >
              <h3 className="heading-4 mb-4 mb-xxl-5">{title}</h3>
              <p className="m-0 text-3">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthCard1;

FullWidthCard1.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  variant: propTypes.string,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

FullWidthCard1.defaultProps = {
  bgClass: "bg-green",
  variant: "image-right",
};
