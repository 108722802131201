import React, { useEffect } from "react";
import "./engagingIndividuals.css";
import engagingIndividualsImg1 from "../../assets/images/our-solutions-mockup-1.png";
import engagingIndividualsImg2 from "../../assets/images/our-solutions-mockup-2.png";
import engagingIndividualsImg3 from "../../assets/images/our-solutions-mockup-4.png";
import engagingIndividualsImg4 from "../../assets/images/our-solutions-mockup-3.png";
import engagingIndividualsImg5 from "../../assets/images/engaging-individuals-5.png";
import engagingIndividualsImg6 from "../../assets/images/our-solutions-mockup-5.png";
import engagingIndividualsImg7 from "../../assets/images/engaging-individuals-7.png";
import EngagingIndividualsTabItem from "../employersTabItem/EmployersTabItem";

const EngagingIndividuals = () => {
  useEffect(() => {
    handleTab(0);
  }, []);

  const handleTab = (index) => {
    const tabMenu = document.querySelector(".my-tabs").children;
    const tabItems = document.querySelector(".my-tab-items").children;
    for (const item of tabMenu) {
      item.classList.remove("active");
    }
    for (const item of tabItems) {
      item.classList.remove("active");
    }
    tabMenu[index].classList.add("active");
    tabItems[index].classList.add("active");
  };

  return (
    <div className="container my-container pt-0">
      <h1 className="heading-2 heading-main">
        Engaging Individuals For Healthier Lifestyle
      </h1>
      <div className="my-tab-container d-none d-lg-block">
        <div className="my-tabs">
          <div
            onClick={() => {
              handleTab(0);
            }}
          >
            Risk Assessment
          </div>
          <div
            onClick={() => {
              handleTab(1);
            }}
          >
            Screening
          </div>
          <div
            onClick={() => {
              handleTab(2);
            }}
          >
            Expert Opinions
          </div>
          <div
            onClick={() => {
              handleTab(3);
            }}
          >
            Diagnostics
          </div>
          <div className="d-none"></div>
          <div className="d-none"></div>
          {/* <div
            onClick={() => {
              handleTab(4);
            }}
          >
            Treatment
          </div> */}
          {/* <div
            onClick={() => {
              handleTab(5);
            }}
          >
            Condition Management
          </div> */}
          <div
            onClick={() => {
              handleTab(6);
            }}
          >
            Content
          </div>
        </div>
        <div className="my-tab-items">
          <EngagingIndividualsTabItem
            title={"Risk Assessment"}
            description={
              "Our cancer risk assessment is used in major corporates around the world so we can act proactively and detect cancer early"
            }
            image={engagingIndividualsImg1}
          />
          <EngagingIndividualsTabItem
            title={"Screening"}
            description={
              "Our cancer screening programs are personalised so we can save lives by assessing the risk and detecting cancer early"
            }
            image={engagingIndividualsImg2}
          />
          <EngagingIndividualsTabItem
            title={"Expert Opinions"}
            description={
              "Online video consultation with expert cancer consultants from India, USA & UK"
            }
            image={engagingIndividualsImg3}
          />
          <EngagingIndividualsTabItem
            title={"Diagnostics"}
            description={
              "Our cancer diagnostics provider network allows users quick and easy access to lab, imaging & genetic tests"
            }
            image={engagingIndividualsImg4}
          />
          <EngagingIndividualsTabItem
            title={"Treatment"}
            description={
              "We have access to leading cancer experts and hospitals across the globe for advanced treatment of all and rare cancers"
            }
            image={engagingIndividualsImg5}
          />
          <EngagingIndividualsTabItem
            title={"Condition Management"}
            description={
              "We use behavioural science, medical and emotional management to improve health and lifestyle of individuals who may be at risk of developing cancer or who may have been diagnosed with the disease"
            }
            image={engagingIndividualsImg6}
          />
          <EngagingIndividualsTabItem
            title={"Content"}
            description={
              "Receive personalised content for your cancer including latest research, blogs and articles"
            }
            image={engagingIndividualsImg7}
          />
        </div>
      </div>

      <div className="accordion d-lg-none" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Risk Assessment
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Risk Assessment"}
                description={
                  "Our cancer risk assessment is used in major corporates around the world so we can act proactively and detect cancer early"
                }
                image={engagingIndividualsImg1}
              />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading2">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse2"
              aria-expanded="true"
              aria-controls="collapse2"
            >
              Screening
            </button>
          </h2>
          <div
            id="collapse2"
            className="accordion-collapse collapse"
            aria-labelledby="heading2"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Screening"}
                description={
                  "Our cancer screening programs are personalised so we can save lives by assessing the risk and detecting cancer early"
                }
                image={engagingIndividualsImg2}
              />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading3">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse3"
              aria-expanded="true"
              aria-controls="collapse3"
            >
              Expert Opinions
            </button>
          </h2>
          <div
            id="collapse3"
            className="accordion-collapse collapse"
            aria-labelledby="heading3"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Expert Opinions"}
                description={
                  "Online video consultation with expert cancer consultants from India, USA & UK"
                }
                image={engagingIndividualsImg3}
              />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading4">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse4"
              aria-expanded="true"
              aria-controls="collapse4"
            >
              Diagnostics
            </button>
          </h2>
          <div
            id="collapse4"
            className="accordion-collapse collapse"
            aria-labelledby="heading4"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Diagnostics"}
                description={
                  "Our cancer diagnostics provider network allows users quick and easy access to lab, imaging & genetic tests"
                }
                image={engagingIndividualsImg4}
              />
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="heading5">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse5"
              aria-expanded="true"
              aria-controls="collapse5"
            >
              Treatment
            </button>
          </h2>
          <div
            id="collapse5"
            className="accordion-collapse collapse"
            aria-labelledby="heading5"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Treatment"}
                description={
                  "We have access to leading cancer experts and hospitals across the globe for advanced treatment of all and rare cancers"
                }
                image={engagingIndividualsImg5}
              />
            </div>
          </div>
        </div> */}
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="heading6">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse6"
              aria-expanded="true"
              aria-controls="collapse6"
            >
              Condition Management
            </button>
          </h2>
          <div
            id="collapse6"
            className="accordion-collapse collapse"
            aria-labelledby="heading6"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Condition Management"}
                description={
                  "We use behavioural science, medical and emotional management to improve health and lifestyle of individuals who may be at risk of developing cancer or who may have been diagnosed with the disease"
                }
                image={engagingIndividualsImg6}
              />
            </div>
          </div>
        </div> */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="heading7">
            <button
              className="accordion-button collapsed font-open-sans fw-600 px-4 px-md-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse7"
              aria-expanded="true"
              aria-controls="collapse7"
            >
              Content
            </button>
          </h2>
          <div
            id="collapse7"
            className="accordion-collapse collapse"
            aria-labelledby="heading7"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-0">
              <EngagingIndividualsTabItem
                title={"Content"}
                description={
                  "Receive personalised content for your cancer including latest research, blogs and articles"
                }
                image={engagingIndividualsImg7}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagingIndividuals;
