// Base for production
export const Base = `https://api.onco-connect.com/`;

// Base For development
// export const Base = `http://localhost/k2k-backend/`

const CURLPath = {
  json: {
    "Content-Type": "application/json",
  },
  // account
  "account/admin/login": "account/admin/login",
  "account/admin/forgot-password": "account/admin/forgot-password",
  "account/admin/reset-password": "account/admin/reset-password",
  "account/admin/get-profile": "account/admin/get-profile",
  "account/admin/update-profile": "account/admin/update-profile",
  "account/admin/change-password": "account/admin/change-password",

  //users
  "admin/user": "admin/user",
  "admin/user/is-active": "admin/user/is-active",

  //employees
  "admin/user/employees": "admin/user/employees",

  //admin form-type
  "question/get-type": "question/get-type",

  //question
  "admin/question": "admin/question",
  "admin/question/is-active": "admin/question/is-active",
  question: "question",
  //User Side
  // "account/sign-up": "account/sign-up",
  "employee/login": "employee/login",
  "employee/forgot-password": "employee/forgot-password",
  "employee/reset-password": "employee/reset-password",
  "employee/get-profile": "employee/get-profile",
  "employee/update-profile": "employee/update-profile",
  logout: "logout",
  register: "register",
};

export default CURLPath;
