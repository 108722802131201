import React from "react";
import "./actionableInsights.css";
import insightsMockup from "../../assets/images/insights-mockup-1.png";
import employersIcon1 from "../../assets/images/employers-icon-1.png";
import employersIcon2 from "../../assets/images/employers-icon-2.png";
import employersIcon3 from "../../assets/images/employers-icon-3.png";
import employersIcon4 from "../../assets/images/employers-icon-4.png";

const ActionableInsights = () => {
  return (
    <div className="actionable-insights-sec">
      <div className="container my-container">
        <h1 className="heading-2 heading-main">
          Data-driven actionable insights
        </h1>
        <div className="row g-4 g-xl-5 gy-5 align-items-center justify-content-center">
          <div className="col-lg-8 col-xl-7">
            <div className="d-flex justify-content-center">
              <img src={insightsMockup} alt="" width={"90%"} />
            </div>
          </div>
          <div className="col-lg-8 col-xl-5">
            <div className="d-flex align-items-start gap-4 mb-5">
              <img src={employersIcon1} alt="" className="mt-4" />
              <div>
                <h4 className="fw-600 fs_20">OncoCheck Program</h4>
                <p className="m-0 opacity-50">
                  Build a customised annual cancer screening plan that drives
                  higher engagement and helps detecting cancer early
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-4 mb-5">
              <img src={employersIcon2} alt="" className="mt-4" />
              <div>
                <h4 className="fw-600 fs_20">Dedicated dashboard</h4>
                <p className="m-0 opacity-50">
                  Providing health data analysis and engagement along with
                  utilisation indicators
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-4 mb-5">
              <img src={employersIcon3} alt="" className="mt-4" />
              <div>
                <h4 className="fw-600 fs_20">
                  Personalised Support For Employees
                </h4>
                <p className="m-0 opacity-50">
                  Our AI- led solutions help detect cancer early and our
                  screening program supports everyone with a dedicated health
                  support team
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-4 mb-5">
              <img src={employersIcon4} alt="" className="mt-4" />
              <div>
                <h4 className="fw-600 fs_20">
                  Cancer impact calculator and savings
                </h4>
                <p className="m-0 opacity-50">
                  Our cancer impact calculator helps corporates and insurers to
                  understand better return on investment for the services we
                  provide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionableInsights;
