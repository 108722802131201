import React from "react";
import cancerWellnessImg1 from "../../../../../assets/images/cancer-wellness1.png";

const CancerWellness = () => {
  return (
    <div className="container my-container pt-0">
      <h1 className="heading-2 heading-main">Cancer Wellness</h1>
      <img src={cancerWellnessImg1} alt="" className="w-100" />
    </div>
  );
};

export default CancerWellness;
