import React, { useEffect } from "react";
import "./termsAndConditions.css";
const TermsAndConditions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="tnc-container">
      <h1 className="tnc-heading">Terms &amp; Conditions</h1>
      <div className="tnc-content">
        This terms and conditions govern your use of our digital services and
        the associated website or application <b>(“Services”)</b>. By accessing
        or using our Services, you agree to be bound by these Terms &amp;
        Conditions. This platform is owned and operated by Crossroads Health
        Services Private Limited, a private limited company registered under the
        provision of the Indian Companies Act, 2013. It operates under the name
        of Onco-Connect, called herewith as <b>(“Company”)</b>. The Company
        reserves the right to modify these Terms &amp; Conditions at any time.
        If you do not agree with any part of these Terms &amp; Conditions,
        please do not use our Services.
        <h1>Acceptance of Terms</h1>
        By using our Services, you agree to comply with and are bound by these
        Terms &amp; Conditions, and all applicable laws and regulations.
        <h1>Use of Service</h1>
        The Service uses information provided by you <b>
          (“User Information”)
        </b>{" "}
        directly or from reports, if you consent to sharing such reports and/or
        data collected on devices with the Service. Our data collection and
        processing practices are governed by our privacy policy. The Service is
        intended for informational purposes only and is not intended to be a
        substitute for professional medical advice, diagnosis or treatment.
        Please always take advice from a medical professional for any questions
        you may have regarding your medical condition. Never disregard any
        professional medical advice or delay in seeking it due to this Service.
        This Service does not provide diagnosis for medical condition, nor does
        it prescribe any treatment for a medical issue. It is your
        responsibility to seek medical advice for any medical condition you may
        have. This Service works on the basis of User Information, and in the
        event, you enter incorrect User Information, the Service will return an
        incorrect result.
        <h1>Disclaimer</h1>
        This Service is provided on a “as is” and “as available” basis without
        any warranties, expressed or implied. We do not guarantee the accuracy,
        completeness or reliability of any content or information provided
        through our Services. In no event will the Company, or its related
        partners be liable to You or anyone else for any decision made or action
        taken in reliance of the information in the Website or for any
        consequential, special or similar damages, even if advised of the
        possibility of such damages.
        <h1>Intellectual Property</h1>
        All content, trademarks, logos and other intellectual property used in
        connection with our Services are the property of Onco-Connect. You may
        not use, modify, reproduce or distribute any content from our Services
        without our prior written consent.
        <h1>Governing Law and Jurisdiction</h1>
        <ul>
          <li>
            {" "}
            The governing law of the Agreement shall be the substantive law of
            India.
          </li>
          <li>
            Any dispute arising out of or in connection with this Agreement,
            including any question regarding its existence, validity or
            termination, shall be referred to and finally resolved by
            arbitration under the Mumbai court. The language to be used in the
            arbitral proceedings shall be English.
          </li>
        </ul>
        <h1>Contact us</h1>
        If you have any concerns, questions requests, or grievances regarding
        these Terms &amp; Conditions, please write to{" "}
        <a href="mailto:hello@onco-connect.com">hello@onco-connect.com</a>. Once
        we receive your request, we will aim to reply within five working days.
        <h1>Refund Policy</h1>
         <b>Policy Overview</b>: <br/> All bookings for lab tests, scans, and other services
        provided by Onco-connect are non-refundable. However, under certain
        conditions, services can be rescheduled at no additional cost.
        Conditions for Rescheduling Services: <br/>
        <b>Lab Tests and Scans:</b>
        <ul>
          
          <li> No-Show by Us or Service Issues: If we fail to provide the scheduled service due to
        our no-show or due to issues such as equipment failure or personnel
        unavailability, customers may reschedule their appointment for a later
        date without any additional charges. </li>
          <li> Coverage Area Limitations: If a
        service cannot be provided because the customer’s location is outside
        our serviceable area, and this was not identified at the time of
        booking, the service may be rescheduled to a new location within our
        coverage area.</li>
        </ul>
        <br/>
        <b>Video Consultations: </b> <br/>
        Rescheduling of video consultations
        is generally not allowed unless there is an urgent and verifiable
        reason. Customers must provide adequate documentation to support their
        request for rescheduling due to urgent circumstances.
        <ul>
          <li><b>Process for
       Rescheduling:</b> <br></br>  Customers wishing to reschedule must contact our customer
        service department via email at hello@onco-connect.com. Please include
        email ID, the reason for rescheduling, and any relevant documentation if
        applicable. Rescheduling requests must be made at least 24 hours before
        the scheduled appointment time.<br/></li>
        </ul>
       
        <b>Policy Modifications:</b> <br/> Onco-connect
        reserves the right to modify this policy at any time to better serve our
        customers and adapt to operational needs. Contact Information: For any
        inquiries or assistance with rescheduling, please reach out to our
        customer support team (hello@onco-connect.com).
      </div>
    </div>
  );
};

export default TermsAndConditions;
