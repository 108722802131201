import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./header.css";
import SidebarMenu from "./headerComponents/sidebarMenu/SidebarMenu";
import { ButtonSecondary, ButtonWhite } from "../buttons/Buttons";

const Header = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const headerMenu = [
    { name: "Home", link: "/", active: pathName === "/" ? true : false },
    {
      name: "Solutions",
      link: "/solutions",
      active: pathName === "/solutions" ? true : false,
    },
    {
      name: "Second Opinion",
      link: "/secondOpinion",
      active: pathName === "/secondOpinion" ? true : false,
    },
    {
      name: "Insurers",
      link: "/insurers",
      active: pathName === "/insurers" ? true : false,
    },
    {
      name: "Employers",
      link: "/employers",
      active: pathName === "/employers" ? true : false,
    },
    {
      name: "Digital Library",
      link: "/digitalLibrary",
      active: pathName === "/digitalLibrary" ? true : false,
    },
    {
      name: "Contact Us",
      link: "/contactUs",
      active: pathName === "/contactUs" ? true : false,
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <header className="my-nav">
        <nav className="navbar navbar-expand-xl">
          <div className="container-fluid position-relative">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <i
                // className={
                //   isMenuOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"
                // }
                className="fa-solid fa-bars"
              ></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                {headerMenu &&
                  headerMenu.map((item, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <Link
                          className={
                            item.active ? "nav-link active" : "nav-link"
                          }
                          to={item.link}
                        >
                          {item.name}
                          <span className="menu-active-border"></span>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* <div className="navbar-btns d-none d-md-flex align-items-center gap-3">
              <Link to="/employee/login">
                <ButtonWhite
                  name="Log in"
                  arrow={false}
                  classes={"py-2 px-3"}
                />
              </Link>
              <Link to="/employee/signup">
                <ButtonSecondary
                  name="Sign up"
                  arrow={false}
                  classes={"py-2 px-3"}
                />
              </Link>
            </div> */}
          </div>
        </nav>

        <SidebarMenu
          headerMenu={headerMenu}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </header>
    </>
  );
};

export default Header;
