import React from "react";
import EmployeeDashboardComponent from "./employee/EmployeeDashboardComponent";
import WelcomeSubHeader from "./employee/WelcomeSubHeader";

const UserProfile = () => {
  return (
    <>
      <WelcomeSubHeader />
      <EmployeeDashboardComponent />
    </>
  );
};

export default UserProfile;
