import React from "react";
import Figures from "../../../../../components/figures/Figures";

const EmployersFigures = () => {
  return (
    <div className="container my-container my-lg-5">
      <div className="row row-cols-1 row-cols-lg-3 g-4 g-xl-5">
        <div className="col">
          <Figures
            digit={"94%"}
            description={
              "94% of employees want to know if they have cancer as early as possible"
            }
          />
        </div>
        <div className="col">
          <Figures
            digit={"24%"}
            description={"Employees receive a cancer second opinion service"}
          />
        </div>
        <div className="col">
          <Figures
            digit={"3<sup>rd</spu>"}
            description={
              "Employees rank cancer as their top three health concerns"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EmployersFigures;
