import React from "react";
import UserProfileForm from "./userProfileCompunents/UserProfileForm";
import WelcomeSubHeader from "./userProfileCompunents/WelcomeSubHeader";
import TakeAssessment from "./userProfileCompunents/TakeAssessment";

const UserProfile = () => {
  return (
    <>
      <WelcomeSubHeader />
      <UserProfileForm />
      <TakeAssessment />
    </>
  );
};

export default UserProfile;
