import React from "react";
import "./cards.css";
import propTypes from "prop-types";

const Card2 = ({ title, description, image, bgClass, classes }) => {
  return (
    <div className={`card-2 ${bgClass} ${classes ? classes : ""}`}>
      <img src={image} alt="" className="mb-4" />
      <h3 className="heading-4 text-center mb-4">{title}</h3>
      <p className="mb-0 text-center fs_18 fw-500">{description}</p>
    </div>
  );
};
export default Card2;

Card2.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  image: propTypes.any.isRequired,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

Card2.defaultProps = {
  bgClass: "bg-linen",
};
