import React from "react";
import "./secondOpinion.css";
import Banner from "./Banner";
import Process from "./Process";
import Query from "./Query";
import CommonlyRequested from "./CommonlyRequested";
 import Pricing from "./Pricing";
import Info from "./Info";
import SupportFramework from "./SupportFramework";
import Partners from "./Partners";
function SecondOpinion() {
  return (
    <>
      <div className="second-opinion">
        <Banner/>
        <Query/>
        <Process/>
        <CommonlyRequested/>
        <SupportFramework/>
         <Pricing/>
         <Info/>
         <Partners/>
      </div>    </>
  );
}

export default SecondOpinion;
