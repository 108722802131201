import React from "react";
import "./cards.css";
import propTypes from "prop-types";
import { ButtonPrimary } from "../buttons/Buttons";
import { Link } from "react-router-dom";

const Card1 = ({
  title,
  subTitle,
  description,
  link,
  buttonText,
  bgClass,
  classes,
}) => {
  return (
    <div className={`card-1 ${bgClass} ${classes ? classes : ""}`}>
      <div>
        <h3 className="heading-4 text-center card-1-h-min-height m-0">
          {title}
        </h3>
      </div>
      <div>
        {subTitle && (
          <p className="mb-4 text-center fs_20 fw-600">{subTitle}</p>
        )}
        <p className="text-center text-3 mb-0">{description}</p>
      </div>
      <div className="mt-auto">
        <Link to={link}>
          <ButtonPrimary name={buttonText} classes={"mx-auto"} />
        </Link>
      </div>
    </div>
  );
};
export default Card1;

Card1.propTypes = {
  title: propTypes.string.isRequired,
  subTitle: propTypes.string,
  description: propTypes.string.isRequired,
  link: propTypes.string,
  buttonText: propTypes.string,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

Card1.defaultProps = {
  buttonText: "Learn More",
  bgClass: "bg-linen",
};
