import React, { useState } from "react";
import "./demoContactForm.css";
import propTypes from "prop-types";
import { Country } from "country-state-city";
import Validator from "validatorjs";
import Validation from "../../utilities/validation";
import axios from "axios";
import { toast } from "react-toastify";

// variant can be green to use green color theme
const DemoContactForm = ({ variant, classes, heading }) => {
  const [loading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [countryPinCode, setCountryPinCode] = useState("+91");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [allCountry] = useState(Country.getAllCountries());

  const handelSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = {
      name: nameValue,
      email: emailValue,
      countryPinCode: countryPinCode,
      phoneNumber: phoneNumberValue,
    };

    const validate = new Validator(formData, {
      name: "required|min:3",
      email: "required|email",
      countryPinCode: "required",
      phoneNumber: "required|digits_between:10,13",
    });

    const HTML = `
    <div style="margin: 20px">
      <div style="background-color: #bfddcb; padding: 30px 30px">
        <h1
          style="
            text-align: center;
            font-size: 20px;
            color: #000;
            font-weight: 600;
          "
        >
          ${heading}
        </h1>
      </div>

      <img
        style="display: block; height: 60px; width: 150px; margin: 20px auto"
        src="https://onco-connect.com/logo.png"
      />

      <table style="margin: 0 auto; border-spacing: 10px">
        <tr>
          <td style="font-size: 13px">Name :</td>
          <td style="font-size: 13px"><b>${formData.name}</b></td>
        </tr>
        <tr>
          <td style="font-size: 13px">Email :</td>
          <td style="font-size: 13px"><b>${formData.email}</b></td>
        </tr>
        <tr>
          <td style="font-size: 13px">Mobile :</td>
          <td style="font-size: 13px">
            <b>${formData.countryPinCode}-${formData.phoneNumber}</b>
          </td>
        </tr>
      </table>

      <p style="font-size: 11px">This Is A System Genrated Mail.</p>
    </div>
    `;

    const bodyData = {
      from_name: "noreply",
      from: "noreply@onco-connect.com",
      to: "akshay@onco-connect.com",
      subject: heading,
      message: HTML,
    };

    if (validate.fails()) {
      Validation(validate.errors.errors);
      setLoading(false);
    } else {
      const url = "https://mail.mobitplus.com/";
      const options = {
        headers: { "Content-type": "application/json" },
      };

      try {
        const res = await axios.post(url, bodyData, options);

        if (res.data === 1) {
          toast.success(
            "Your Request is submitted, Please wait to 2-3 days we will contact you soon."
          );
          setNameValue("");
          setEmailValue("");
          setCountryPinCode("+91");
          setPhoneNumberValue("");

          setLoading(false);
        } else {
          toast.error("Somthing Went wrong to sent mail. Please try again..");

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <form onSubmit={handelSubmit} className={`${classes ? classes : ""}`}>
      <input
        type="text"
        placeholder="Full name"
        className="input-1 mb-4"
        value={nameValue}
        onChange={(e) => {
          setNameValue(e.target.value);
        }}
      />

      <input
        type="text"
        placeholder="Email"
        className="input-1 mb-4"
        value={emailValue}
        onChange={(e) => {
          setEmailValue(e.target.value);
        }}
      />

      <div className="row">
        <div className="col-12 col-sm-5 col-md-4 col-lg-6 col-xl-5">
          <div className="my-select mb-4">
            <select
              className="input-1"
              value={countryPinCode}
              onChange={(e) => {
                setCountryPinCode(e.target.value);
              }}
            >
              {allCountry &&
                allCountry.length > 0 &&
                allCountry.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={
                        item.phonecode.charAt(0) === "+"
                          ? item.phonecode
                          : "+" + item.phonecode
                      }
                    >
                      {item.name}{" "}
                      {item.phonecode.charAt(0) === "+"
                        ? item.phonecode
                        : "+" + item.phonecode}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-12 col-sm-7 col-md-8 col-lg-6 col-xl-7">
          <input
            type="text"
            placeholder="Phone"
            className="input-1 mb-4"
            value={phoneNumberValue}
            onChange={(e) => {
              setPhoneNumberValue(e.target.value);
            }}
          />
        </div>
      </div>

      <button
        disabled={loading}
        type="submit"
        className={`btn btn-primary w-100 ${
          variant === "green" ? "green-submit-btn" : "pink-submit-btn"
        }`}
      >
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          "Submit"
        )}
      </button>
    </form>
  );
};

export default DemoContactForm;

DemoContactForm.propTypes = {
  classes: propTypes.string,
  variant: propTypes.string,
};

DemoContactForm.defaultProps = {
  variant: "pink",
};
