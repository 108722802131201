import React from "react";
import "./knowMore.css";
import { ButtonPrimary } from "../../../../../components/buttons/Buttons";
import { Link } from "react-router-dom";

const KnowMore = () => {
  return (
    <div className="container my-container pt-0 my-md-5">
      <h1 className="heading-2 heading-main">
        Want To Know More About What We Do
      </h1>
      <div className="d-flex justify-content-center know-more-btn">
        <Link to="/contactUs">
          <ButtonPrimary name="Speak To Us" classes={"btn-lg"} />
        </Link>
      </div>
    </div>
  );
};

export default KnowMore;
