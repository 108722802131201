import React from "react";
import "./cards.css";
import propTypes from "prop-types";

const FullWidthCard4 = ({ title, description, bgClass, classes }) => {
  return (
    <div className={`full-width-card4 ${bgClass} ${classes ? classes : ""}`}>
      <div className="p-4 p-lg-5">
        <h3 className="text-3 fw-600 mb-4">{title}</h3>
        <p className="m-0 fs_20">{description}</p>
      </div>
    </div>
  );
};

export default FullWidthCard4;

FullWidthCard4.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  bgClass: propTypes.string,
  classes: propTypes.string,
};

FullWidthCard4.defaultProps = {
  bgClass: "bg-linen",
};
