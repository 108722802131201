import React from "react";
import "./commonlyRequested.css";
export default function CommonlyRequested() {
  const cancerTypesList1 = [
    {
      id: "1",
      name: "Breast Cancer",
      bg: "rgba(255, 239, 226, 1)",
    },
    {
      id: "2",
      name: "Bowel Cancer",
      bg: "rgba(205, 229, 253, 1)",
    },
    {
      id: "3",
      name: "Cervical Cancer",
      bg: "rgba(191, 221, 203, 1)",
    },
  ];
  const cancerTypesList2 = [
    {
      id: "4",
      name: "Lung Cancer",
      bg: "rgba(198, 224, 208, 1)",
    },
    {
      id: "5",
      name: "Pancreactic Cancer",
      bg: "rgba(205, 229, 253, 1)",
    },
  ];
  const cancerTypesList3 = [
    {
      id: "6",
      name: "Gastric Cancer",
      bg: "rgba(241, 187, 179, 1)",
    },
    {
      id: "7",
      name: "Ovarian Cancer",
      bg: "rgba(245, 224, 184, 1)",
    },

    {
      id: "8",
      name: "Prostate Cancer",
      bg: "rgba(225, 237, 249, 1)",
    },
  ];

  const CancerType = ({ name, bg }) => {
    return (
      <div>
        <button style={{ backgroundColor: bg }} className="type-name">
          <h3>{name}</h3>
        </button>
      </div>
    );
  };

  return (
    <div className="commonly-requested">
      <p className="commonly-requested-heading">
        Commonly Requested Cancer Second Opinions
      </p>
      <p className="pre-heading">We provide expert opinion for all and  rare cancers</p>
<div className="all-types-container">
<div className="type-container">
        {cancerTypesList1.map((type) => (
          <CancerType key={type.id} name={type.name} bg={type.bg} />
        ))}
      </div>
      <div className="type-container type-container2">
        {" "}
        {cancerTypesList2.map((type) => (
          <CancerType key={type.id} name={type.name} bg={type.bg} />
        ))}
      </div>
      <div className="type-container">
        {cancerTypesList3.map((type) => (
          <CancerType key={type.id} name={type.name} bg={type.bg} />
        ))}
      </div>
</div>
 
    </div>
  );
}
