import React, { useState, useEffect } from "react";
import "../userCancerAssessment/userCancerAssessment.css";
// import questionImg from "../../../assets/images/question-img.png";
import { ButtonSecondary } from "../../../components/buttons/Buttons";
import { GET } from "../../../constant/RequestAuthService";
import StoredVariables, { GetSession } from "../../../constant/Session";
import { DECODE } from "../../../constant/Util";
import RadioButton from "../../../components/radio";
import CheckboxButton from "../../../components/checkbox";
const AssessmentStepper = () => {
  const [questionData, setQuestionData] = useState("");
  const [questionDataLength, setQuestiondatalength] = useState("");
  const [current, setCurrent] = useState(0);
  const [answer, setAnswer] = useState("");
  const [answerId, setAnswerId] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const GetQuestion = async () => {
    const { data, status } = await GET("question", {
      id: GetSession(StoredVariables.userId),
    });
    if (status === 200) {
      setQuestiondatalength(data && data.length);
      setQuestionData(data && data);
    }
  };

  useEffect(() => {
    GetQuestion();
  }, []);

  const onChange = (value) => {
    setCurrent(value + 1);
  };

  console.log(current);
  return (
    <>
      <div className="container-fluid p-0 bg-white">
        <div className="container py-4">
          <div className="col-md-8 col-lg-8 p-0 mx-auto">
            <div className="progress assessment-progress my-3">
              <div
                className="progress-bar bg-success"
                style={{
                  width: `${current && current}%`,
                }}
              ></div>
            </div>
            <p className="text-4 text-center m-0">
              {current + 1}/{questionDataLength} questions answered
            </p>
          </div>
        </div>
      </div>

      <div className="container my-3 my-md-4">
        <div className="row row-cols-1 g-4 g-xl-5">
          {/* <div className="col">
            <img src={questionImg} alt="" className="w-100 rounded-3" />
          </div> */}

          <div className="col">
            <div className="pro-card d-flex justify-content-center h-100 p-5">
              <div className="d-inline-flex flex-column">
                <p className="text-center mb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        questionData && questionData[current].title
                          ? DECODE(questionData[current].title)
                          : "",
                    }}
                  />
                </p>
                <div>
                  {/* for radio types options*/}
                  {questionData &&
                    questionData[current].element.property === "radio" &&
                    questionData[current].options.length > 0 &&
                    questionData[current].options.map((x) => (
                      <>
                        <RadioButton
                          x={x}
                          setAnswerId={setAnswerId}
                          setAnswer={setAnswer}
                        />
                      </>
                    ))}
                  {/* for checkbox types options*/}
                  {questionData &&
                    questionData[current].element.property === "checkbox" &&
                    questionData[current].options.length > 0 &&
                    questionData[current].options.map((x) => (
                      <>
                        <CheckboxButton
                          x={x}
                          setAnswerId={setAnswerId}
                          setAnswer={setAnswer}
                        />
                      </>
                    ))}
                  {/* for select types options*/}
                  {questionData &&
                    questionData[current].element.property === "select" && (
                      <select>
                        {questionData[current].options.length > 0 &&
                          questionData[current].options.map((x) => (
                            <option
                              value={x.id}
                              // onChange={() => setOptionValue(x)}
                            >
                              {x.value}
                            </option>
                          ))}
                      </select>
                    )}
                  {/* for Dependent question */}
                  {questionData &&
                    questionData[current].children.length > 0 &&
                    questionData[current].children.map((x) => (
                      <>
                        {x.parentOptionId === answerId && (
                          <>
                            <p className="text-center mb-4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DECODE(x.title),
                                }}
                              />
                            </p>
                            {x.element.property === "radio" &&
                              x.options.length > 0 &&
                              x.options.map((y) => (
                                <RadioButton
                                  x={y}
                                  setAnswerId={setAnswerId}
                                  setAnswer={setAnswer}
                                />
                              ))}
                            {x.element.property === "checkbox" &&
                              x.options.length > 0 &&
                              x.options.map((y) => (
                                <CheckboxButton
                                  x={y}
                                  // setAnswerId={setAnswerId}
                                  setAnswer={setAnswer}
                                />
                              ))}
                            {x.element.property === "select" &&
                              x.options.length > 0 &&
                              x.options.map((y) => (
                                <RadioButton
                                  x={y}
                                  setAnswerId={setAnswerId}
                                  setAnswer={setAnswer}
                                />
                              ))}
                          </>
                        )}
                      </>
                    ))}
                </div>
                <ButtonSecondary
                  name="Next Step"
                  arrow={false}
                  onClick={onChange}
                  disabled={isRequired === true ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentStepper;
